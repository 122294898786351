<template>
  <div id="CourseList">
    <section class="content-header">
      <h1>課程管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li class="active">課程管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              style="min-width: auto"
              @click="add()"
            >
              <i class="fa fa-plus"></i> 新增
            </button>
            <button
              class="btn btn-block btn-add mr-10"
              :class="$user.hasPermission('sendNotice') ? 'btn-danger' : ''"
              type="button"
              style="min-width: auto"
              @click="
                $user.hasPermission('sendNotice')
                  ? showMail()
                  : noMailPermission()
              "
            >
              <i class="fa fa-bell"></i> 發送通知信
            </button>
            <button
              class="btn btn-block btn-success btn-add mr-10"
              type="button"
              @click="goRecordAll()"
            >
              <i class="fa fa-search"></i> 批量課程紀錄
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.courseType"
                @change="resetDataList()"
              >
                <option
                  v-for="(item, index) in classList.chapterType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit2"
                required="required"
                v-model="searchData.courseClass"
                @change="resetDataList()"
              >
                <option
                  v-for="(item, index) in classList.classType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.courseName"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="resetDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <div class="checkbox" v-if="$user.hasPermission('watchAll')">
                <label>
                  <iCheckCheckBox
                    :propValue="true"
                    :propChecked="self"
                    v-on:update:checked="showSelf"
                  />
                  我上傳的課程
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 5%">
                    <div class="checkbox">
                      <label>
                        <iCheckCheckBox
                          :propValue="true"
                          :propChecked="isCheckAll"
                          v-on:update:checked="checkAll"
                        />
                      </label>
                    </div>
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="width: 8%; min-width: 100px"
                  >
                    類型
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="width: 8%; min-width: 100px"
                  >
                    分類
                  </th>
                  <th class="text-nowrap" style="width: 15%; min-width: 180px">
                    課程名稱
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="width: 8%; min-width: 80px"
                  >
                    建立者
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    時數
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="width: 10%; min-width: 120px"
                  >
                    建立時間
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="width: 10%; min-width: 120px"
                  >
                    上次寄送通知信時間
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    課程狀態
                  </th>
                  <th class="text-nowrap text-center" style="width: 5%">
                    測驗狀態
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="width: 15%; min-width: 340px"
                  >
                    功能
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="11">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="11">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">
                      <div class="checkbox">
                        <label>
                          <iCheckCheckBox
                            :propValue="data"
                            :propChecked="data.checked"
                            v-on:update:checked="checkChange"
                          />
                        </label>
                      </div>
                    </td>
                    <td class="text-center">{{ data.courseType }}</td>
                    <td class="text-center">{{ data.courseClass }}</td>
                    <td>{{ data.courseName }}</td>
                    <td>{{ data.createEmpName }}</td>
                    <td class="text-center" style="white-space: nowrap">
                      {{ data.courseTotalTime }}
                    </td>
                    <td class="text-center">
                      {{ data.createTime | dateFormat() }}
                    </td>
                    <td class="text-center">
                      <span v-if="data.sendMailTime">{{
                        data.sendMailTime | dateFormat()
                      }}</span>
                      <span v-else> - </span>
                    </td>
                    <td
                      class="text-center"
                      :class="{ 'text-red': data.courseStatus != 1 }"
                    >
                      {{ data.courseStatus == 1 ? "啟用" : "停用" }}
                    </td>
                    <td
                      class="text-center"
                      :class="{ 'text-red': data.testStatus != 1 }"
                    >
                      {{ data.testStatus == 1 ? "啟用" : "停用" }}
                    </td>
                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-warning"
                        @click="goChapter(data)"
                      >
                        章節
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        class="btn btn-success"
                        @click="goDetail(data)"
                      >
                        詳細
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="edit(data)"
                      >
                        修改
                      </button>
                      &nbsp;
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-brown dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          其他
                          <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a href="javascript:void(0)" @click="goQuiz(data)">
                              測驗
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              @click="copyCourse(data)"
                            >
                              複製
                            </a>
                          </li>
                          <li>
                            <a
                              class="text-red"
                              href="javascript:void(0)"
                              @click="deleteItem(data)"
                            >
                              刪除
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
        <!--發送通知信-->
        <div class="modal fade" ref="mail">
          <div class="modal-dialog" style="overflow: visible">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">發送通知信</h4>
              </div>
              <div class="modal-body">
                <form class="form-horizontal" name="announcementForm">
                  <div class="box-body">
                    <div class="form-group">
                      <label
                        class="col-sm-2 control-label text-nowrap"
                        style="padding-top: 0"
                      >
                        <i class="text-red">*</i> 課程：
                      </label>
                      <div class="col-sm-10">
                        <div v-for="(course, index) in checkDatas" :key="index">
                          {{ course.courseType }} - {{ course.courseName }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label
                        class="col-sm-2 control-label text-nowrap"
                        style="padding-top: 0"
                      >
                        <i class="text-red">*</i> 信件內容：
                      </label>
                      <div class="col-sm-10">
                        <textarea
                          class="form-control"
                          id="inputContent"
                          required="required"
                          type="text"
                          rows="5"
                          v-model="mailContent"
                        ></textarea>
                      </div>
                    </div>

                    <div class="form-group">
                      <label
                        class="col-sm-2 control-label text-nowrap"
                        style="padding-top: 0"
                      >
                        通知主管：
                      </label>
                      <div class="col-sm-10">
                        <multiselect
                          placeholder="請選擇公司或部門"
                          label="optionName"
                          track-by="optionName"
                          required
                          :options="managerList"
                          :hide-selected="true"
                          :multiple="true"
                          :taggable="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          max-elements="已達標籤上限"
                          v-model="selected"
                        >
                          <span class="notice" slot="noOptions">列表為空</span>
                          <span class="notice" slot="noResult">查無結果</span>
                          <span class="notice maxElements" slot="maxElements">
                            已達標籤上限
                          </span>
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title">
                                {{ props.option.optionName }}
                              </span>
                            </div>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer text-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="sendMail()"
                >
                  確定
                </button>
                <button
                  class="btn btn-default"
                  data-dismiss="modal"
                  type="button"
                  @click="resetMail()"
                >
                  關閉
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import multiselect from "vue-multiselect";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
    multiselect,
    Pagination,
  },
  data() {
    return {
      loading: false,
      mailLoading: false,
      LEGAL_DEPT_ID: 134, //法務部
      WEB_DEPT_ID: 294, //WEB開發一部
      searchData: {
        courseName: "", // 課程名稱
        courseType: "", // 課程類別
        courseClass: "", // 課程分類
        isRequire: "", // 是否必修 0：選修、1：必修
        getTotalTime: 1, // 取得課程總時數 0：停用、1：啟用 ps. 需要顯示總時數再傳入1即可
        order: 1, // 排序 1.創立時間新到舊
        pageNum: 1, // 頁碼
        pageSize: 20, // 頁數
        isDel: 0, // 是否刪除 0：啟用、1：刪除
        // courseStatus: 1, // 課程狀態 0：停用、1：啟用
        // createEmpId: "", // 創立員編
      },
      classList: {},
      managerList: [],
      selected: [],
      mailList: [],
      mailContent: `提醒您，未完成以下之教育訓練，請於EIP-教育訓練完成課程。`,
      self: true,
      selfStatus: true,
      isCheckAll: false,
      checkIds: [],
      checkDatas: [],
      checkDefDatas: [],
      list: [],
      total: 0,
      userData: [],
    };
  },
  async created() {
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    this.userData = this.$user.getUserData();
    if (
      this.userData.user.deptId == this.LEGAL_DEPT_ID ||
      this.$user.hasPermission("watchAll")
    ) {
      this.self = !this.define.showAllCourses;
    } else {
      this.self = true;
      this.searchData.createEmpId = this.userData.user.employeeId;
    }

    this.getClassList();
    this.getManagerList();
    this.getDataList();
  },
  computed: {
    hasPermissions() {
      const url = location.href;
      if (
        (url.indexOf("localhost") > -1 || url.indexOf("dev") > -1) &&
        (this.userData.user.deptId == this.LEGAL_DEPT_ID ||
          this.userData.user.deptId == this.WEB_DEPT_ID)
      ) {
        return true;
      } else if (this.userData.user.deptId == this.LEGAL_DEPT_ID) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    checkDefDatas() {
      this.checkDefDatas.forEach((dep) => {
        if (
          dep.doCourseDeptId == "AAll" ||
          dep.doCourseDeptId == "JAll" ||
          dep.doCourseDeptId == "TAll" ||
          dep.doCourseDeptId == "NAll"
        ) {
          let companyId = dep.doCourseDeptId.substr(1, 1);
          this.selected = this.managerList.filter(
            (item) => item.companyId == companyId
          );
        } else {
          const Index = this.managerList.findIndex(
            (item) => item.deptId == dep.doCourseDeptId
          );
          if (Index > -1) this.selected.push(this.managerList[Index]);
        }
      });
    },
    selected() {
      this.mailList = this.selected.map((item) => {
        return item.empEmail;
      });
    },
  },
  methods: {
    getClassList() {
      api.education.class().then(({ data }) => {
        this.classList = data.result;
      });
    },
    getManagerList() {
      api.education
        .manager({
          companyId: "", // 公司代號
          deptId: "", // 部門代號
        })
        .then(({ data }) => {
          this.managerList = data.result;
          this.managerList.forEach((item) => {
            item.optionName = item.deptName + " - " + item.employeeName;
          });
        });
    },
    getDataList() {
      this.loading = true;
      api.education.course.queryAll(this.searchData).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
        }
        this.loading = false;
      });
    },
    resetDataList() {
      this.cancelAll();
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    showSelf(data) {
      this.define.showAllCourses = Boolean(data.checked != "1");
      if (data.checked == "1") {
        this.searchData.createEmpId = this.userData.user.employeeId;
      } else {
        this.searchData.createEmpId = "";
      }
      this.resetDataList();
    },
    checkAll(data) {
      this.isCheckAll = data.checked;
      this.list.forEach((item) => {
        item.checked = this.isCheckAll;
      });
    },
    cancelAll() {
      this.isCheckAll = 0;
      this.list.forEach((item) => {
        item.checked = 0;
      });
      this.checkIds = [];
      this.checkDatas = [];
    },
    checkChange(data) {
      const id = data.value.courseId;
      if (data.checked && !this.checkIds.includes(id)) {
        this.checkIds.push(id);
        this.checkDatas.push({
          courseId: data.value.courseId,
          courseName: data.value.courseName,
          courseType: data.value.courseType,
          isRequire: data.value.isRequire,
          doCourseDeptList: data.value.doCourseDeptList,
        });
      } else if (!data.checked && this.checkIds.includes(id)) {
        const index = this.checkIds.indexOf(id);
        if (index !== -1) {
          this.checkIds.splice(index, 1);
          this.checkDatas.splice(index, 1);
        }
      }
    },
    async onPageChange(pageIdx, pageSize) {
      this.checkIds = [];
      this.checkDatas = [];
      this.list = [];
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    add() {
      this.$router.push({
        name: "ETCourseEdit",
        params: {
          searchData: this.searchData,
        },
      });
    },
    edit(item) {
      this.$router.push({
        name: "ETCourseEdit",
        params: {
          id: item.courseId,
          searchData: this.searchData,
        },
      });
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          item.isDel = 1;
          item.courseStatus = 0;
          api.education.course.update(item).then((res) => {
            this.global.showResponse(this, res, "delete", this.getDataList);
          });
        }
      });
    },
    goRecordAll() {
      let checkIds = this.checkIds.toString();
      if (checkIds == "") {
        this.$eventBus.$emit("showAlert", "請勾選課程");
        return;
      }

      this.$router.push({
        name: "ETCourseRecordAll",
        params: {
          searchData: this.searchData,
        },
        query: {
          ids: checkIds,
        },
      });
    },
    showMail() {
      if (this.checkDatas.length == 0) {
        this.$eventBus.$emit("showAlert", "請勾選課程");
        return;
      }

      this.resetMail();
      this.checkDatas.forEach((course) => {
        course.doCourseDeptList.forEach((dep) => {
          const alreadyHad = this.checkDefDatas.filter(
            (sub) => sub.doCourseDeptId == dep.doCourseDeptId
          );
          if (alreadyHad.length == 0) {
            this.checkDefDatas.push(dep);
          }
        });
      });

      $(this.$refs.mail).modal("show");
    },
    noMailPermission() {
      this.$eventBus.$emit(
        "showAlert",
        "如需發信提醒完成必修課程功能，請洽開發人員開啟權限"
      );
    },
    closeMail() {
      this.mailContent = `提醒您，未完成以下之教育訓練，請於EIP-教育訓練完成課程。`;
      this.mailList = [];
      this.selected = [];
      this.checkDefDatas = [];
      $(this.$refs.mail).modal("hide");
    },
    sendMail() {
      if (this.mailLoading) return;

      if (this.mailContent == "") {
        this.$eventBus.$emit("showAlert", "請輸入信件內容");
        return;
      }

      this.mailLoading = true;
      api.education.course
        .mail({
          CompanyId: "A", // 公司id
          CourseIdList: this.checkIds, // 課程id列表
          MailContent: this.mailContent, // 信件內容
          CcList: this.mailList, // 副本mail
        })
        .then((res) => {
          this.mailLoading = false;
          this.global.showResponse(this, res, "send", this.closeMail);
        });
    },
    resetMail() {
      this.mailContent = `提醒您，未完成以下之教育訓練，請於EIP-教育訓練完成課程。`;
      this.selected = [];
      this.checkDefDatas = [];
    },
    goChapter(item) {
      this.$router.push({
        name: "ETChapter",
        params: {
          id: item.courseId,
          name: item.courseName,
          searchData: this.searchData,
        },
      });
    },
    goQuiz(item) {
      this.$router.push({
        name: "ETCourseQuizEdit",
        params: {
          id: item.courseId,
          name: item.courseName,
          searchData: this.searchData,
        },
      });
    },
    goDetail(item) {
      localStorage.setItem("ETCourseDetail_" + item.courseId, item.isRequire);
      this.$router.push({
        name: "ETCourseDetail",
        params: {
          id: item.courseId,
          name: item.courseName,
          searchData: this.searchData,
        },
      });
    },
    copyCourse(item) {
      api.education.course
        .copy({ CourseId: item.courseId })
        .then(({ data }) => {
          if (data.status == 1) {
            this.$eventBus.$emit("showAlert", "複製課程成功！");
            this.resetDataList();
          } else if (data.message) {
            let stateCode = data.stateCode ? "(" + stateCode + ")" : "";
            this.$eventBus.$emit("showAlert", data.message + stateCode);
          }
        });
    },
  },
};
</script>

<style>
#CourseList .icheckbox_flat-blue {
  background: white;
  border: 2px solid #d2d6de;
  border-radius: 5px;
}
#CourseList .icheckbox_flat-blue.checked {
  background: #3498db;
  border-color: #3498db;
}
#CourseList .icheckbox_flat-blue.checked::before {
  content: "✔";
  color: white;
  font-size: 17px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: 1px;
}
#CourseList .btn-brown {
  background-color: #d47937;
  color: white;
}
#CourseList .dropdown-menu {
  min-width: fit-content;
}
</style>