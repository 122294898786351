<template>
  <div id="quiz">
    <div class="box-body">
      <div class="top-box">
        <div class="total-score">
          得分：{{ myScore }} / {{ totalScore }} （最高紀錄：{{
            personalResultScore
          }}
          分）
        </div>
        <span class="note text-red" v-if="quizDatas"
          >*本測驗及格分數：{{ Number(quizDatas.passingScore) }}（{{
            isFinished ? "通過" : "尚未通過"
          }}）</span
        >
      </div>
    </div>
    <div class="box-body" v-if="quizDatas">
      <div class="form-group" v-for="(quiz, index) in questions" :key="index">
        <div class="question">
          <div
            class="title col-sm-11"
            :class="{
              'text-green': isSubmit && !quiz.isWrong,
              'text-red': (isSubmit && quiz.isWrong) || quiz.isAnswerYet,
            }"
          >
            <i class="fa fa-check" v-if="isSubmit && !quiz.isWrong"></i>
            <i class="fa fa-times" v-if="isSubmit && quiz.isWrong"></i>
            {{ index + 1 }}. {{ quiz.questionText }}
          </div>
          <div class="score text-gray col-sm-1">{{ quiz.questionScore }}分</div>
        </div>
        <div
          class="options col-sm-12"
          :class="{ mb0: isSubmit && quiz.isWrong }"
        >
          <div
            class="radio-block"
            v-for="(option, oIndex) in quiz.options"
            :key="oIndex"
          >
            <label class="custom-radio" v-if="isPreview">
              <input
                :name="`question${index}`"
                type="radio"
                :value="oIndex + 1"
                :disabled="true"
                v-model="quiz.correctAnswer"
              />
              <i class="icon"></i>
              <span>{{ addFromCharCode(oIndex + 1, option.optionText) }}</span>
            </label>
            <label class="custom-radio" v-else>
              <input
                :name="`question${index}`"
                type="radio"
                :value="oIndex + 1"
                :disabled="isSubmit"
                v-on:input="myAnswer(option.questionId, oIndex + 1)"
                ref="iCheckRedioButton"
              />
              <i class="icon"></i>
              <span>{{ addFromCharCode(oIndex + 1, option.optionText) }}</span>
            </label>
          </div>
        </div>
        <div
          class="answer col-sm-12 text-green"
          v-if="isSubmit && quiz.isWrong"
        >
          正確答案：{{ showCorrectAnswer(quiz) }}
        </div>
      </div>
      <div class="col-sm-12">
        <button
          class="btn btn-primary"
          v-if="!isPreview"
          @click="isSubmit ? resetExam() : submitExam()"
        >
          {{ isSubmit ? "重新測驗" : "送出" }}
        </button>
        <p class="text-red" v-if="isAnswerYet">* 尚有題目未完成</p>
      </div>
    </div>
  </div>
</template>

<style>
#quiz .iradio_flat-blue.checked.disabled {
  background-position: -110px 0;
}
</style>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckRedioButton,
  },
  props: ["isPreview", "datas", "isFinished"],
  data() {
    return {
      isSubmit: false,
      quizDatas: [],
      myAnswerList: [],
      myScore: 0,
      personalResultScore: 0,
      isAnswerYet: false,
      totalScore: 0,
      isQuiz: false,
      questions: [],
    };
  },
  watch: {
    datas: {
      handler: function (val) {
        this.quizDatas = val;
        this.setQuestionsList();
      },
      deep: true,
      immediate: true,
    },
    $route(val) {
      this.isQuiz = val.query && val.query.quiz;
    },
    myAnswerList: {
      handler: function (val) {},
      deep: true,
      immediate: true,
    },
    questions: {
      handler: function (val) {},
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.isQuiz = this.$route.query && this.$route.query.quiz;
    this.showLoading();
    this.getQuizDatas();
    this.getPersonalQuizResult();
  },
  methods: {
    setQuestionsList() {
      if (this.quizDatas && this.quizDatas.questions) {
        this.questions = this.quizDatas.questions;
      }
      this.hideLoading();
    },

    // 取得測驗題目
    getQuizDatas() {
      if (this.isQuiz) {
        api.education.quiz
          .queryAll({
            CourseId: this.$route.params.id, // 課程id
            TestId: this.$route.params.chapterId, // 測驗id
          })
          .then((res) => {
            if (res.data.status == 1) {
              this.quizDatas = res.data.result[0];
              this.setQuestionsList();
              this.updateScore();
            }
          });
      }
    },

    // 取得個人測驗結果
    getPersonalQuizResult() {
      if (this.isQuiz) {
        api.education.quiz
          .result({
            EmployeeId: this.$store.state.loginUser.employeeId, // 員編
            TestId: this.$route.params.chapterId, // 測驗id
          })
          .then((res) => {
            if (res.data.status == 1 && res.data.result[0]) {
              this.personalResultScore = res.data.result[0].score;
            }
          });
      }
    },

    showCorrectAnswer(quiz) {
      const Index = quiz.options.findIndex(
        (item) => item.optionOrder == quiz.correctAnswer
      );
      if (Index !== -1) {
        let num = Index + 1;
        let itemAlpha = String.fromCharCode(64 + num) + ". ";
        return itemAlpha + quiz.options[Index].optionText;
      }
    },

    myAnswer(questionId, myAnswer) {
      const Index = this.myAnswerList.findIndex(
        (item) => item.QuestionId === questionId
      );
      if (Index !== -1) {
        if (this.myAnswerList[Index].Answer == myAnswer) return;
        this.myAnswerList[Index].Answer = myAnswer;
      } else {
        this.myAnswerList.push({
          QuestionId: questionId, // 題目id
          Answer: myAnswer, // 答案
        });
      }
    },

    checkAnswerWrong() {
      const that = this;
      if (that.questions) {
        that.questions.forEach((quiz) => {
          const Index = that.myAnswerList.findIndex(
            (item) => item.QuestionId === quiz.id
          );
          if (
            Index !== -1 &&
            that.myAnswerList[Index].Answer == quiz.correctAnswer
          ) {
            that.myScore = that.myScore + Number(quiz.questionScore);
            quiz.isWrong = false;
          } else {
            quiz.isWrong = true;
          }
        });
      }
    },

    checkAnswerFill() {
      const that = this;
      that.isAnswerYet = false;
      if (that.questions) {
        that.questions.forEach((quiz) => {
          const Index = that.myAnswerList.findIndex(
            (item) => item.QuestionId === quiz.id
          );
          if (Index !== -1) {
            quiz.isAnswerYet = false;
          } else {
            that.isAnswerYet = true;
            quiz.isAnswerYet = true;
          }
        });
      }
    },

    // 重新測驗的答案清空重置
    resetExam() {
      if (this.isSubmit) {
        this.isSubmit = false;
        this.myScore = 0;

        // 清空選擇
        const iCheckRedioButton = this.$refs.iCheckRedioButton;
        if (iCheckRedioButton) {
          iCheckRedioButton.forEach((item) => {
            item.setStatus("", "reset");
          });
        }
        this.myAnswerList = []; //需先reset，再清除
      }
    },

    // 提交測驗結果
    submitExam() {
      this.checkAnswerFill();
      if (this.myAnswerList.length !== this.questions.length) {
        return;
      }

      this.$eventBus.$emit("showConfirm", "確認是否送出測驗結果?", (isOK) => {
        if (isOK) {
          this.isAnswerYet = false;
          this.isSubmit = true;
          this.checkAnswerWrong();

          if (this.isQuiz) {
            api.education.quiz
              .submitExam({
                TestId: this.$route.params.chapterId, // 測驗id
                Answers: this.myAnswerList,
              })
              .then((res) => {
                if (res.data.status == 1) {
                  if (res.data.result.score > this.personalResultScore) {
                    this.personalResultScore = res.data.result.score;
                  }
                  if (res.data.result.isPass) {
                    this.$emit("updateStatus", res.data.result.isPass);
                  }
                }
              });
          }
        }
      });
    },

    // 更新總分
    updateScore() {
      if (this.questions.length > 0) {
        let totalScore = 0;
        this.questions.forEach((item) => {
          totalScore += Number(item.questionScore);
        });
        this.totalScore = totalScore;
      }
    },

    addFromCharCode(index, text) {
      return String.fromCharCode(64 + index) + ". " + text;
    },
  },
};
</script>

<style lang="scss">
@mixin max-lg {
  @media (max-width: 1199px) {
    @content;
  }
}
#quiz {
  .top-box {
    .text-red {
      font-size: 16px;
    }
  }
  .total-score {
    display: inline-block;
    font-size: 18px;
    padding: 14px 8px;
    margin-right: 10px;
    border: 1px solid;
  }
  .note {
    display: inline-block;
    @include max-lg {
      margin-top: 5px;
    }
  }
  .form-group {
    .question {
      .title {
        font-size: 16px;
      }
      .score {
        color: #bfbfbf !important;
        word-break: keep-all;
      }
    }
    .options {
      margin-bottom: 25px;
      font-size: 15px;
      &.mb0 {
        margin-bottom: 0 !important;
      }
    }
    .answer {
      background-color: #e6f4ea;
      padding: 15px;
      margin-bottom: 20px;
    }
  }
}
</style>