<template>
  <div id="CapterEdit">
    <section class="content-header">
      <h1>章節管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">章節管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning company-files-edit">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">
              章節{{ isEdit ? "編輯" : "新增" }}
            </h4>
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="chapterName">
                  <span class="text-red">*</span> 章節名稱：
                </label>
                <div class="col-sm-8">
                  <input
                    id="chapterName"
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="data.chapterName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="chapterDesc">
                  章節說明：
                </label>
                <div class="col-sm-8">
                  <textarea
                    id="chapterDesc"
                    class="form-control"
                    required="required"
                    v-model="data.chapterDesc"
                  ></textarea>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="teacher">
                  講師：
                </label>
                <div class="col-sm-4">
                  <input
                    id="teacher"
                    class="form-control"
                    required="required"
                    type="text"
                    v-model="data.teacher"
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  class="col-sm-3 control-label"
                  :for="
                    learnMaterialType == 0
                      ? 'inputVideo'
                      : learnMaterialType == 1
                      ? 'pptUrl'
                      : learnMaterialType == 2
                      ? 'inputPDF'
                      : ''
                  "
                >
                  <span class="text-red">*</span> 課程形式：
                </label>
                <div class="col-sm-8">
                  <template
                    v-if="(data.learnMaterialType != 2 && isEdit) || !isEdit"
                  >
                    <div class="radio-inline">
                      <iCheckRedioButton
                        :checkValue="0"
                        message="影片"
                        name="radioMode1"
                        v-model="learnMaterialType"
                      />
                    </div>
                    <div class="radio-inline">
                      <iCheckRedioButton
                        :checkValue="1"
                        message="嵌入碼(Youtube影片、Google簡報...適用)"
                        name="radioMode1"
                        v-model="learnMaterialType"
                      />
                    </div>
                  </template>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="2"
                      message="文件閱讀"
                      name="radioMode1"
                      v-model="learnMaterialType"
                    />
                  </div>
                  <template v-if="learnMaterialType == 0">
                    <div class="mt-10" v-if="videoFile && videoFile.length < 1">
                      <input
                        @change="changeFile($event, 'EducationChapterFile')"
                        accept="video/*"
                        id="inputVideo"
                        name="file"
                        type="file"
                      />
                    </div>
                    <p class="text-red">
                      *影片限制150MB，超過大小請剪輯後分段上傳
                    </p>
                  </template>
                  <template v-if="learnMaterialType == 2">
                    <div class="mt-10" v-if="videoFile && videoFile.length < 1">
                      <input
                        @change="changeFile($event, 'EducationChapterFile')"
                        accept=".pdf"
                        id="inputPDF"
                        name="file"
                        type="file"
                      />
                    </div>
                    <p class="text-red">*文件檔案上傳格是：PDF(上限5MB)</p>
                  </template>

                  <div class="mt-10" v-if="learnMaterialType == 1">
                    <div>
                      請填上嵌入碼
                      <a
                        target="_blank"
                        class="pull-right"
                        href="https://steam.oxxostudio.tw/category/html/example/google-ppt.html#google_vignette"
                      >
                        <i class="fa fa-info-circle"></i>
                        Goolge簡報嵌入碼在哪裡
                      </a>
                    </div>
                    <textarea
                      id="pptUrl"
                      class="form-control"
                      rows="3"
                      v-model="data.pptUrl"
                    ></textarea>
                  </div>
                  <div v-else class="mt-10">
                    <ul class="file-list list-unstyled">
                      <li v-for="(file, index) in videoFile" :key="index">
                        <div class="name">{{ file.name }}</div>
                        <div class="btn-area">
                          <button
                            type="button"
                            class="btn btn-danger"
                            @click="
                              deleteFile(file.applyId, 'EducationChapterFile')
                            "
                          >
                            刪除
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                class="form-group"
                v-if="data.learnMaterialType == 2 || learnMaterialType == 2"
              >
                <label class="col-sm-3 control-label"> 完課條件： </label>
                <div class="col-sm-8">
                  <div class="checkbox">
                    <label>
                      <iCheckCheckBox
                        v-bind:propChecked="data.clickToFinish"
                        v-on:update:checked="
                          data.clickToFinish = $event['checked']
                        "
                      />
                      需打勾確認完成閱讀
                    </label>
                  </div>
                  <div class="instruction">
                    <p>文案：</p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      maxlength="100"
                      v-model="data.finishMsg"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group" v-else>
                <label class="col-sm-3 control-label" for="chapterTime">
                  <span class="text-red">*</span> 時數：
                </label>
                <div class="col-sm-4">
                  <div class="input-group time">
                    <div class="input-group-addon">
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <input
                      id="chapterTime"
                      class="form-control timepicker"
                      type="text"
                      :disabled="isEdit"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="inputFile">
                  其他教材：
                </label>
                <div class="col-sm-8 text">
                  <input
                    @change="changeFile($event, 'EducationChapter')"
                    accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip"
                    id="inputFile"
                    maxlength="5"
                    name="file2"
                    type="file"
                  />
                  <div class="help-block mt-10">
                    文件檔案上傳格式：
                    <span class="text-red">(上限10MB)</span>
                    <br />1.WORD：DOC、DOCX。 <br />2.EXCEL：XLS、XLSX。
                    <br />3.POWERPOINT：PPT、PPTX。 <br />4.PDF。 <br />5.TXT。
                    <br />6.XPS。 <br />7.ZIP。
                    <br />(需上傳多個檔案建議使用ZIP檔上傳)
                  </div>
                </div>
              </div>

              <div class="form-group" v-if="files && files.length > 0">
                <label class="col-sm-3 control-label"> 已有檔案： </label>
                <div class="col-sm-8">
                  <ul class="file-list list-unstyled">
                    <li v-for="(file, index) in files" :key="index">
                      <div class="name">{{ file.name }}</div>
                      <div class="btn-area">
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="deleteFile(file.applyId, 'EducationChapter')"
                        >
                          刪除
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  <span class="text-red">*</span> 狀態：
                </label>
                <div class="col-sm-8">
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="啟用"
                      name="radioMode2"
                      v-model="data.chapterStatus"
                    />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      :checkValue="0"
                      message="停用"
                      name="radioMode2"
                      v-model="data.chapterStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="save()"
                  :disabled="waittingFilesUpload"
                >
                  {{ waittingFilesUpload ? "檔案上傳中..." : "送出" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
#CapterEdit {
  .instruction {
    display: flex;
    align-items: center;
    margin-top: 8px;
    p {
      margin-bottom: 0;
      margin-right: 5px;
      word-break: keep-all;
    }
  }
}
</style>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import DatePicker from "@/components/DatePicker";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {
    iCheckRedioButton,
    iCheckCheckBox,
    DatePicker,
  },
  data() {
    return {
      isEdit: false,
      data: {
        chapterName: this.$route.params.chapterName || "", // 章節名稱
        chapterDesc: "", // 章節介紹
        courseId: this.$route.params.id || "", // 課程id
        chapterId: this.$route.params.chapterId || utils.getTimeId(),
        chapterStatus: 1, // 章節狀態
        chapterTime: "00:00:00", // 章節時數
        learnMaterialType: 0, // 教材類型 0:影片、1:簡報、2:文件閱讀
        teacher: "", // 講師
        pptUrl: "",
        clickToFinish: 1,
        finishMsg: "我已確實閱讀完畢並同意遵守公司政策規範",
      },
      files: [],
      videoFile: [],
      startTime: new Date(),
      waittingFilesUpload: false,
      backLearnMaterialType: false,
      learnMaterialType: 0,
    };
  },
  watch: {
    learnMaterialType(newVal, oldVal) {
      if (this.backLearnMaterialType) {
        this.backLearnMaterialType = false;
        return;
      }

      this.changeLearnMaterialType(newVal, oldVal);
    },
  },
  async created() {
    if (this.$route.params.chapterId) {
      this.isEdit = true;
      this.getData();
    }
  },
  mounted() {
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });

    $(".timepicker").timepicker({
      showInputs: false,
      minuteStep: 1,
      secondStep: 1,
      showSeconds: true,
      showMeridian: false,
      defaultTime: "00:00:00",
    });
  },
  methods: {
    getData() {
      api.education.chapter
        .query({
          courseId: this.$route.params.id, // 課程id
          chapterId: this.$route.params.chapterId, // 章節id
        })
        .then(({ data }) => {
          this.data = data.result;
          this.files = this.data.files;
          this.videoFile = this.data.videoFiles;
          this.startTime = this.dateFormat(this.data.startTime);
          $("#chapterTime").val(this.data.chapterTime);

          if (
            this.isEdit &&
            this.data.learnMaterialType != this.learnMaterialType
          ) {
            this.backLearnMaterialType = true;
            this.learnMaterialType = this.data.learnMaterialType;
          }
        });
    },
    changeFile(e, module) {
      var files = e.target.files;

      for (var i = 0; i < files.length; i++) {
        var fileSize = files[0].size;
        var re =
          module == "EducationChapter"
            ? /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i
            : this.learnMaterialType == 2
            ? /\.(pdf)$/i
            : /\.(mp4|m4v|mov|qt|avi|flv|wmv|asf|mpeg|mpg|vob|mkv|asf|rm|rmvb|vob|ts|dat)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (!re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (this.learnMaterialType == 0 && fileSize > 150000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 150 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (this.learnMaterialType == 2 && fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (module == "EducationChapter" && fileSize > 10000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 10 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //檔案上傳
      this.uploadFile(files, module, this.data.chapterId, true);
    },

    removeFile() {
      $("#inputVideo, #inputPDF").val("");
    },

    async uploadFile(files, module, id, multi = false) {
      this.waittingFilesUpload = true;
      let formdata = new FormData();

      for (var i = 0; i < files.length; i++) {
        formdata.append("files", files[i]);
      }

      formdata.append("files", files);
      formdata.append("module", module);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      if (response_upload.data.status == 1) {
        $("#inputFile").val("");
      } else {
        if (response_upload.data.message) {
          this.$eventBus.$emit("showAlert", response_upload.data.message);
        }
      }
      this.refreshFileList(module);
    },

    async refreshFileList(module) {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.data.chapterId,
        module: module,
      });
      var response_queryall = await task_queryall;
      if (module == "EducationChapter") {
        this.files = response_queryall.data.result;
      } else {
        this.videoFile = response_queryall.data.result;
      }
      this.waittingFilesUpload = false;
    },

    deleteFile(applyId, module, isAgree) {
      if (isAgree) {
        this.keepDeleteFile(applyId, module);
      } else {
        this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
          if (isOK) {
            this.keepDeleteFile(applyId, module);
          }
        });
      }
    },
    keepDeleteFile(applyId, module) {
      api.file.deleteApply({ id: applyId }).then((res) => {
        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", "刪除成功!");
        } else {
          this.$eventBus.$emit("showAlert", "刪除失敗!");
        }
        this.refreshFileList(module);
      });
    },
    save() {
      const chapterTime = $("#chapterTime").val();
      this.data.chapterTime = chapterTime;

      const action = this.isEdit ? "update" : "create";

      if (this.data.chapterName == "") {
        this.$eventBus.$emit("showAlert", "章節名稱不能為空");
        return;
      }

      const seconds = moment.duration(chapterTime).as("seconds");
      if (this.learnMaterialType != 2 && seconds == 0) {
        this.$eventBus.$emit("showAlert", "時數不能為0");
        return;
      }

      if (
        (this.learnMaterialType == 0 && this.videoFile == "") ||
        (this.learnMaterialType == 2 && this.videoFile == "") ||
        (this.learnMaterialType == 1 && this.data.pptUrl == "")
      ) {
        this.$eventBus.$emit("showAlert", "請上傳上課影片/嵌入碼/文件");
        return;
      }

      if (
        this.learnMaterialType == 1 &&
        this.data.pptUrl.indexOf("iframe") == -1
      ) {
        this.$eventBus.$emit("showAlert", "請確認嵌入碼");
        return;
      }

      if (this.data.isFinishedRead == 0) {
        this.data.finishMsg = "";
      }
      if (this.learnMaterialType != 2) {
        this.data.clickToFinish = 0;
        this.data.finishMsg = "";
      }

      this.data.startTime = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      this.data.learnMaterialType = this.learnMaterialType;

      if (this.isEdit) {
        api.education.chapter[action](this.data).then((res) => {
          this.global.showResponse(this, res, action, this.back);
        });
      } else {
        const confirmText =
          this.learnMaterialType == 2
            ? "文件閱讀，新增後無法更改課程形式，確定新增嗎？"
            : "新增後無法修改上課時數，確定新增嗎？";
        this.$eventBus.$emit("showConfirm", confirmText, (isOK) => {
          if (isOK) {
            api.education.chapter[action](this.data).then((res) => {
              this.global.showResponse(this, res, action, this.back);
            });
          }
        });
      }
    },
    dateFormat(time) {
      let newTime = time ? time : new Date();
      return new moment(newTime).format("YYYY/MM/DD");
    },
    back() {
      this.$router.push({
        name: "ETChapter",
        params: {
          id: this.$route.params.id,
          name: this.$route.params.name,
          searchData: this.$route.params.searchData,
        },
      });
    },

    changeLearnMaterialType(newVal, oldVal) {
      if (this.videoFile && this.videoFile.length < 1) return;

      let type = newVal == 1 ? "影片" : newVal == 2 ? "文件" : "檔案";
      this.$eventBus.$emit(
        "showConfirm",
        "切換課程形式類別，會刪除" + type + "，是否確認切換？",
        (isOK) => {
          if (isOK) {
            this.videoFile.forEach((file) => {
              this.deleteFile(file.applyId, "EducationChapterFile", "agree");
            });
          } else {
            this.backLearnMaterialType = true;
            this.learnMaterialType = oldVal;
            this.$nextTick(() => {
              $("#chapterTime").val(this.data.chapterTime);
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
#CapterEdit {
  .text-gray {
    color: #999 !important;
  }

  .form-group {
    .file-list {
      li {
        display: flex;
        align-items: center;
        border: 1px solid #e3e3e3;
        border-bottom: none;
        padding-left: 5px;
        &:last-child {
          border: 1px solid #e3e3e3;
        }
        .name {
          width: calc(100% - 55px);
        }
        .btn-area {
          border-left: 1px solid #e3e3e3;
          width: 55px;
          .btn {
            margin: auto !important;
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
</style>
