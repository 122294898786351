<template>
  <div id="AnnouncementList">
    <section class="content-header">
      <h1>
        {{
          announcementtypes.typeName == "內部公告"
            ? announcementtypes.typeName + "(行政公告/其他公告/EIP留存公告)"
            : announcementtypes.typeName
        }}
      </h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司公告</li>
        <li class="active">{{ announcementtypes.typeName }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
              v-if="checkPermission('AN-03')"
            >
              <i class="fa fa-plus"></i> 新增公告
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.target"
                @change="workShowAll(true)"
              >
                <option
                  :key="index"
                  :value="item.targetId"
                  v-for="(item, index) in targetList"
                  >{{ item.targetName }}</option
                >
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.issuedUnit"
                @change="workShowAll()"
              >
                <option value>全部單位</option>
                <option
                  :key="index"
                  :value="item.issuedUnit"
                  v-for="(item, index) in issuedUnitList"
                  >{{ item.issuedUnitName }}</option
                >
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋公告主旨"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  v-on:click="clickSearch()"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="(searchShow = 1)">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ announcements.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 90px; width: 5%">發布日期</th>
                  <th style="min-width: 90px; width: 5%">發布時間</th>
                  <th class="text-nowrap" style="width: 10%;">公告文號</th>
                  <th class="text-nowrap" style="width: 10%;">公告單位</th>
                  <th class="text-nowrap" style="width: 5%;">公告聯絡人</th>
                  <th
                    class="text-nowrap"
                    style="width: 5%;"
                    v-if="checkPermission('AN-03')"
                  >
                    承辦人
                  </th>
                  <th style="min-width: 150px;">公告主旨</th>
                  <th class="text-nowrap text-center">詳閱檔案</th>
                  <th
                    class="text-center"
                    style="min-width: 60px; width: 5%;"
                    v-if="checkPermission('AN-03')"
                  >
                    狀態
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 10%;"
                    v-if="checkPermission('AN-03')"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="announcements.total > 0">
                  <tr :key="item.id" v-for="item in announcements.datas">
                    <td>
                      {{
                        item.status != "1"
                          ? item.publishTime.split("T")[0]
                          : "--"
                      }}
                    </td>
                    <td>
                      {{
                        item.status != "1"
                          ? item.publishTime.split(/[T.]/)[1]
                          : "--"
                      }}
                    </td>
                    <td>{{ item.docNo }}</td>
                    <td>{{ item.issuedUnitName }}</td>
                    <td>{{ item.contactEmpName }}</td>
                    <td v-if="checkPermission('AN-03')">
                      {{ item.contractorName }}
                    </td>
                    <td>
                      <!-- <small class="label bg-red">New</small> -->
                      <a
                        href="javascript:void(0)"
                        @click="showAnnouncement(item.id)"
                        >{{ item.title }}</a
                      >
                    </td>
                    <td class="text-center">
                      <a
                        :key="file.id"
                        :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                        class="file-icon"
                        v-for="file in item.files"
                        v-show="item.files.length > 0"

                      >
                        <i
                          :class="'icon icon-' + file.mine.substring(0, 3)"
                        ></i>
                      </a>
                      <span v-show="item.files.length == 0">-</span>
                    </td>
                    <td class="text-center" v-if="checkPermission('AN-03')">
                      <span class="text-red" v-if="item.status < 2"
                        >未發布</span
                      >
                      <span v-else>已發布</span>
                    </td>
                    <td class="text-center" v-if="checkPermission('AN-03')">
                      <button
                        @click="editorItem(item)"
                        class="btn btn-primary"
                        v-if="checkUserInfo(item.target)"
                      >
                        修改
                      </button>
                      &nbsp;
                      <button
                        @click="deleteItem(item)"
                        class="btn btn-danger"
                        v-if="checkUserInfo(item.target)"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="announcements.total > 0"
        >
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";

export default {
  components: {
    Pagination
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: ""
  },
  watch: {
    $route(to, from) {
      this.searchData.keyword = "";
      this.searchData.target = this.userData.companyId;
      this.searchData.page = 1;
      this.workShowAll();
    }
  },
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      searchShow: 1,
      announcementtypes: [],
      announcements: [],
      announcementTemp: [],
      userPermission: "",
      userData: "",
      hasEditPermission: false,
      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數

      targetList: [],

      issuedUnitList: [],

      searchData: {
        keyword: "",
        target: "",
        issuedUnit: "",
        page: 1
      }
    };
  },
  async created() {
    await this.getTarget();
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.AnnouncementEdit
    );
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.workShowAll();
  },
  mounted() {},
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.pageSize = pageSize;
      this.workShowAll();
    },
    async workShowAll(refresh = false) {
      if (refresh) this.searchData.issuedUnit = "";

      //*---- 單筆公告
      var task_uni = api.announcementtype.query({
        id: this.$route.params.id
      });
      var response_uni = await task_uni;
      this.announcementtypes = response_uni.data.result;

      //*---- 顯示列表
      var task_all = api.announcement.queryall({
        typeId: this.$route.params.id,
        pageSize: this.pageSize,
        pageNum: this.searchData.page,
        keyword: this.searchData.keyword,
        target: this.searchData.target,
        issuedUnit: this.searchData.issuedUnit,
        isManagerQuery: this.hasEditPermission ? 1 : 0
      });
      var response_all = await task_all;
      this.announcementTemp = JSON.parse(
        JSON.stringify(response_all.data.result)
      );

      //公告單位
      var task_issuedUnit = await api.announcement.queryIssuedUnit({
        typeId: this.$route.params.id,
        target: this.searchData.target == "A" ? "A,0" : this.searchData.target,
        isManagerQuery: this.hasEditPermission ? 1 : 0
      });

      this.issuedUnitList = task_issuedUnit.data.result.datas;

      this.announcements = response_all.data.result;
      this.total = this.announcements.total;
      this.$refs.pagination.setPage(this.searchData.page, this.total);
    },

    clickSearch() {
      this.searchData.page = 1;
      this.workShowAll();
    },

    async getTarget() {
      this.userData = this.$store.state.loginUser;
      //*----對象
      var task_target = api.announcement.targetList();
      var response_target = await task_target;
      this.targetList = response_target.data.result;

      this.searchData.target = this.userData.companyId;
    },

    //*---- 新增內容
    addNew() {
      this.$router.push({
        name: "AnnouncementEdit",
        params: { type: this.id, searchData: this.searchData }
      });
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.$router.push({
        name: "AnnouncementEdit",
        params: { id: item.id, type: this.id, searchData: this.searchData }
      });
      return;
    },

    showAnnouncement(id) {
      this.$router.push({
        name: "Announcement",
        params: { id: id, type: this.id, searchData: this.searchData }
      });
      return;
    },

    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          api.announcement.delete(item).then(res => {
            if (res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "刪除成功!");
            } else {
              this.$eventBus.$emit("showAlert", "刪除失敗!");
            }
            this.workShowAll();
          });
        }
      });
    },
    checkPermission(permission) {
      return utils.checkByUserPermission(permission);
    },
    checkUserInfo(target) {
      if (target == "0") {
        if (!this.checkPermission(config.Permission.AnnouncementEditAll))
          return false;
      } else if (target == "A" || target == "A01" || target == "A02" || target == "B01") {
        if (this.userData.companyId != "A") return false;
      }
      return true;
    }
  }
};
</script>
