<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>{{ announcementtypes.typeName }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司公告</li>
        <li class="active">{{ announcementtypes.typeName }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯公告</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <form class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputDocNum">
                  <span class="text-red">*</span> 公告文號：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputDocNum"
                    required="required"
                    type="text"
                    v-model="edit.docNo"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectUnit">
                  <span class="text-red">*</span> 公告單位：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="edit.issuedCompanyId"
                    v-on:change="selectCompany()"
                  >
                    <option disabled="disabled" value>請選擇公司</option>
                    <option
                      :key="index"
                      :value="company.companyId"
                      v-for="(company, index) in companyOptions"
                      >{{ company.companyName }}</option
                    >
                  </select>
                  <select
                    class="form-control mt-10"
                    id="selectUnit"
                    required="required"
                    v-model="edit.issuedUnit"
                    v-on:change="selectDept()"
                  >
                    <option disabled="disabled" value>請選擇單位</option>
                    <option
                      :key="index"
                      :value="dept.deptId"
                      v-for="(dept, index) in depts"
                      >{{ dept.deptIdName }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="AnnouncementName">
                  <span class="text-red">*</span> 公告聯絡人：
                </label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="edit.contactName">
                    <option disabled="disabled" value>請選擇</option>
                    <option
                      :key="index"
                      :value="emp.employeeId"
                      v-for="(emp, index) in empOptions"
                      >{{ emp.employeeName }}</option
                    >
                  </select>
                  <!-- <select2-multiple
                    :options="empOptions"
                    data-placeholder="請選擇聯絡人"
                    v-model="empSelected"
                  ></select2-multiple>-->
                </div>
              </div>
              <div class="form-group" v-if="userData.companyId == 'A'">
                <label class="col-sm-2 control-label" for="selectCompany">
                  <span class="text-red">*</span> 公告對象：
                </label>
                <div class="col-sm-10">
                  <select
                    @change="targetChange"
                    class="form-control"
                    id="selectUnit"
                    required="required"
                    v-model="edit.target"
                  >
                    <option disabled="disabled" value>請選擇</option>
                    <option value="0">網銀國際</option>
                    <option
                      :key="index"
                      :value="item.targetId"
                      v-for="(item, index) in targetOptions"
                      >{{ item.targetName }}</option
                    >
                  </select>

                  <div class="checkbox">
                    <label>
                      <iCheckCheckBox
                        :propDisabled="edit.target == '0'"
                        v-bind:propChecked="edit.isReadOnly"
                        v-on:update:checked="
                          edit.isReadOnly = $event['checked']
                        "
                      />
                    </label>
                    不開放其他公司瀏覽(選「網銀國際」時此欄位唯讀)
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  <span class="text-red">*</span> 承辦人：
                </label>
                <div class="col-sm-10">
                  <label class="control-label">{{ edit.contractorName }}</label>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputTitle">
                  <span class="text-red">*</span> 主旨：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    id="inputTitle"
                    required="required"
                    type="text"
                    v-model="edit.title"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <!-- 工具欄容器 -->
                    <!-- <div class="toolbar-container"></div> -->
                    <!-- 編輯器容器 -->
                    <!-- <div class="CKEditor"></div> -->
                    <tinymce v-model="edit.contents" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile"
                  >上傳檔案：</label
                >
                <div class="col-sm-10 text">
                  <input
                    @change="changeFile($event)"
                    accept=".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .txt, .xps, .zip"
                    id="inputFile"
                    maxlength="5"
                    multiple="multiple"
                    name="file1"
                    type="file"
                  />
                  <div class="help-block">
                    文件檔案上傳格式：
                    <span class="text-red">(上限10MB)</span>
                    <br />1.WORD：DOC、DOCX。 <br />2.EXCEL：XLS、XLSX。
                    <br />3.POWERPOINT：PPT、PPTX。 <br />4.PDF。 <br />5.TXT。
                    <br />6.XPS。 <br />7.ZIP。
                    <br />(每個檔案上限10MB，最多可以選5個檔案)
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile"
                  >已有檔案：</label
                >
                <div class="col-sm-10 text">
                  <table class="table table-bordered">
                    <tbody>
                      <tr :key="index" v-for="(file, index) in edit.files">
                        <td>{{ file.name }}</td>
                        <td>
                          <button
                            @click="deleteFile(file.applyId)"
                            class="btn btn-danger"
                            type="button"
                          >
                            刪除
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectMailGroup">
                  <span class="text-red">*</span> 發送群組：
                </label>
                <div class="col-sm-10">
                  <select2-multiple
                    :options="sendOptions"
                    data-placeholder="請選擇群組"
                    v-model="sendSelected"
                  ></select2-multiple>
                  <p class="text-orange mt-10">
                    <i class="fa fa-warning"></i>
                    如需新增群組，請洽系統開發人員。
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label" for="datetimepicker">
                  <span class="text-red">*</span> 發送公告：
                </label>
                <div class="col-sm-10">
                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        checkValue="2"
                        message="排程發送："
                        name="radioMode"
                        v-model="edit.mode"
                      />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker />
                      </div>
                    </div>
                    <div class="input-group time">
                      <div class="input-group-addon">
                        <i class="fa fa-clock-o"></i>
                      </div>
                      <input class="form-control timepicker" type="text" />
                    </div>
                  </div>
                  <div class="radio">
                    <iCheckRedioButton
                      checkValue="1"
                      message="立即發送"
                      name="radioMode"
                      v-model="edit.mode"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  @click="Confirm(1)"
                  class="btn btn-default"
                  type="button"
                  v-if="edit.status < 2"
                >
                  儲存為草稿
                </button>
                <button
                  @click="Confirm(2)"
                  class="btn btn-primary"
                  type="button"
                >
                  {{ saveButton }}
                </button>
                <button @click="Confirm(3)" class="btn bg-purple" type="button">
                  發佈寄信
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import select2Multiple from "@/components/select2Multiple";

import CKEditor from "@/components/CKEditor";
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh';

import api from "@/assets/js/app-api";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import utils from "@/assets/js/app-utils";
// import config from "@/assets/js/app-config";
import tinymce from "@/components/Tinymce";

export default {
  components: {
    iCheckCheckBox,
    iCheckRedioButton: iCheckRedioButton,
    CKEditor,
    "select2-multiple": select2Multiple,
    DatePicker,
    tinymce
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
    type: ""
  },
  watch: {
    $route(to, from) {
      this.workShowAll();
    }
  },
  data() {
    return {
      editType: false,
      announcementtypes: [],
      userData: "",

      depts: [],
      empOptions: [],

      targetOptions: [],

      companyOptions: [],

      sendSelected: ["all@wanin.tw"],
      sendOptions: [
        {
          text: "網銀總部 (all@wanin.tw)",
          id: "all@wanin.tw"
        },
        {
          text: "台北分公司 (wtp.all@wanin.tw)",
          id: "wtp.all@wanin.tw"
        },
        {
          text: "遊一卡 (yoe.all@yoe.com.tw)",
          id: "yoe.all@yoe.com.tw"
        }
      ],

      edit: {
        id: "", //公告Id
        typeId: this.type, //公告類別
        title: "", //公告標題
        docNo: "", // 公告文號
        issuedCompanyId: "", //公告公司Id
        issuedUnit: "", // 公告單位
        contactName: "", // 公告聯絡人
        target: "", //公告對象 各對象用 ',' 做區隔
        contents: "<p>Content of the editor.</p>", // 內容
        contractor: "", //承辦人
        email: "", //收件人 各mail用 ';' 做區隔
        mode: 1, // 1.立即發送 2.排程發送
        status: 0, // 1.草稿 2.儲存 3.儲存兼發信
        publishTime: "", //發佈時間
        isReadOnly: 0, //是否唯讀
        files: []
      },

      saveButton: ""
    };
  },
  created() {
    this.workShowAll();
    if (this.userData.companyId == "J") {
      this.sendOptions = [
        {
          text: "台北分公司 (wtp.all@wanin.tw)",
          id: "wtp.all@wanin.tw"
        }
      ];
    }
    if (this.userData.companyId == "E") {
      this.sendOptions = [
        {
          text: "遊一卡 (yoe.all@yoe.com.tw)",
          id: "yoe.all@yoe.com.tw"
        }
      ];
    }
    if (this.userData.companyId == "G") {
      this.sendOptions = [
        {
          text: "樂點公司 (gash.all@wanin.tw)",
          id: "gash.all@wanin.tw"
        },
      ];
    }

    this.sendSelected = [this.sendOptions[0].id]; //預設第一個
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue"
    });

    $(".timepicker").timepicker({
      showInputs: false
    });
  },
  methods: {
    async workShowAll() {
      this.userData = this.$store.state.loginUser;

      if (this.$route.params.id != "" && this.$route.params.id != undefined) {
        var task_query = api.announcement.query({
          id: this.$route.params.id
        });
        var response_query = await task_query;

        this.edit = JSON.parse(JSON.stringify(response_query.data.result));
        this.editorItem();
      } else {
        this.addNew();
      }

      var task_uni = api.announcementtype.queryEdit({
        id: this.$route.params.type
      });
      var response_uni = await task_uni;
      this.announcementtypes = response_uni.data.result;

      //*----對象
      if (this.userData.companyId == 'A') {
        var task_target = api.announcement.targetList();
        var response_target = await task_target;
        this.targetOptions = response_target.data.result;
      }

      //*----公司
      var task_company = api.organization.getCompanyList();
      var response_company = await task_company;
      this.companyOptions = response_company.data.result;
      if (this.userData.companyId != "A") {
        this.companyOptions = this.companyOptions.filter(company => {
          return company.companyId == this.userData.companyId;
        });
      }

      // 預設公告單位為自己公司
      this.edit.issuedCompanyId = this.userData.companyId;
      this.selectCompany();
    },

    checkUserPermission() {},

    async selectCompany() {
      this.empOptions = [];
      var task_deps = api.organization.getDeptList({
        companyId: this.edit.issuedCompanyId
      });
      var response_desps = await task_deps;
      this.depts = response_desps.data.result;
      if (this.depts.length == 0) {
        this.edit.issuedUnit = "";
        this.edit.contactName = "";
        return;
      }
      await this.selectDept();
    },

    //*---- 切換部門時替換員工資料
    async selectDept() {
      var task_emps = api.organization.getDeptEmpList({
        deptId: this.edit.issuedUnit,
        includePart: true
      });
      var response_emps = await task_emps;
      this.empOptions = response_emps.data.result;
    },

    //*---- 新增內容
    addNew() {
      this.saveButton = "儲存為正式稿";
      this.edit = {
        id: utils.getTimeId(), //公告Id
        typeId: this.type, //公告類別
        title: "", //公告標題
        docNo: "", // 公告文號
        issuedCompanyId: "", //公告公司Id
        issuedUnit: "", // 公告單位
        contactName: "", // 公告聯絡人
        target: "", //公告對象 各對象用 ',' 做區隔
        contents: "<p>Content of the editor.</p>", // 內容
        contractor: "", //承辦人
        contractorName: this.userData.employeeName,
        email: "", //收件人 各mail用 ';' 做區隔
        mode: 1, // 1.立即發送 2.排程發送
        status: 0, // 1.草稿 2.儲存 3.儲存兼發信
        publishTime: "", //發佈時間
        isPublished: 0,
        isReadOnly: 0, //是否唯讀
        files: []
      };

      this.empOptions = [];

      this.sendSelected = [this.sendOptions[0].id]; //預設第一個
        
      $(".datepicker, .timepicker").val("");

      $("#title").html("新增內容");
    },

    //*---- 修改內容
    async editorItem(item) {
      this.editType = true;
      this.saveButton = "發佈";
      $("#title").html("編輯公告");
      if (this.edit.publishTime != "") {
        $(".datepicker").val(
          new Date(this.edit.publishTime).toLocaleDateString()
        );
        $(".timepicker").val(
          new Date(this.edit.publishTime).toLocaleTimeString("en-US", {
            timeStyle: "short"
          })
        );
      }
      await this.selectCompany();
      await this.selectDept();
      if (this.edit.email != "" && this.edit.email != null)
        this.sendSelected = this.edit.email.split(";");
    },
    // onEditorContentChange(content) {
    //   this.edit.contents = content;
    // },
    //*---- 返回
    backList() {
      this.$router.push({
        name: "AnnouncementList",
        params: { id: this.type, searchData: this.$route.params.searchData }
      });
    },

    targetChange() {
      if (this.edit.target == "0") this.edit.isReadOnly = 1;
    },

    //*---- 上傳檔案
    changeFile(e) {
      var files = e.target.files;

      if (files.length + this.edit.files.length > 5) {
        this.$eventBus.$emit(
          "showAlert",
          "您檔案數量超過上限5個，請重新選擇。"
        );
        this.removeFile();
        return;
      } else if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|xps|zip)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (fileSize > 20000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 20 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      //檔案上傳
      this.uploadFile(files[0], "Announcement", this.edit.id, true);
    },
    removeFile() {
      document.getElementById("inputFile").value = "";
    },

    //檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      var response_upload = await task_upload;
      this.edit.id = response_upload.data.result.id;
      this.refreshFileList();
    },

    //檔案列表刷新
    async refreshFileList() {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.edit.id,
        module: "Announcement"
      });
      var response_queryall = await task_queryall;
      this.edit.files = response_queryall.data.result;
    },

    //刪除檔案
    deleteFile(applyId) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", isOK => {
        if (isOK) {
          api.file.deleteApply({ id: applyId }).then(res => {
            if (res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "刪除成功!");
            } else {
              this.$eventBus.$emit("showAlert", "刪除失敗!");
            }
            this.refreshFileList();
          });
        }
      });
    },

    async Confirm(status) {
      if (this.edit.mode == 2)
        this.edit.publishTime =
          $(".datepicker").val() + " " + $(".timepicker").val();

      this.edit.email = this.sendSelected.join(";");

      if (this.userData.companyId == "J") {
        this.edit.isReadOnly = 1;
        this.edit.target = "J";
      }

      // this.edit.contents = this.$refs.CKEditor.SetData();

      if (!this.checkAll()) return;

      //status 1.草稿 2.儲存 3.儲存兼發信
      if (status != 0) this.edit.status = status;

      if (this.edit.status == 0) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return;
      }

      if (this.editType) {
        api.announcement.update(this.edit).then(res => {
          if (res.data.status == "1")
            this.$router.push({
              name: "AnnouncementList",
              params: { id: this.type }
            });
          else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        });
      } else if (!this.editType) {
        api.announcement.create(this.edit).then(res => {
          if (res.data.status == "1")
            this.$router.push({
              name: "AnnouncementList",
              params: { id: this.type }
            });
          else {
            this.$eventBus.$emit("showAlert", res.data.message);
          }
        });
      }
      return;
    },
    checkAll() {
      if (this.edit.title == "") {
        this.$eventBus.$emit("showAlert", "主旨不能為空");
        return false;
      }
      if (this.edit.docNo == "") {
        this.$eventBus.$emit("showAlert", "公告文號不能為空");
        return false;
      }
      if (this.edit.issuedUnit == "") {
        this.$eventBus.$emit("showAlert", "請選擇公告單位");
        return false;
      }
      if (this.userData.companyId == 'A' && this.edit.target == "") {
        this.$eventBus.$emit("showAlert", "請選擇公告對象");
        return false;
      }
      if (this.edit.contactName == "") {
        this.$eventBus.$emit("showAlert", "請選擇公告連絡人");
        return false;
      }
      if (this.edit.contents == "") {
        this.$eventBus.$emit("showAlert", "內容不能為空");
        return false;
      }
      if (this.edit.mode == 2 && this.edit.publishTime == "") {
        this.$eventBus.$emit("showAlert", "請選擇排程時間");
        return false;
      }

      if (this.edit.email == "") {
        this.$eventBus.$emit("showAlert", "發送群組不可為空!!");
        return false;
      }

      return true;
    }
  }
};
</script>
