<template>
  <div id="CourseRecordPersonal">
    <section class="content-header">
      <h1>上課紀錄(人員)</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">上課紀錄(人員)</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-warning btn-add"
              type="button"
              @click="add()"
            >
              <i class="fa fa-plus"></i> 新增部門必修
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="selectCompany"
                required="required"
                v-model="searchData.companyId"
                @change="getDataList()"
              >
                <option
                  :value="company.companyId"
                  v-for="(company, index) in companyList"
                  :key="index"
                >
                  {{ company.companyName }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectDept"
                required="required"
                v-model="deptArray"
              >
                <option
                  :value="{
                    companyId: dept.companyId,
                    deptId: dept.deptId,
                    deptIdName: dept.deptIdName || dept.deptName,
                  }"
                  v-for="(dept, index) in deptList"
                  :key="index"
                >
                  {{ dept.deptIdName || dept.deptName }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectFinished"
                required="required"
                v-model="searchData.isFinished"
                @change="getDataList()"
              >
                <option value="">全部狀態</option>
                <option value="0">未完成</option>
                <option value="1">已完成</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                placeholder="搜尋姓名"
                type="text"
                v-model="searchData.empName"
              />
              <div class="input-group-btn">
                <button
                  @click="getDataList()"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 15%">
                    部門
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    員工編號
                  </th>
                  <th class="text-nowrap text-center" style="width: 15%">
                    姓名
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    未完成必修課程數
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    功能
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="5">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="5">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">{{ data.deptName }}</td>
                    <td class="text-center">{{ data.employeeId }}</td>
                    <td class="text-center">{{ data.employeeName }}</td>
                    <td class="text-center">
                      {{ data.notFinishedChapterCount }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-warning" @click="goDetail(data)">
                        詳細
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        companyId: this.$route.params.companyId || "A",
        deptId: this.$route.params.id || "",
        isFinished: "",
      },
      userData: {},
      companyList: [],
      deptList: [],
      list: [],
      deptArray: {},
    };
  },
  async created() {
    this.userData = this.$user.getUserData();

    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }

    this.getCompanyList();
    this.getDeptList();
  },
  watch: {
    deptArray(val) {
      this.searchData.companyId = val.companyId;
      this.searchData.deptId = val.deptId;
      this.searchData.deptIdName = val.deptIdName.trim();
      this.getDataList();
    },
    deptList: {
      handler: function (val) {},
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getCompanyList() {
      api.education.company().then(({ data }) => {
        if (this.userData.user == "134") {
          // 身份為法務
          this.companyList = data.result;
        } else {
          const CompanyId = this.userData.user.companyId;
          const Index = data.result.findIndex(
            (item) => item.companyId == CompanyId
          );
          this.companyList.push(data.result[Index]);
        }
      });
    },
    getDeptList() {
      if (this.userData.user.deptId == "134") {
        api.education
          .deptList({
            deptType: "", // 部門層級
            companyId: this.searchData.companyId, // 公司代號
          })
          .then(({ data }) => {
            this.deptList = data.result || [];
            this.setDedaultDeptId();
          });
      } else {
        let divisionList = [];
        this.userData.deptEmp.forEach((item) => {
          if (item.deptLevel == "0050") divisionList.push(item);
          else this.deptList.push(item);
        });
        if (divisionList.length > 0) {
          divisionList.forEach((item) => {
            if (item.deptLevel == "0050") {
              api.education
                .deptList({
                  deptType: "", // 部門層級
                  companyId: this.searchData.companyId, // 公司代號
                  ParentDeptId: item.deptId,
                })
                .then(({ data }) => {
                  let deptList = data.result || [];
                  if (deptList.length > 0) {
                    deptList.forEach((dept) => {
                      const Index = this.deptList.findIndex(
                        (data) => data.deptId == dept.deptId
                      );
                      dept.deptIdName = dept.deptIdName.split(" ")[1];
                      if (Index == -1) this.deptList.push(dept);
                    });
                  }
                  this.setDedaultDeptId();
                });
            }
          });
        } else {
          this.setDedaultDeptId();
        }
      }
    },
    setDedaultDeptId() {
      if (this.deptList[0]) {
        let datas = {
          companyId: this.deptList[0].companyId,
          deptId: this.deptList[0].deptId,
          deptIdName: this.deptList[0].deptIdName || this.deptList[0].deptName,
        };
        this.deptArray = datas;
      }
    },
    getDataList() {
      this.loading = true;
      api.education.record.all.personal(this.searchData).then((res) => {
        this.list = res.data.result || [];
        this.loading = false;
      });
    },
    add() {
      const Index = this.deptList.findIndex(
        (data) => data.deptId == this.searchData.deptId
      );
      this.$router.push({
        name: "ETCourseRecordEdit",
        params: {
          cId: this.searchData.companyId,
          depId: this.searchData.deptId,
          searchData: this.$route.params.searchData,
        },
      });
    },
    goDetail(item) {
      const Index = this.deptList.findIndex(
        (data) => data.deptId == this.searchData.deptId
      );
      this.$router.push({
        name: "ETCourseRecordDetail",
        params: {
          cid: this.searchData.companyId,
          depId: this.deptList[Index].deptId,
          id: item.employeeId,
          searchData: this.searchData,
        },
      });
    },
    back() {
      this.$router.push({
        name: "ETStudentCourseList",
        params: {
          type: this.$route.params.type,
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
