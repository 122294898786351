<template>
  <div class="pdf-document">
    <div class="loading" v-show="loading">Loading ...</div>
    <!-- <p>頁碼：{{ `${pageNo}/${totals.length}` }}</p> -->
    <div class="drag-box" id="dragBox" v-if="pdfUrl">
      <div class="scroll" @scroll="scrollfun($event)">
        <div class="wrapper" id="pdf-container">
          <div
            v-for="item in totals"
            :id="`page-${item}`"
            :key="item"
            class="pdf-box"
          >
            <canvas :id="'canvas-pdf-' + item" class="canvas-pdf"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<style scoped lang="scss">
.pdf-document {
  position: relative;
  .loading {
    width: 102%;
    height: 102%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -1%;
    top: -1%;
    z-index: 10;
    background-color: #fff;
    font-size: 20px;
    white-space: nowrap;
    opacity: 0.8;
  }
  p {
    margin: 0 0 10px 0;
    text-align: center;
  }
  .drag-box {
    width: 100%;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
  }
  .wrapper {
    height: fit-content;
  }
  .canvas-pdf {
    width: 100%;
  }
  .pdf-box {
    position: relative;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .scroll {
    height: 600px;
    overflow: auto;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "showPdf",
  props: ["pdfUrl"],
  data() {
    return {
      loading: true,
      scale: 3,
      totals: [],
      pageNo: 1,
      viewHeight: 0,
      disabled: "",
      pdf_doc: "",
    };
  },
  watch: {
    async pdfUrl(val) {
      await this.resetPDF();
      if (val) {
        this.$nextTick(() => {
          this.renderPdf(this.scale);
        });
      }
    },
  },
  mounted() {
    this.showLoading();
  },
  methods: {
    resetPDF() {
      this.totals = [];
      this.pageNo = 1;
      this.viewHeight = 0;
      this.disabled = "";
    },
    async downloadAndConvertToUint8Array(pdfUrl) {
      // 使用 axios 发送 GET 请求获取 PDF 文件数据
      const response = await axios({
        url: pdfUrl,
        method: "GET",
        responseType: "arraybuffer", // 设置响应类型为 arraybuffer
      });

      // 将 arraybuffer 转换为 Uint8Array
      const uint8Array = new Uint8Array(response.data);
      return uint8Array;
    },
    renderPdf(scale) {
      this.loading = true;

      this.downloadAndConvertToUint8Array(this.pdfUrl)
        .then((uint8Array) => {
          // 现在你可以用这个 Uint8Array 对象进行进一步处理，例如传递给PDF.js库渲染PDF
          // 当 PDF 地址为跨域时，pdf 应该已流的形式传输，否则会出现pdf损坏无法展示
          pdfjsLib.getDocument(uint8Array).promise.then((pdf) => {
            if (this.pdf_doc) this.pdf_doc.destroy();
            this.pdf_doc = pdf;

            // 得到PDF的总页数
            let totalPage = pdf.numPages;
            let idName = "canvas-pdf-";
            // 创建与页数相同数量的 canvas
            this.createCanvas(totalPage, idName);

            for (let i = 1; i <= totalPage; i++) {
              pdf.getPage(i).then((page) => {
                let pageDiv = document.getElementById(`page-${i}`);
                let viewport = page.getViewport({ scale });
                let canvas = document.getElementById(idName + i);
                let context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                this.viewHeight = viewport.height;

                // 创建渲染上下文
                let renderContext = { canvasContext: context, viewport };

                // 渲染 PDF 页到 canvas
                page.render(renderContext);

                // 获取文本内容
                page.getTextContent().then((textContent) => {
                  // 创建文本图层 div
                  const textLayerDiv = document.createElement("div");
                  textLayerDiv.setAttribute("class", "textLayer");
                  // 添加文本图层 div 到每页 PDF 的 div 中
                  pageDiv.appendChild(textLayerDiv);

                  // // 创建新的 TextLayerBuilder 实例
                  // let textLayer = new TextLayerBuilder({
                  //   textLayerDiv: textLayerDiv,
                  //   pageIndex: page.pageIndex,
                  //   viewport: viewport,
                  // });
                  // textLayer.setTextContent(textContent);
                  // textLayer.render();

                  this.hideLoading();
                  setTimeout(() => {
                    $("#dragBox .scroll").scrollTop = 0;
                    this.loading = false;
                  }, 300);
                });
              });
            }
          });
        })
        .catch((error) => {
          console.error("Error downloading or converting the PDF:", error);
          this.loading = false;
        });
    },
    createCanvas(totalPages) {
      this.totals = [];
      for (let i = 1; i <= totalPages; i++) {
        this.totals.push(i);
      }
    },

    // 分页
    scrollfun(e) {
      let scrollTop = e.target.scrollTop;
      if (scrollTop === 0) {
        this.pageNo = 1;
      } else {
        this.pageNo = Math.ceil(scrollTop / this.viewHeight);

        let clientHeight = e.target.clientHeight + 5;
        let scrollHeight = e.target.scrollHeight;
        if (scrollTop + clientHeight >= scrollHeight) {
          this.$emit("isPageBottom", true);
        } else {
          this.$emit("isPageBottom", false);
        }
      }
    },
  },
};
</script> 