var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ProductsManager" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("公司產品管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/Products" } }, [
                _vm._v("公司產品"),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("公司產品管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content products" }, [
        _c("div", { staticClass: "box box-warning" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v(" 返回\n          "),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-add",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openProductEdit("")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增公司產品\n          "),
                ]
              ),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c("table", { staticClass: "table table-bordered" }, [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm._l(_vm.products.datas, function (product, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(product.productName))]),
                        _c("td", [_vm._v(_vm._s(product.productInfo))]),
                        _c("td", [_vm._v(_vm._s(product.productUrl))]),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _vm._l(
                              product.androidFiles,
                              function (file, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "pic" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt: file.name,
                                        draggable: "false",
                                        src: _vm.imgPath + file.filesUrl,
                                        width: "100",
                                      },
                                    }),
                                  ]
                                )
                              }
                            ),
                            _c("P", [_vm._v(_vm._s(product.androidUrl))]),
                          ],
                          2
                        ),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _vm._l(product.iosFiles, function (file, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "pic" },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: file.name,
                                      draggable: "false",
                                      src: _vm.imgPath + file.filesUrl,
                                      width: "100",
                                    },
                                  }),
                                ]
                              )
                            }),
                            _c("P", [_vm._v(_vm._s(product.iosUrl))]),
                          ],
                          2
                        ),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(product.orderNum)),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: product.status,
                                  expression: "product.status",
                                },
                              ],
                              attrs: {
                                "false-value": 0,
                                id: "check" + product.productId,
                                "true-value": 1,
                                name: "ckbx-style-8",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(product.status)
                                  ? _vm._i(product.status, null) > -1
                                  : _vm._q(product.status, 1),
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = product.status,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? 1 : 0
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            product,
                                            "status",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            product,
                                            "status",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(product, "status", $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.changeStatus(product)
                                  },
                                ],
                              },
                            }),
                            _c("label", {
                              attrs: { for: "check" + product.productId },
                            }),
                          ]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function ($event) {
                                  return _vm.openProductEdit(product)
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                          _vm._v("\n                   \n                  "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteProduct(product)
                                },
                              },
                            },
                            [_vm._v("刪除")]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("productEdit", {
        ref: "productEdit",
        on: { refreshList: _vm.getProductsList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v("名稱")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v("簡述")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v("連結")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v("Android")]
      ),
      _c(
        "th",
        { staticStyle: { "min-width": "150px" }, attrs: { width: "15%" } },
        [_vm._v("iOS")]
      ),
      _c(
        "th",
        {
          staticClass: "text-center",
          staticStyle: { "min-width": "80px" },
          attrs: { width: "10%" },
        },
        [_vm._v("排序")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "80px" },
          attrs: { width: "10%" },
        },
        [_vm._v("啟用狀態")]
      ),
      _c(
        "th",
        {
          staticClass: "text-nowrap text-center",
          staticStyle: { "min-width": "150px" },
          attrs: { width: "15%" },
        },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }