<template>
  <div id="OrderManagement">
    <section class="content-header">
      <h1>訂餐月報表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務管理</li>
        <li class="active">訂餐月報表</li>
      </ol>
    </section>
    <section class="content order-management">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <form class="form-horizontal">
              <div class="form-group">
                <label class="control-label">起訖時間：</label>
                <div class="input-group date" style="width: 230px">
                  <div class="input-group-addon">
                    <i class="fa fa-calendar"></i>
                  </div>
                  <div class="pull-right">
                    <DateRangePicker
                      v-bind:propEndDate.sync="searchData.endDay"
                      v-bind:propStartDate.sync="searchData.startDay"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label">用餐類別：</label>
                <div class="right">
                  <div class="radio-inline">
                    <iCheckRedioButton
                      checkValue="1"
                      message="午餐"
                      name="radioMode"
                      v-model="searchData.eatTime"
                    />
                  </div>
                  <div class="radio-inline">
                    <iCheckRedioButton
                      checkValue="2"
                      message="晚餐"
                      name="radioMode"
                      v-model="searchData.eatTime"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="getDeptList()"
                >
                  <option value="">全部部門</option>
                  <option
                    v-for="(item, index) in deptList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
              <div class="mt-5">
                <div class="form-group">
                  <label class="control-label" for="inputOrderer"
                    >訂購人：</label
                  >
                  <input
                    class="form-control right"
                    id="inputOrderer"
                    type="text"
                    v-model="searchData.employeeName"
                  />
                </div>
                <div class="btns">
                  <button
                    @click="onSearch()"
                    class="btn btn-primary"
                    type="button"
                  >
                    查詢
                  </button>
                  <button
                    @click="exportExcel()"
                    class="btn btn-default"
                    type="button"
                  >
                    匯出
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min-600">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap">部門</th>
                  <th class="text-nowrap">工號</th>
                  <th class="text-nowrap">姓名</th>
                  <th class="text-nowrap">訂餐次數</th>
                  <th class="text-nowrap">餐費總金額</th>
                  <th class="text-nowrap">有訂餐且有打卡次數</th>
                </tr>
                <tr v-if="loading">
                  <td colspan="6" class="text-center">查詢中</td>
                </tr>
                <tr v-else-if="total == 0">
                  <td colspan="6" class="text-center">查詢無任何資料</td>
                </tr>
                <tr v-for="(item, index) in list" :key="index" v-else>
                  <td>{{ item.deptName }}</td>
                  <td>{{ item.employeeId }}</td>
                  <td>{{ item.employeeName }}</td>
                  <td>{{ item.eatCount }}</td>
                  <td>{{ item.totalPrice }}</td>
                  <td>{{ item.punchCount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker";
import Pagination from "@/components/Pagination";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import moment from "moment";
import utils from "@/assets/js/app-utils";

export default {
  components: {
    Pagination,
    DateRangePicker,
    iCheckRedioButton,
  },
  data() {
    return {
      searchData: {
        pageNum: 1,
        pageSize: 20,
        deptId: "", // 上月26-當月的25
        startDay: new moment()
          .subtract(1, "months")
          .startOf("month")
          .date(26)
          .format("YYYY/MM/DD"),
        endDay: new moment().endOf("month").date(25).format("YYYY/MM/DD"),
        eatTime: 1, //1:午餐 2:晚餐
        employeeName: "",
      },
      total: 0,
      loading: true,
      deptList: [],
      list: [],
    };
  },
  created() {
    this.getDeptList();
    this.getDataList();
  },
  methods: {
    getDeptList() {
      api.organization
        .getDeptList({
          companyId: this.$store.state.loginUser.companyId,
        })
        .then((res) => {
          if (res.data.status === "1") {
            this.deptList = res.data.result || [];
          }
        });
    },
    getDataList() {
      this.loading = true;
      api.mealManage.queryEatTotalPrice(this.searchData).then((res) => {
        if (res.data.status == "1") {
          this.list = res.data.result.datas;
          this.total = res.data.result.total;
          if (this.$refs.pagination) {
            this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
          }
        }
        this.loading = false;
        this.$forceUpdate();
      });
    },

    onSearch() {
      this.page = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    async exportExcel() {
      const taskDatas = JSON.parse(JSON.stringify(this.searchData));
      taskDatas.pageNum = 0;
      taskDatas.pageSize = 0;
      let task_QueryAll = api.mealManage.queryEatTotalPrice(taskDatas);
      let response = await task_QueryAll;
      if (response.data.status != "1" || response.data.result.total == 0) {
        this.$eventBus.$emit("showAlert", "查詢資料失敗，請重新操作!");
        return;
      }

      let data = [];
      response.data.result.datas.forEach((res) => {
        data.push({
          部門: res.deptName,
          工號: res.employeeId,
          姓名: res.employeeName,
          訂餐次數: res.eatCount,
          餐費總金額: res.totalPrice,
          有訂餐且有打卡次數: res.punchCount,
        });
      });

      let startDay = taskDatas.startDay.replace(/\//g, "");
      let endDay = taskDatas.endDay.replace(/\//g, "");
      utils.exportData(data, startDay + "~" + endDay + "_訂餐月報表.xlsx");
    },
  },
};
</script>
