var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("課程紀錄")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", [_vm._v("課程管理")]),
        _c("li", { staticClass: "active" }, [_vm._v("課程紀錄")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left" }, [
              _vm._v(_vm._s(_vm.$route.params.name)),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-success btn-add mr-10",
                staticStyle: { "min-width": "auto" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.exportList()
                  },
                },
              },
              [_vm._v("\n            匯出\n          ")]
            ),
          ]),
          _c("div", { staticClass: "table-responsive" }, [
            _c("p", [
              _vm._v("時數：" + _vm._s(_vm.course.courseTotalTime || 0)),
            ]),
            _vm.course.hasTest
              ? _c("p", [
                  _vm._v(
                    "\n            測驗及格分數：" +
                      _vm._s(_vm.course.passingScore || 0) +
                      "分\n          "
                  ),
                ])
              : _vm._e(),
            _vm.course.teachers
              ? _c("p", [_vm._v("講師：" + _vm._s(_vm.course.teachers))])
              : _vm._e(),
            _c("div", { staticClass: "select-flex" }, [
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isRequire,
                        expression: "searchData.isRequire",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectCompany", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "isRequire",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "1" } }, [
                      _vm._v("必修課程"),
                    ]),
                    _c("option", { attrs: { value: "0" } }, [
                      _vm._v("選修課程"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.empCompanyId,
                        expression: "searchData.empCompanyId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectCompany", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "empCompanyId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.companyList, function (company, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: company.companyId } },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(company.companyName) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.deptId,
                        expression: "searchData.deptId",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectDept", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "deptId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.deptList, function (dept, index) {
                    return _c(
                      "option",
                      { key: index, domProps: { value: dept.deptId } },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(dept.deptIdName || dept.deptName) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "select-group" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchData.isFinished,
                        expression: "searchData.isFinished",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "selectFinished", required: "required" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.searchData,
                            "isFinished",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function ($event) {
                          return _vm.getDataList()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("全部狀態"),
                    ]),
                    _c("option", { attrs: { value: "0" } }, [_vm._v("未完成")]),
                    _c("option", { attrs: { value: "1" } }, [_vm._v("已完成")]),
                  ]
                ),
              ]),
            ]),
            _c(
              "table",
              {
                staticClass:
                  "table table-hover text-center table-bordered min-800 mt-10",
              },
              [
                _c("thead", [
                  _c("tr", { staticClass: "bg-gray" }, [
                    _c("th", { staticClass: "text-nowrap" }, [_vm._v("部門")]),
                    _c("th", { staticClass: "text-nowrap" }, [_vm._v("姓名")]),
                    _c("th", { staticClass: "text-nowrap" }, [_vm._v("職位")]),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { "min-width": "100px" },
                      },
                      [_vm._v("簽到時間")]
                    ),
                    _c("th", { staticClass: "text-nowrap" }, [
                      _vm._v("簽退時間"),
                    ]),
                    _vm.course.hasTest
                      ? _c("th", { staticClass: "text-nowrap" }, [
                          _vm._v("測驗分數"),
                        ])
                      : _vm._e(),
                    _c("th", { staticClass: "text-nowrap" }, [
                      _vm._v("完成狀態"),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(0)]
                      : _vm.course.learnRecordList.length == 0
                      ? [_vm._m(1)]
                      : _vm._l(
                          _vm.course.learnRecordList,
                          function (data, index) {
                            return _c("tr", { key: index }, [
                              _c("td", [_vm._v(_vm._s(data.deptName))]),
                              _c("td", [_vm._v(_vm._s(data.employeeName))]),
                              _c("td", [_vm._v(_vm._s(data.empTitleName))]),
                              _c("td", [
                                data.startTime
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            data.startTime,
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" - ")]),
                              ]),
                              _c("td", [
                                data.endTime
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("dateFormat")(
                                            data.endTime,
                                            "YYYY-MM-DD HH:mm"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" - ")]),
                              ]),
                              _vm.course.hasTest
                                ? _c("td", [
                                    data.score
                                      ? _c("span", [
                                          _vm._v(_vm._s(data.score) + "分"),
                                        ])
                                      : _c("span", [_vm._v("-")]),
                                  ])
                                : _vm._e(),
                              _c("td", [
                                _vm._v(
                                  _vm._s(data.isFinished ? "已完成" : "未完成")
                                ),
                              ]),
                            ])
                          }
                        ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("td", { attrs: { colspan: "6" } }, [_vm._v("查詢中")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "6" } }, [_vm._v("查無資料")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }