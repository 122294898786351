var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "quiz" } }, [
    _c("div", { staticClass: "box-body" }, [
      _c("div", { staticClass: "top-box" }, [
        _c("div", { staticClass: "total-score" }, [
          _vm._v(
            "\n        得分：" +
              _vm._s(_vm.myScore) +
              " / " +
              _vm._s(_vm.totalScore) +
              " （最高紀錄：" +
              _vm._s(_vm.personalResultScore) +
              "\n        分）\n      "
          ),
        ]),
        _vm.quizDatas
          ? _c("span", { staticClass: "note text-red" }, [
              _vm._v(
                "*本測驗及格分數：" +
                  _vm._s(Number(_vm.quizDatas.passingScore)) +
                  "（" +
                  _vm._s(_vm.isFinished ? "通過" : "尚未通過") +
                  "）"
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm.quizDatas
      ? _c(
          "div",
          { staticClass: "box-body" },
          [
            _vm._l(_vm.questions, function (quiz, index) {
              return _c("div", { key: index, staticClass: "form-group" }, [
                _c("div", { staticClass: "question" }, [
                  _c(
                    "div",
                    {
                      staticClass: "title col-sm-11",
                      class: {
                        "text-green": _vm.isSubmit && !quiz.isWrong,
                        "text-red":
                          (_vm.isSubmit && quiz.isWrong) || quiz.isAnswerYet,
                      },
                    },
                    [
                      _vm.isSubmit && !quiz.isWrong
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _vm._e(),
                      _vm.isSubmit && quiz.isWrong
                        ? _c("i", { staticClass: "fa fa-times" })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(index + 1) +
                          ". " +
                          _vm._s(quiz.questionText) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "score text-gray col-sm-1" }, [
                    _vm._v(_vm._s(quiz.questionScore) + "分"),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "options col-sm-12",
                    class: { mb0: _vm.isSubmit && quiz.isWrong },
                  },
                  _vm._l(quiz.options, function (option, oIndex) {
                    return _c(
                      "div",
                      { key: oIndex, staticClass: "radio-block" },
                      [
                        _vm.isPreview
                          ? _c("label", { staticClass: "custom-radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: quiz.correctAnswer,
                                    expression: "quiz.correctAnswer",
                                  },
                                ],
                                attrs: {
                                  name: `question${index}`,
                                  type: "radio",
                                  disabled: true,
                                },
                                domProps: {
                                  value: oIndex + 1,
                                  checked: _vm._q(
                                    quiz.correctAnswer,
                                    oIndex + 1
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$set(
                                      quiz,
                                      "correctAnswer",
                                      oIndex + 1
                                    )
                                  },
                                },
                              }),
                              _c("i", { staticClass: "icon" }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.addFromCharCode(
                                      oIndex + 1,
                                      option.optionText
                                    )
                                  )
                                ),
                              ]),
                            ])
                          : _c("label", { staticClass: "custom-radio" }, [
                              _c("input", {
                                ref: "iCheckRedioButton",
                                refInFor: true,
                                attrs: {
                                  name: `question${index}`,
                                  type: "radio",
                                  disabled: _vm.isSubmit,
                                },
                                domProps: { value: oIndex + 1 },
                                on: {
                                  input: function ($event) {
                                    return _vm.myAnswer(
                                      option.questionId,
                                      oIndex + 1
                                    )
                                  },
                                },
                              }),
                              _c("i", { staticClass: "icon" }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.addFromCharCode(
                                      oIndex + 1,
                                      option.optionText
                                    )
                                  )
                                ),
                              ]),
                            ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm.isSubmit && quiz.isWrong
                  ? _c("div", { staticClass: "answer col-sm-12 text-green" }, [
                      _vm._v(
                        "\n        正確答案：" +
                          _vm._s(_vm.showCorrectAnswer(quiz)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            _c("div", { staticClass: "col-sm-12" }, [
              !_vm.isPreview
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          _vm.isSubmit ? _vm.resetExam() : _vm.submitExam()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.isSubmit ? "重新測驗" : "送出") +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isAnswerYet
                ? _c("p", { staticClass: "text-red" }, [
                    _vm._v("* 尚有題目未完成"),
                  ])
                : _vm._e(),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }