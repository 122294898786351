<template>
  <div id="OrderNextWeekMeal">
    <section class="content-header">
      <h1>預訂{{ expatId === "J" ? "" : "下週" }}午餐</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>訂餐服務</li>
        <li class="active">預訂{{ expatId === "J" ? "" : "下週" }}午餐</li>
      </ol>
    </section>
    <section class="content order-meal">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box two-btn">
            <!-- <h4 class="pull-left"></h4> -->
            <button
              class="btn btn-block btn-primary btn-cog"
              type="button"
              v-if="expatId !== 'J'"
              @click="goBack()"
            >
              <i class="fa fa-list"></i> 訂餐歷程
            </button>
            <button
              class="btn btn-block btn-default btn-cog"
              type="button"
              @click="openEmployeeHabits()"
            >
              <i class="fa fa-gear"></i> 個人飲食習慣
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered min-600">
              <tbody>
                <tr class="bg-gray">
                  <th>用餐日期</th>
                  <th class="text-center">星期</th>
                  <th>店家名稱</th>
                  <th>狀態</th>
                  <th>備註</th>
                  <th class="text-center">瀏覽菜單</th>
                  <th class="text-center">取消/保留餐點</th>
                </tr>
                <tr :key="item.OrderId" v-for="item in orderList">
                  <td>{{ formatDateTime(item.eatDay, "YYYY/MM/DD") }}</td>
                  <td class="text-center">
                    {{ formatDateTime(item.eatDay, "dd") }}
                  </td>
                  <td>
                    <ul class="store-name list-unstyled">
                      <li :key="store.storeId" v-for="store in item.stores">
                        {{ store.storeName }}
                      </li>
                    </ul>
                  </td>
                  <td :class="{ 'text-red': getOrderStatus(item) == '未訂餐' }">
                    {{ getOrderStatus(item) }}
                  </td>
                  <td>
                    {{
                      item.manageUpdate == 3
                        ? "保留"
                        : item.manageUpdate == 4
                        ? "取消"
                        : ""
                    }}
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-success"
                      title="瀏覽菜單"
                      @click="goOrderMenu(item.eatDay)"
                    >
                      <i class="fa fa-book"></i>
                    </button>
                  </td>
                  <td class="text-center">
                    <template
                      v-if="
                        getOrderStatus(item) != '不訂餐' &&
                        openUserKeep == 1 &&
                        checkMealBtnsShow(item.eatDay)
                      "
                    >
                      <button
                        @click="updateMealStatus(item, 'cancel')"
                        class="btn btn-danger"
                        style="margin-right: 10px"
                        v-if="item.manageUpdate != 4"
                      >
                        取消
                      </button>
                      <button
                        @click="updateMealStatus(item, 'keep')"
                        class="btn btn-primary"
                        v-if="item.manageUpdate != 3"
                      >
                        保留
                      </button>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="text-red mt-10">
            <i class="fa fa-warning"></i>
            訂餐截止時間：{{ orderDeadline }}。
          </p>
        </div>
      </div>
    </section>
    <employeeHabits ref="employeeHabits" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";
import employeeHabits from "@/views/OrderSystem/EmployeeHabits";

export default {
  components: {
    Pagination,
    employeeHabits,
  },
  data() {
    return {
      expatId: this.$route.params.expatId || this.$user.getUserExpatId(),
      orderList: {},
      total: 0, //總筆數
      pageSize: 10, //每頁顯示筆數
      page: 1,
      orderDeadline: "",
      employeeId: this.$store.state.loginUser.employeeId,
      empOrderList: [],
      orderStartTime: "",
      openUserKeep: 0,
      checkDeadlineType: "",
    };
  },
  async created() {
    await this.getEmpNextWeekOrderList(); //優先查詢
    this.getDeadline();
  },
  methods: {
    async getNextWeekOrderList() {
      if (new moment().isBefore(this.orderStartTime)) return;

      api.empOrder
        .orderWeek({
          companyId: this.expatId,
          eatStartDay: new moment()
            .add(1, "weeks")
            .startOf("isoWeek")
            .format("YYYY/MM/DD"),
          eatEndDay: new moment()
            .add(4, "weeks")
            .endOf("isoWeek")
            .format("YYYY/MM/DD"),
          eatTime: 1,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.orderList = res.data.result;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getEmpNextWeekOrderList() {
      api.empOrder
        .orderWeek({
          companyId: this.expatId,
          employeeId: this.employeeId,
          eatStartDay: new moment()
            .add(1, "weeks")
            .startOf("isoWeek")
            .format("YYYY/MM/DD"),
          eatEndDay: new moment()
            .add(4, "weeks")
            .endOf("isoWeek")
            .format("YYYY/MM/DD"),
          eatTime: 1,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.empOrderList = res.data.result;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getDeadline() {
      api.mealTimeSet
        .queryLunch({
          companyId: this.$route.params.expatId,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.orderDeadline = new moment()
              .startOf("isoWeek")
              .add(res.data.result.weekEnd - 1, "Days")
              .format("YYYY/MM/DD " + res.data.result.hourEnd + ":00:00");
            this.orderStartTime = new moment()
              .startOf("isoWeek")
              .add(res.data.result.weekStart - 1, "Days")
              .format("YYYY/MM/DD " + res.data.result.hourStart + ":00:00");
            this.getNextWeekOrderList();

            // 10點之前可調本日
            // 10點之後 & weekEnd+hourEnd 之後才可調整下週的部份
            this.openUserKeep = res.data.result.openUserKeep;
            let nowTime = new moment().format("HHmm");
            if (Number(nowTime) < 1000) {
              // 可調本日
              this.checkDeadlineType = "today";
            } else {
              let isOverDeadline = new moment().isAfter(
                new moment(this.orderDeadline)
              );
              if (isOverDeadline) {
                // 可調下周
                this.checkDeadlineType = "others";
              }
            }
          }
        });
    },
    checkMealBtnsShow(eatDay) {
      let nowDate = new moment().format("YYYY-MM-DD");
      let checkDate = new moment(eatDay).format("YYYY-MM-DD");
      let isToday = checkDate == nowDate;
      if (
        (isToday && this.checkDeadlineType == "today") ||
        (!isToday && this.checkDeadlineType == "others")
      ) {
        return true;
      } else {
        return false;
      }
    },
    getOrderStatus(eat) {
      let query = this.empOrderList.filter((emp) => {
        return emp.eatDay == eat.eatDay;
      });

      if (query.length > 0) {
        switch (query[0].eatStatus) {
          case 0:
            return "不訂餐";
          case 1:
            return "已訂餐";
          default:
            return "未訂餐";
        }
      }

      return "未訂餐";
    },
    async goOrderMenu(day) {
      let data =
        this.$route.params.expatId == "J"
          ? {
              eatDay: day,
              companyId: "J",
            }
          : {
              eatDay: day,
            };

      var task_check = api.mealTimeSet.checkLunchTime(data);
      var response = await task_check;
      if (
        response.data.status != "1" ||
        response.data.result.checkStatus != "1"
      ) {
        this.$eventBus.$emit("showAlert", "已超過訂餐時間!!");
        return;
      }

      let date = this.formatDateTime(day, "YYYY/MM/DD");
      this.$router.push({
        name: "OrderMenu",
        params: { date: date, state: 1, expatId: this.expatId },
      });
      return;
    },
    openEmployeeHabits() {
      this.$refs.employeeHabits.showHabitsEditBox();
    },
    goBack() {
      this.$router.push({
        name: "Order",
        params: { expatId: this.expatId },
      });
      return;
    },
    formatDateTime(datetime, format) {
      return utils.formatTime(datetime, format, "zh-tw");
    },

    updateMealStatus(item, type) {
      let confirmMsg = "";
      let msg = "";
      let status = 0;
      if (type == "cancel") {
        msg = "取消";
        confirmMsg = "確定要取消餐點嗎？";
        status = 4;
      }
      if (type == "keep") {
        msg = "保留";
        status = 3;
      }

      if (confirmMsg) {
        this.$eventBus.$emit("showConfirm", confirmMsg, (isOK) => {
          if (isOK) {
            this.updateMealStatusApi(item, status, msg);
          }
        });
      } else {
        this.updateMealStatusApi(item, status, msg);
      }
    },
    updateMealStatusApi(item, status, msg) {
      let eatDay = new moment(item.eatDay).format("YYYY/MM/DD");
      let eatID = item.eatId;
      api.empOrder
        .updateEatStatus({
          EatDay: eatDay,
          EatId: eatID, //如果有的話，一定要傳
          ManageUpdate: status, //員工手動 3:保留 4:取消
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.$eventBus.$emit("showAlert", msg + "成功！");
            this.getDeadline();
          } else {
            this.$eventBus.$emit("showAlert", msg + "失敗！");
          }
        });
    },
  },
};
</script>
