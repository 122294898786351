import moment from "moment";
import store from "@/store.js";
import xlsx from "xlsx";

const monentLocale = "zh-tw";

function getTimeId() {
  return new moment().format("YYYYMMDDHHmmssSSS");
}

function checkByUserPermission(permission) {
  let userPermissionList = store.state.loginUserPermission;
  let num = -1;

  let isCheck = false;
  permission.split(",").some((element) => {
    num = userPermissionList.indexOf(element);
    switch (num) {
      case -1:
        isCheck = false;
        break;
      default:
        isCheck = true;
        break;
    }
    if (isCheck) return true;
  });

  return isCheck;
}

export default {
  install(Vue, options) {
    if (this.isInstalled) return;
    this.isInstalled = true;
    Vue.prototype.$utils = this;
  },
  store,
  getTimeId: getTimeId,
  checkByUserPermission: checkByUserPermission,
  formatTime: function (datetime, format, locale) {
    if (format == undefined) format = "YYYY/MM/DD";
    if (locale == undefined) locale = monentLocale;
    return new moment(datetime).locale(locale).format(format);
  },
  getWeekDay: function (datetime, locale) {
    return this.formatTime(datetime, "dd", locale);
  },
  exportData(exportData, fileName, sheetName) {
    if (!exportData || exportData.length == 0) {
      this.$eventBus.$emit("showAlert", "無資料!!");
      return;
    }

    if (!sheetName) sheetName = "sheet1";
    if (!fileName) fileName = getTimeId() + ".xlsx";

    let worksheet = xlsx.utils.json_to_sheet(exportData);
    let new_workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(new_workbook, worksheet, sheetName);
    xlsx.writeFile(new_workbook, fileName);
  },
  exportMultipleSheetsData(obj, fileName) {
    if (obj.length > 0) {
      let new_workbook = xlsx.utils.book_new();
      obj.forEach((item, index) => {
        let sheetName = item.sheetName ? item.sheetName : "sheet" + index;
        let worksheet = xlsx.utils.json_to_sheet(item.exportData);
        xlsx.utils.book_append_sheet(new_workbook, worksheet, sheetName);
      });

      if (!fileName) fileName = getTimeId() + ".xlsx";
      xlsx.writeFile(new_workbook, fileName);
    } else {
      this.$eventBus.$emit("showAlert", "無資料!!");
    }
  },
  deepCloneObj(data) {
    return JSON.parse(JSON.stringify(data));
  },
};
