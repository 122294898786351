var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseEdit" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("課程管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", { staticClass: "active" }, [_vm._v("課程管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning company-files-edit" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box with-border" }, [
            _c("h4", { staticClass: "pull-left", attrs: { id: "title" } }, [
              _vm._v(
                "\n            課程" +
                  _vm._s(_vm.isEdit ? "編輯" : "新增") +
                  "\n          "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "box-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "box-body" }, [
              _c("div", { staticClass: "form-group" }, [
                _vm._m(0),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.courseName,
                        expression: "data.courseName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputTitle",
                      required: "required",
                      type: "text",
                    },
                    domProps: { value: _vm.data.courseName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.data, "courseName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(1),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("div", { staticClass: "radio-date" }, [
                    _c(
                      "div",
                      { staticClass: "radio" },
                      [
                        _c("iCheckRedioButton", {
                          attrs: {
                            checkValue: "年度課程",
                            message: "年度課程",
                            name: "radioMode",
                          },
                          model: {
                            value: _vm.data.courseType,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "courseType", $$v)
                            },
                            expression: "data.courseType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "input-group date" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.chapterType,
                              expression: "chapterType",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { id: "selectUnit", required: "required" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.chapterType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.chapterTypeList, function (item, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: item.value } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.name) +
                                  "\n                      "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "radio" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "常態課程",
                          message: "常態課程",
                          name: "radioMode",
                        },
                        model: {
                          value: _vm.data.courseType,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "courseType", $$v)
                          },
                          expression: "data.courseType",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(2),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectCourseType,
                          expression: "selectCourseType",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { "max-width": "220px" },
                      attrs: { name: "selectCourseType", required: "required" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectCourseType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { value: "" },
                          domProps: { selected: !_vm.selectCourseType },
                        },
                        [
                          _vm._v(
                            "\n                    請選擇\n                  "
                          ),
                        ]
                      ),
                      _vm._l(_vm.courseTypeList, function (item, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: item.className } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.className) +
                                "\n                  "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _c("p", { staticStyle: { color: "#999" } }, [
                    _vm._v("*如需新增課程分類請聯繫開發單位"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-3 control-label" }, [
                  _vm._v(" 必修部門： "),
                ]),
                _c(
                  "div",
                  { staticClass: "col-sm-8" },
                  [
                    _c(
                      "multiselect",
                      {
                        attrs: {
                          placeholder: "請選擇公司或部門",
                          label: "deptIdName",
                          "track-by": "deptIdName",
                          required: "",
                          options: _vm.deptList,
                          "hide-selected": true,
                          multiple: true,
                          taggable: true,
                          "close-on-select": false,
                          "clear-on-select": false,
                          "max-elements": "已達標籤上限",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (props) {
                              return [
                                _c("div", { staticClass: "option__desc" }, [
                                  _c("span", { staticClass: "option__title" }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(props.option.deptIdName) +
                                        "\n                      "
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "notice",
                            attrs: { slot: "noOptions" },
                            slot: "noOptions",
                          },
                          [_vm._v("列表為空")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "notice",
                            attrs: { slot: "noResult" },
                            slot: "noResult",
                          },
                          [_vm._v("查無結果")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "notice maxElements",
                            attrs: { slot: "maxElements" },
                            slot: "maxElements",
                          },
                          [
                            _vm._v(
                              "\n                    已達標籤上限\n                  "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._m(3),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "col-sm-3 control-label" }, [
                  _vm._v(
                    "\n                僅限必修部門顯示：\n              "
                  ),
                ]),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c("div", { staticClass: "checkbox" }, [
                    _c(
                      "label",
                      [
                        _c("iCheckCheckBox", {
                          attrs: { propChecked: _vm.data.showInRequireDept },
                          on: {
                            "update:checked": function ($event) {
                              _vm.data.showInRequireDept = $event["checked"]
                            },
                          },
                        }),
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v(
                            "\n                      *未勾選的話，會顯示在其他部門的選修課程之中\n                    "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(4),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: "0",
                          message: "不設定",
                          name: "radioNotice",
                        },
                        model: {
                          value: _vm.data.scheduleSendMailType,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "scheduleSendMailType", $$v)
                          },
                          expression: "data.scheduleSendMailType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "radio-date",
                      staticStyle: { "padding-top": "7px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "1",
                              message: "單次",
                              name: "radioNotice",
                            },
                            model: {
                              value: _vm.data.scheduleSendMailType,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "scheduleSendMailType", $$v)
                              },
                              expression: "data.scheduleSendMailType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "input-group date" }, [
                        _vm._m(5),
                        _c(
                          "div",
                          { staticClass: "pull-right" },
                          [
                            _c("DatePicker", {
                              attrs: { propValue: _vm.scheduleSendMailDate },
                              model: {
                                value: _vm.scheduleSendMailDate,
                                callback: function ($$v) {
                                  _vm.scheduleSendMailDate = $$v
                                },
                                expression: "scheduleSendMailDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "radio-date",
                      staticStyle: { "padding-top": "7px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "radio" },
                        [
                          _c("iCheckRedioButton", {
                            attrs: {
                              checkValue: "2",
                              message: "每月",
                              name: "radioNotice",
                            },
                            model: {
                              value: _vm.data.scheduleSendMailType,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "scheduleSendMailType", $$v)
                              },
                              expression: "data.scheduleSendMailType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "input-group date" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.scheduleSendMailDay,
                                expression: "scheduleSendMailDay",
                              },
                            ],
                            staticClass: "form-control",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.scheduleSendMailDay = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(28, function (index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: index } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(index) +
                                    "號\n                      "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _vm._m(6),
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 1,
                          message: "啟用",
                          name: "radioMode3",
                        },
                        model: {
                          value: _vm.data.courseStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "courseStatus", $$v)
                          },
                          expression: "data.courseStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "radio-inline" },
                    [
                      _c("iCheckRedioButton", {
                        attrs: {
                          checkValue: 0,
                          message: "停用",
                          name: "radioMode3",
                        },
                        model: {
                          value: _vm.data.courseStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "courseStatus", $$v)
                          },
                          expression: "data.courseStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-body" }, [
              !_vm.toggleIsShow
                ? _c(
                    "button",
                    {
                      staticClass: "toggle-show",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.toggleIsShow = !_vm.toggleIsShow
                        },
                      },
                    },
                    [_vm._m(7)]
                  )
                : _vm._e(),
              _vm.toggleIsShow
                ? _c("div", { staticClass: "toggle-block" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "col-sm-3 control-label" }, [
                        _vm._v("新人訓練課程："),
                      ]),
                      _c("div", { staticClass: "col-sm-8" }, [
                        _c(
                          "div",
                          { staticClass: "radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "0",
                                message: "否",
                                name: "radioNewStaff",
                              },
                              model: {
                                value: _vm.data.isForNewRecruit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "isForNewRecruit", $$v)
                                },
                                expression: "data.isForNewRecruit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "radio-inline" },
                          [
                            _c("iCheckRedioButton", {
                              attrs: {
                                checkValue: "1",
                                message: "是",
                                name: "radioNewStaff",
                              },
                              model: {
                                value: _vm.data.isForNewRecruit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "isForNewRecruit", $$v)
                                },
                                expression: "data.isForNewRecruit",
                              },
                            }),
                          ],
                          1
                        ),
                        false
                          ? _c(
                              "div",
                              {
                                staticClass: "group-staff",
                                staticStyle: { "padding-top": "7px" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "radio" },
                                  [
                                    _c("iCheckRedioButton", {
                                      attrs: {
                                        checkValue: "1",
                                        message: "是",
                                        name: "radioNewStaff",
                                      },
                                      model: {
                                        value: _vm.data.isForNewRecruit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "isForNewRecruit",
                                            $$v
                                          )
                                        },
                                        expression: "data.isForNewRecruit",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.data.isForNewRecruit == 1
                                  ? _c("p", { staticClass: "day" }, [
                                      _c("span", { staticClass: "text-red" }, [
                                        _vm._v("*"),
                                      ]),
                                      _vm._v(
                                        " 需要在到職日\n                      "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.data.newRecruitFinishDay,
                                            expression:
                                              "data.newRecruitFinishDay",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number", min: "1" },
                                        domProps: {
                                          value: _vm.data.newRecruitFinishDay,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.data,
                                              "newRecruitFinishDay",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        "\n                      天內完成課程，未完成將於隔日發送通知信。\n                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.toggleIsShow
                ? _c(
                    "button",
                    {
                      staticClass: "toggle-show",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.toggleIsShow = !_vm.toggleIsShow
                        },
                      },
                    },
                    [_vm._m(8)]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "btns" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.isEdit ? "更新" : "新增") +
                      "課程\n            "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "col-sm-3 control-label", attrs: { for: "selectType" } },
      [
        _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 課程名稱：\n              "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 課程類型：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 課程分類：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text-red" }, [
      _vm._v(
        "\n                  *選擇台中總公司=設定所有部門為必修單位\n                  "
      ),
      _c("br"),
      _vm._v("*未設定=該課程為全公司選修課程\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 排程發送通知信：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-calendar" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-3 control-label" }, [
      _c("span", { staticClass: "text-red" }, [_vm._v("*")]),
      _vm._v(" 狀態：\n              "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("進階設定 "),
      _c("i", { staticClass: "fa fa-angle-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("較少設定 "),
      _c("i", { staticClass: "fa fa-angle-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }