<template>
  <div id="StudentCourseList">
    <section class="content-header">
      <h1>{{ $route.params.type == "Required" ? "必修" : "選修" }}課程</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          {{ $route.params.type == "Required" ? "必修" : "選修" }}課程
        </li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.courseType"
                @change="resetDataList()"
              >
                <option
                  v-for="(item, index) in classList.chapterType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit2"
                required="required"
                v-model="searchData.courseClass"
                @change="resetDataList()"
              >
                <option
                  v-for="(item, index) in classList.classType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit2"
                required="required"
                v-model="searchData.isFinished"
                @change="resetDataList()"
              >
                <option
                  v-for="(item, index) in classList.finishedType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 8%">
                    類型
                  </th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    分類
                  </th>
                  <th class="text-nowrap" style="width: 15%; min-width: 180px">
                    課程名稱
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    課程狀態
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    未完成章節數
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    課程內容
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="6">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">{{ data.courseType }}</td>
                    <td>{{ data.courseClass }}</td>
                    <td>{{ data.courseName }}</td>
                    <td class="text-center">
                      {{ data.isFinished == 1 ? "已完成" : "未完成" }}
                    </td>
                    <td class="text-center">
                      {{ data.notFinishedChapterCount }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-warning" @click="goCourse(data)">
                        章節
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="box-footer text-center no-border" v-show="total > 0">
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import Pagination from "@/components/Pagination";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
    Pagination,
  },
  data() {
    return {
      loading: false,
      searchData: {
        employeeId: "", // 員編
        deptId: "", // 部門id
        companyId: "", // 公司id
        courseClass: "",
        isRequire: this.$route.params.type == "Required" ? 1 : 0, // 是否必修 0：選修、1：必修
        courseType: "", // 課程類別 // 課程類別 (可不傳)
        isFinished: "", // 完成狀態 (可不傳)
      },
      classList: {},
      list: [],
      total: 0,
      employeeId: "",
      deptId: "",
      recordList: [],
      isFinished: "",
      requireCourse: [], //必修課程
    };
  },
  async created() {
    this.searchData.employeeId = this.$user.getUserData().user.employeeId;
    this.searchData.deptId = this.$user.getUserData().user.deptId;
    this.searchData.companyId = this.$user.getUserData().user.companyId;
    this.getClassList();
    this.getDataList();
  },
  watch: {
    $route() {
      (this.searchData.isRequire =
        this.$route.params.type == "Required" ? 1 : 0),
        (this.searchData.isFinished = "");
      this.searchData.courseType = "";
      this.resetDataList();
    },
  },
  methods: {
    getClassList() {
      api.education.class(this.searchData).then(({ data }) => {
        this.classList = data.result;
      });
    },
    getDataList() {
      this.loading = false;
      api.education.course.student(this.searchData).then((res) => {
        this.list = res.data.result || [];
        this.loading = false;
      });
    },
    resetDataList() {
      this.getDataList();
    },
    goCourse(item) {
      this.$router.push({
        name: "ETStudentCourse",
        params: {
          type: this.$route.params.type,
          id: item.courseId,
          name: item.courseName,
          searchData: this.$route.params.searchData,
        },
      });
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.page = pageIdx;
      this.pageSize = pageSize;
      this.getDataList();
    },
  },
};
</script>
