<template>
  <div id="OfficeSetting">
    <section class="content-header">
      <h1>辦公室管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>系統管理</li>
        <li class="active">辦公室管理</li>
      </ol>
    </section>
    <section class="content seating" v-if="!hasEditPermission">
      <p class="text-red mt-10">
        <i class="fa fa-warning"></i>
        無管理權限
      </p>
    </section>
    <section class="content seating" v-if="hasEditPermission">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增辦公室
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-bordered" id="office">
              <tbody>
                <tr class="bg-gray">
                  <th style="min-width: 150px" width="30%">名稱</th>
                  <th style="min-width: 150px">位置</th>
                  <th class="text-center">排序</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%"
                  >
                    啟用狀態
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 100px; width: 10%"
                  >
                    使用訂餐統計
                  </th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 20%"
                  >
                    功能
                  </th>
                </tr>
                <tr :key="index" v-for="(item, index) in office">
                  <td>
                    <p
                      :id="'name' + item.id"
                      class="m-0"
                      v-if="item.editor == 0"
                    >
                      {{ item.office }}
                    </p>
                    <input
                      class="form-control"
                      required="required"
                      type="text"
                      v-model="item.office"
                      v-if="item.editor != 0"
                    />
                  </td>
                  <td>
                    <p v-if="item.editor == 0">
                      {{ companyNameList[item.companyAreaId] }}
                    </p>
                    <select
                      class="form-control"
                      v-if="item.editor != 0"
                      v-model="item.companyAreaId"
                    >
                      <option
                        v-for="(area, index) in companyAreaList"
                        :value="area.companyAreaId"
                        :key="index"
                      >
                        {{ area.companyAreaName }}
                      </option>
                    </select>
                  </td>
                  <td class="text-center">
                    <p
                      :id="'name' + item.id"
                      class="m-0"
                      v-if="item.editor == 0"
                    >
                      {{ item.orderNum }}
                    </p>
                    <input
                      class="form-control text-center w90"
                      required="required"
                      type="number"
                      v-model="item.orderNum"
                      v-if="item.editor != 0"
                    />
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'check' + item.id"
                        :true-value="1"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.status"
                      />
                      <label :for="'check' + item.id"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="ckbx-style-8 ckbx">
                      <input
                        :false-value="0"
                        :id="'checkMeal' + item.id"
                        :true-value="1"
                        @change="changeStatus(item)"
                        name="ckbx-style-8"
                        type="checkbox"
                        v-model="item.isMealCount"
                      />
                      <label :for="'checkMeal' + item.id"></label>
                    </div>
                  </td>
                  <td class="text-center">
                    <button
                      @click="change(item)"
                      class="btn btn-primary"
                      v-if="item.editor == 0"
                    >
                      修改
                    </button>
                    <button
                      @click="save(item)"
                      class="btn btn-success btn-save"
                      v-if="item.editor != 0"
                    >
                      儲存
                    </button>
                    &nbsp;
                    <button
                      @click="deleteType(item, index)"
                      class="btn btn-danger"
                      v-if="item.editor == 0"
                    >
                      刪除
                    </button>
                    <button
                      @click="cancel(item, index)"
                      class="btn btn-default"
                      v-if="item.editor != 0"
                    >
                      取消
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-15">
            <i class="text-orange fa fa-warning"></i> 友善連結：【<router-link
              to="Seating"
              >座位表</router-link
            >】、【<router-link to="Account">帳號設定</router-link>】。
          </p>
        </div>
      </div>
    </section>

    <!--座位表 預覽圖片
    <div class="modal fade" id="preview">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title" id="preview-title">預覽圖片</h4>
          </div>
          <div class="modal-body text-center">
            <img draggable="false" id="preview-img" src="../assets/seat32b1.jpg" />
          </div>
          <div class="modal-footer">
            <button class="btn btn-default pull-right" data-dismiss="modal" type="button">關閉</button>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import api from "../assets/js/app-api";
import config from "@/assets/js/app-config";

export default {
  data() {
    return {
      office: [],
      officeTemp: [],
      companyAreaList: [],
      index: 0,
      hasEditPermission: false,
    };
  },
  computed: {
    companyNameList() {
      let data = [];
      this.companyAreaList.forEach((item) => {
        data[item.companyAreaId] = item.companyAreaName;
      });
      return data;
    },
  },
  mounted() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.OfficeEdit
    );
    this.workShowAll();
    this.companyAreaShowAll();
  },
  methods: {
    //*---- 顯示列表
    async workShowAll() {
      var task1;
      task1 = api.office.queryAllEdit({
        pageNum: 0,
        pageSize: 0,
      });
      var response = await task1;
      var tmp = response.data.result.datas;
      for (var i = 0; i < tmp.length; i++) {
        tmp[i]["editor"] = 0;
      }
      this.officeTemp = JSON.parse(JSON.stringify(tmp));
      this.office = tmp;
    },

    async companyAreaShowAll() {
      api.office.CompanyAreaList().then((res) => {
        this.companyAreaList = res.data.result;
      });
    },

    //*---- 儲存更新
    workUpdate(item) {
      api.office
        .update(item)
        .then((res) => {
          if (res.data.status == "1") {
            item.editor = 0;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message, "注意");
          }
        })
        .catch((err) => {
          this.$eventBus.$emit("showAlert", err.data.message, "注意");
        });
    },

    //*---- 切換文字或輸入框
    change(item) {
      item.editor = item.editor == 1 ? 0 : 1;
    },

    //*---- 儲存
    save(item) {
      var that = this;
      if (item.typeId == "") {
        if (item.office == "") {
          this.$eventBus.$emit("showAlert", "名稱不得為空", "注意");
          return;
        }
        api.office
          .create(item)
          .then((res) => {
            item.editor = item.editor == 1 ? 0 : 1;
            that.workShowAll();
          })
          .catch((err) => {
            this.$eventBus.$emit("showAlert", err.data.message, "注意");
          });
      } else {
        //item.editor = 0
        this.workUpdate(item);
      }
    },

    //*---- 新增辦公室
    addNew() {
      this.office.push({
        typeId: "",
        office: "",
        companyAreaId: this.$user.getUserCompanyId(),
        status: "1",
        editor: "1",
        isMealCount: "1",
      });
    },
    cancel(item, index) {
      var that = this;
      if (item.typeId == 0) {
        that.office.splice(index, 1);
      } else {
        var temp = JSON.parse(JSON.stringify(that.officeTemp[index]));
        that.office.splice(index, 1, temp);
      }
    },

    //*---- 改變狀態
    changeStatus(item) {
      if (item.editor == 0) this.workUpdate(item);
    },

    //*---- 整列刪除
    deleteType(item) {
      var that = this;
      this.$eventBus.$emit("showConfirm", "刪除後將無法復原，請確認是否刪除？");
      $("#attention-delete").click(function () {
        api.office
          .delete(item)
          .then((res) => {
            $("#attention").modal("hide");
            that.workShowAll();
          })
          .catch((err) => {
            $("#attention-delete").hide();
            if (err.data.message) {
              this.$eventBus.$emit("showAlert", err.data.message);
            }
          });
      });
    },
  },
};
</script>
