<template>
  <div class="editor">
    <!-- 工具欄容器 -->
    <div class="toolbar-container"></div>
    <!-- 編輯器容器 -->
    <div class="CKEditor"></div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh';

export default {
  props: ["content"],
  data() {
    return {
      editor: null // 編輯器例項
    };
  },
  mounted() {
    this.initCKEditor();
    $(".editor").each(function() {
      $(this)
        .find("table")
        .addClass("table table-bordered");
    });
  },
  watch: {
    content: function(value) {
      this.editor.setData(value);
    }
  },
  methods: {
    initCKEditor() {
      CKEditor.create(document.querySelector(".CKEditor"), {
        language: "zh",
        fontColor: {
          colors: [
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black"
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey"
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey"
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey"
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true
            }

            // ...
          ]
        },
        fontBackgroundColor: {
          colors: [
            {
              color: "hsl(0, 75%, 60%)",
              label: "Red"
            },
            {
              color: "hsl(30, 75%, 60%)",
              label: "Orange"
            },
            {
              color: "hsl(60, 75%, 60%)",
              label: "Yellow"
            },
            {
              color: "hsl(90, 75%, 60%)",
              label: "Light green"
            },
            {
              color: "hsl(120, 75%, 60%)",
              label: "Green"
            }

            // ...
          ]
        },
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "alignment",
          "|",
          "highlight",
          "code",
          "blockQuote",
          "|",
          "link",
          // "imageUpload",
          "insertTable",
          "|",
          "undo",
          "redo"
        ],
        ckfinder: {
          //uploadUrl: "/admin/Upload/uploadUrl"
          // 後端處理上傳邏輯返回json資料,包括uploaded(選項true/false)和url兩個欄位
        }
      })
        .then(editor => {
          const toolbarContainer = document.querySelector(".toolbar-container");
          toolbarContainer.appendChild(editor.ui.view.toolbar.element);
          this.editor = editor;
          // editor.model.document.on( 'change', () => {
          //     this.$emit("onContentChagne", this.editor.getData());
          // } );
          // 將編輯器儲存起來，用來隨時獲取編輯器中的內容等，執行一些操作
        })
        .catch(error => {
          var console = console;
          // console.error(error);
        });
    },
    SetData() {
      return this.editor.getData();
    }
  }
};
</script>