<template>
  <div id="Seating">
    <section class="content-header">
      <h1>座位表</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>公司通訊錄</li>
        <li class="active">座位表</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box has-select box-header with-border">
            <button
              v-if="hasEditPermission"
              @click="goOffice()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-cog"></i> 辦公室管理
            </button>
            <div class="form-group select-group">
              <label class="control-label" for="selectOffice">辦公室：</label>
              <select
                @change="changeOffice($event.target.selectedIndex)"
                class="form-control"
                id="selectOffice"
                v-model="key"
                v-if="offices && offices.length > 0"
              >
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in offices"
                >
                  {{ item.office }}
                </option>
              </select>
              <select class="form-control" id="selectOffice" v-else>
                <option value="">請選擇</option>
              </select>
            </div>
            <template v-if="offices && offices.length > 0">
              <label
                class="btn btn-primary"
                for="inputFile"
                v-if="hasEditPermission"
              >
                <input
                  @change="changeFile($event)"
                  accept=".jpg, .jpeg, .png"
                  id="inputFile"
                  onclick="this.value=null;"
                  ref="seatPic"
                  style="display: none"
                  type="file"
                />
                <i class="fa fa-picture-o"></i> 更新圖片
              </label>
              &nbsp;
              <button
                @click="deleteFile()"
                class="btn btn-danger"
                type="button"
                v-if="hasEditPermission"
              >
                刪除圖片
              </button>
            </template>
          </div>
          <div class="pic text-center" v-if="offices && offices.length > 0">
            <img :src="fileUrl" draggable="false" />
          </div>
          <p style="margin-top: 10px" v-else>尚未新增任何辦公室。</p>
        </div>
        <div class="box-footer" v-if="hasEditPermission">
          <!-- <p>
            <i class="text-orange fa fa-warning"></i>
            如需新增或修改辦公室，請至【<router-link to="/OfficeSetting"
              >辦公室管理</router-link
            >】進行設定。
          </p> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../assets/js/app-api";
import config from "@/assets/js/app-config";

export default {
  data() {
    return {
      offices: [],
      index: 0,
      key: "",
      fileUrl: "",
      hasEditPermission: false,
    };
  },
  mounted() {
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.SeatingSetting
    );
    this.workShowAll();
  },
  methods: {
    //*---- 顯示列表
    async workShowAll() {
      var task1;
      var isFilterNonFile = this.hasEditPermission == true ? 0 : 1;
      task1 = api.office.officeListEdit({
        isFilterNonFile: isFilterNonFile,
      });
      var response = await task1;
      var tmp = response.data.result.datas;
      this.offices = tmp;
      this.fileUrl = "";
      if (this.offices.length > 0) {
        this.key = this.offices[this.index].id;
        if (this.offices[this.index].files.length > 0) {
          this.fileUrl = process.env.VUE_APP_EIPIMG_PATH + this.offices[this.index].files[0].filesUrl;
        }
      }
    },

    //*---- 更換辦公室
    async changeOffice(index) {
      this.index = index;
      var that = this;
      if (this.offices[index].files.length > 0) {
        that.fileUrl = process.env.VUE_APP_EIPIMG_PATH + that.offices[index].files[0].filesUrl;
      } else {
        that.fileUrl = "";
      }
    },

    //*---- 上傳檔案
    changeFile(e) {
      var files = e.target.files;
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(PNG|JPG|JPEG|GIF)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName)) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。",
            "注意"
          );
          $("#attention-delete").hide();
          this.removeFile();
          return;
        }

        if (fileSize > 5000000) {
          this.$eventBus.$emit(
            "showAlert",
            "圖檔超過檔案上限 5 MB，請重新選擇。",
            "注意"
          );
          this.removeFile();
          return;
        }
      }

      this.preview(files); //圖片預覽
      this.uploadFile(files[0], "OfficeArea", this.key); //檔案上傳
    },
    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          that.offices.files = [];
          that.fileUrl = e.target.result;
        };
        reader.readAsDataURL(input[0]);
      }
    },
    //檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      await task_upload;
      // this.offices.id = response_upload.data.result.id;
      this.workShowAll();
    },
    // 取消上傳
    removeFile() {
      document.getElementById("inputFile").value = "";
    },

    //*---- 刪除檔案
    async deleteFile() {
      var that = this;
      this.$eventBus.$emit(
        "showConfirm",
        "刪除後將無法復原，請確認是否刪除？",
        "注意"
      );
      $("#attention-delete").click(async () => {
        var id = that.offices[this.index].files[0].applyId;
        var response_delete = await api.file.deleteApply({ id: id });
        if (response_delete.data.status == "1") {
          $("#attention").modal("hide");
          this.workShowAll();
        }
      });
    },

    goOffice() {
      this.$router.push({
        name: "OfficeSetting",
      });
    },
  },
};
</script>
