<template>
  <!-- 編輯店家 -->
  <div class="modal fade store-food" ref="store_food">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">編輯餐點</h4>
        </div>
        <div class="modal-body">
          <form class="form-horizontal">
            <div class="form-group">
              <label class="col-sm-3 control-label">店家名稱：</label>
              <div class="col-sm-9 control-text">{{ storeName }}</div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputFoodName">
                <span class="text-red">*</span> 餐點名稱：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputFoodName"
                  required="required"
                  type="text"
                  v-model="edit.mealName"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="foodPic"
                >餐點圖片：</label
              >
              <div class="col-sm-9">
                <div
                  class="pic"
                  :key="index"
                  v-for="(file, index) in edit.files"
                >
                  <img
                    draggable="false"
                    :src="imgPath + file.filesUrl"
                  />
                </div>
                <input
                  accept=".png, .gif, .jpg, .jpeg"
                  id="foodPic"
                  type="file"
                  @change="changeFile($event)"
                />
                <div class="help-block">
                  圖片建議尺寸 430 * 330，請勿小於該尺寸。
                  <br />
                  <span class="text-red">(檔案上限2MB)</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputFoodPrice">
                <span class="text-red">*</span> 售價：
              </label>
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputFoodPrice"
                  type="number"
                  v-model="edit.price"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" for="inputFoodQty"
                >可訂數量：</label
              >
              <div class="col-sm-9">
                <input
                  class="form-control"
                  id="inputFoodQty"
                  required="required"
                  type="text"
                  v-model="edit.amount"
                />
                <p class="help-block">如不限訂購數量，此欄位請保持空白。</p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">
                <span class="text-red">*</span> 種類：
              </label>
              <div class="col-sm-9">
                <table class="table table-bordered table-habits">
                  <tbody>
                    <tr>
                      <td>
                        <div class="radio">
                          <iCheckRedioButton
                            checkValue="1"
                            v-model="edit.mealType"
                            name="radioMode"
                            message="葷食"
                          />
                        </div>
                        <iCheckMultiCheckBox
                          :propOptions="foodType"
                          :propSelecteds.sync="edit.mealContainAry"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="radio">
                          <iCheckRedioButton
                            checkValue="0"
                            v-model="edit.mealType"
                            name="radioMode"
                            message="素食"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" ref="editSaveButton" type="button">
            儲存
          </button>
          <button
            class="btn btn-default pull-right"
            data-dismiss="modal"
            type="button"
          >
            關閉
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import iCheckCheckBox from "@/components/iCheckCheckBox";
import iCheckMultiCheckBox from "@/components/iCheckMultiCheckBox";

export default {
  components: {
    iCheckCheckBox,
    iCheckMultiCheckBox,
    iCheckRedioButton,
  },
  data() {
    return {
      imgPath: process.env.VUE_APP_EIPIMG_PATH,
      storeName: "",
      editMode: false,
      responseWait: false,
      edit: {},
      storeEdit: {},
      foodType: ["牛", "豬", "魚"],
    };
  },
  mounted() {
    // iCheck
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue",
    });
  },
  methods: {
    async showMealEditBox(storeId, mealId) {
      await this.getStoreInfo(storeId);

      if (this.storeEdit.storeName == "") {
        $(this.$refs.store_food).modal("hide");
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!");
        return;
      }

      this.storeName = this.storeEdit.storeName;

      if (mealId != "0") {
        this.editMode = true;
        await this.getMealInfo(mealId);
      } else {
        this.editMode = false;
        this.edit = {
          mealId: utils.getTimeId(),
          storeId: storeId,
          mealName: "",
          price: 0,
          amount: "",
          files: [],
          averageSorce: 0,
          mealType: 1,
          mealContain: "",
          mealContainAry: [],
          mealStatus: 1,
        };
      }
      $(this.$refs.store_food).modal("show");
      $(this.$refs.editSaveButton).click(() => {
        if (this.editMode) this.updateStore(this.edit);
        else {
          this.addStore(this.edit);
        }
      });
    },
    getStoreInfo(storeId) {
      return api.store
        .query({
          storeId: storeId,
        })
        .then((res) => {
          if (res.data.status == "1")
            this.storeEdit = JSON.parse(JSON.stringify(res.data.result));
          else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getMealInfo(mealId) {
      api.storeMenu
        .query({
          mealId: mealId,
        })
        .then((res) => {
          if (res.data.status == "1")
            this.edit = JSON.parse(JSON.stringify(res.data.result));
          else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }

          if (this.edit.mealContain != "" && this.edit.mealContain != null) {
            this.edit.mealContainAry = this.edit.mealContain.split(",");
          } else {
            this.edit.mealContainAry = [];
          }
          //  this.edit.mealContain = this.edit.mealContain.split(",");
          //else this.edit.mealContain = ["魚", "豬"];
        });
    },
    updateStore(dine) {
      // 更新店家餐點

      if (dine.mealType == "0") {
        dine.mealContain = "";
        dine.mealContainAry = "";
      }

      if (dine.mealContainAry != "")
        dine.mealContain = dine.mealContainAry.join();

      if (!this.checkDineInfo(dine)) return;

      if (this.responseWait) return;
      this.responseWait = true;

      let foodPic = document.getElementById("foodPic");

      if (foodPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(foodPic.files[0], "MealList", dine.mealId);
      }

      api.storeMenu.update(dine).then((res) => {
        this.responseWait = false;
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        } else {
          $(this.$refs.store_food).modal("hide");
        }

        this.$emit("getStoreMenu", dine);
      });
    },
    addStore(dine) {
      // 添加店家餐點

      if (dine.mealType == "0") {
        dine.mealContain = "";
        dine.mealContainAry = "";
      }

      if (dine.mealContainAry != "" && this.edit.mealContain != null)
        dine.mealContain = dine.mealContainAry.join();

      if (!this.checkDineInfo(dine)) return;

      if (this.responseWait) return;
      this.responseWait = true;

      let foodPic = document.getElementById("foodPic");

      if (foodPic.files.length >= 1) {
        //檔案上傳
        this.uploadFile(foodPic.files[0], "MealList", dine.mealId);
      }

      api.storeMenu.create(dine).then((res) => {
        this.responseWait = false;
        if (res.data.status != "1") {
          this.$eventBus.$emit("showAlert", "Fail!");
        } else {
          $(this.$refs.store_food).modal("hide");
        }

        this.$emit("getStoreMenu", dine);
      });

      return;
    },
    changeFile(e) {
      var files = e.target.files;
      if (files.length == 0) this.edit.files = [];
      if (files.length > 0) {
        var isCheckType = true; //是否檢查圖片副檔名
        var fileSize = files[0].size;
        var re = /\.(PNG|JPG|JPEG|GIF)$/i; //允許的副檔名
        var fileName = files[0].name;

        if (isCheckType && !re.test(fileName.toUpperCase())) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的文件格式不符，請重新選擇。"
          );
          this.removeFile();
          return;
        }

        if (fileSize > 2000000) {
          this.$eventBus.$emit(
            "showAlert",
            "您選擇的檔案超過上限 5 MB，請重新選擇。"
          );
          this.removeFile();
          return;
        }
      }

      this.preview(files);

      // //檔案上傳
      // this.uploadFile(files[0], "MealList", this.edit.mealId);
    },
    removeFile() {
      document.getElementById("foodPic").value = "";
    },

    //檔案上傳
    async uploadFile(files, folderName, id, multi = false) {
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("module", folderName);
      formdata.append("moduleId", id);
      formdata.append("multi", multi);

      var task_upload = api.file.upload(formdata);
      await task_upload;
    },

    //圖片預覽
    preview(input) {
      let that = this;
      if (input && input[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          that.edit.files = [];
          that.edit.files.push({
            filesUrl: e.target.result,
          });
        };
        reader.readAsDataURL(input[0]);
      }
    },

    checkDineInfo(dine) {
      if (dine == null || dine == undefined) {
        this.$eventBus.$emit("showAlert", "發生錯誤，請重新操作!!");
        return false;
      }
      if (dine.mealName == undefined || dine.mealName == "") {
        this.$eventBus.$emit("showAlert", "餐點名稱不可為空!!");
        return false;
      }

      if (dine.price == undefined || dine.price == 0 || dine.price == "") {
        this.$eventBus.$emit("showAlert", "請填寫餐點售價!!");
        return false;
      }

      if (dine.mealType == undefined || dine.mealType.toString() == "") {
        this.$eventBus.$emit("showAlert", "餐點種類不可為空!!");
        return false;
      }

      return true;
    },
  },
};
</script>