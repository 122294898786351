<template>
  <div id="AnnouncementEdit">
    <section class="content-header">
      <h1>特約商店</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>特約商店</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">編輯特約商店</h4>
            <button
              @click="backList()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <div class="form-horizontal" name="announcementForm">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputDocNum">
                  <span class="text-red">*</span> 商店名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="text"
                    v-model="data.storeName"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputDocNum">
                  分店名稱：
                </label>
                <div class="col-sm-10">
                  <input
                    class="form-control"
                    type="text"
                    v-model="data.applyArea"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectUnit">
                  <span class="text-red">*</span> 分類：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="data.storeType"
                  >
                    <option value="" hidden>請選擇分類</option>
                    <option
                      :value="category.classId"
                      v-for="(category, index) in categoryList"
                    >
                      {{ category.className }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="selectUnit">
                  <span class="text-red">*</span> 使用區域：
                </label>
                <div class="col-sm-10">
                  <select
                    class="form-control"
                    v-model="data.companyType"
                  >
                    <option value="" hidden>請選擇區域</option>
                    <option
                      :value="area.classId"
                      v-for="(area, index) in areaList"
                    >
                      {{ area.className }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputDocNum">
                  <span class="text-red">*</span> 合約期限：
                </label>
                <div class="col-sm-10">

                  <div class="radio-date">
                    <div class="radio">
                      <iCheckRedioButton
                        :checkValue="0"
                        message="到期時間："
                        name="radioMode"
                        v-model="data.isNoLimitContract"
                      />
                    </div>
                    <div class="input-group date">
                      <div class="input-group-addon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div class="pull-right">
                        <DatePicker v-model="data.contractTime" />
                      </div>
                    </div>
                  </div>

                  <div class="radio">
                    <iCheckRedioButton
                      :checkValue="1"
                      message="永久"
                      name="radioMode"
                      v-model="data.isNoLimitContract"
                    />
                  </div>
                </div>
              </div>
              
              <div class="form-group">
                <label class="col-sm-2 control-label" for="editor">
                  <span class="text-red">*</span> 內容：
                </label>
                <div class="col-sm-10">
                  <div class="editor">
                    <tinymce v-model.trim="data.discountOffers" />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile"
                  >上傳商店主圖：</label
                >
                <div class="col-sm-10 text">
                  <input
                    @change="changeFile($event, 'image')"
                    accept="image/*"
                    id="inputPic"
                    type="file"
                    name="pic"
                    v-if="!hasImage()"
                  />
                  <div
                    v-else
                    v-for="file in data.files"
                    style="display: flex; align-items: end;"
                  >
                    <template v-if="global.isImage(file.mine)">
                      <img
                        style="width: 150px; margin-right: 10px;"
                        class="mt-10"
                        :src="imgPath + file.filesUrl"
                      >
                      <button
                        class="btn btn-danger"
                        @click="deleteFile(file.applyId, 'image')"
                      >
                        刪除
                      </button>
                    </template>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-2 control-label" for="inputFile"
                  >上傳合約檔案：</label
                >
                <div class="col-sm-10 text">
                  <input
                    @change="changeFile($event)"
                    id="inputFile"
                    maxlength="5"
                    type="file"
                    name="file"
                  />
                  <div
                    v-for="file in data.contracts"
                    class="mt-10"
                  >
                    <a
                      style="margin-right: 10px;"
                      :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                      v-if="!global.isImage(file.mine)"
                    >
                      {{ file.name }}
                    </a>
                    <img
                      style="width: 150px; margin-right: 10px;"
                      class="mt-10"
                      :src="imgPath + file.filesUrl"
                      v-else
                    >
                    <button
                      class="btn btn-danger"
                      @click="deleteFile(file.applyId)"
                    >
                      刪除
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="btns">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="save()"
                >
                  儲存
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import moment from "moment";
import tinymce from "@/components/Tinymce";
import iCheckRedioButton from "@/components/iCheckRedioButton";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    tinymce,
    iCheckRedioButton,
    DatePicker
  },
  delimiters: ["${", "}"],
  name: "app",
  watch: {},
  data() {
    return {  
      host: process.env.VUE_APP_EIPAPI_PATH,
      imgPath: process.env.VUE_APP_EIPIMG_PATH,
      isEdit: this.$route.params.id ? true : false,
      data: {
        storeId: this.$route.params.id || new moment().format("YYYYMMDDHHmmssSSS"),
        storeName: "",
        applyArea: "",
        contractTime: new moment().format("YYYY-MM-DD"),
        isNoLimitContract: 0,
        discountOffers: "",
        storeType: "",
        companyType: "",
        commentCount: 0,
        storeStatus: 1,
        isDel: 0,
        order: 1,
      },
      areaList: [],
      categoryList: [],
    };
  },
  created() {},
  mounted() {
    $('input[type="checkbox"].flat, input[type="radio"].flat').iCheck({
      checkboxClass: "icheckbox_flat-blue",
      radioClass: "iradio_flat-blue"
    });
    
    if(this.isEdit) {
      this.getData();
    }

    this.getClassList('特約商店地區');
    this.getClassList('特約商店類別');
  },
  methods: {
    getData() {
      api.shop.admin.query({
        storeId: this.data.storeId,
        storeStatus: 1,
      })
        .then((res) => {
          this.data = res.data.result;
          $(".datepicker").val(
            new Date(this.data.contractTime).toLocaleDateString()
          );
        })
    },
    hasImage() {
      if(!this.data.files) return false
      const Index = this.data.files.findIndex(item => this.global.isImage(item.mine))
      return Index > -1
    },
    getClassList(type) {
      api.shopClass.queryAll({
        className: "", // 類別名稱
        module: type, // 類別模組 ex: 特約商店地區、特約商店類別
        classStatus: 1, // 類別狀態
        isDel: 0, // 刪除狀態 1:已刪除、0:未刪除
        pageNum: 1,
        pageSize: 999
      })
        .then((res) => {
          if(type == '特約商店地區') this.areaList = res.data.result.datas;
          if(type == '特約商店類別') this.categoryList = res.data.result.datas;
        })
    },
    changeFile(e, isImage) {
      var files = e.target.files;
      this.uploadFile(files[0], isImage)
    },
    async uploadFile(files, isImage) {
      let module = isImage ? 'AffiliatedStore' : 'AffiliatedStoreContract';
      let formdata = new FormData();
      formdata.append("files", files);
      formdata.append("moduleId", this.data.storeId);
      formdata.append("module", module);
      formdata.append("multi", true);

      api.file.upload(formdata)
        .then((res) => {
          this.refreshFileList(module);
        })
    },
    deleteFile(applyId, isImage) {
      let module = isImage ? 'AffiliatedStore' : 'AffiliatedStoreContract';
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.file.deleteApply({
              id: applyId,
            })
            .then((res) => {
              this.refreshFileList(module)
            });
        }
      });
    },
    async refreshFileList(module) {
      var task_queryall = api.file.queryApplyAll({
        moduleId: this.data.storeId,
        module: module,
      });
      var response_queryall = await task_queryall;
      if(module == 'AffiliatedStore') this.data.files = response_queryall.data.result;
      else this.data.contracts = response_queryall.data.result;
      this.$forceUpdate();
    },
    save() {
      const type = this.isEdit ? 'update' : 'create' ;

      if(this.data.storeName == "") {
        this.$eventBus.$emit("showAlert",`請輸入商店名稱`);
        return
      }

      if(this.data.storeType == "") {
        this.$eventBus.$emit("showAlert",`請選擇分類`);
        return
      }

      if(this.data.companyType == "") {
        this.$eventBus.$emit("showAlert",`請選擇使用區域`);
        return
      }

      if(this.data.discountOffer == "") {
        this.$eventBus.$emit("showAlert",`請輸入內容`);
        return
      }

      api.shop[type](this.data)
        .then((res) => {
          this.global.showResponse(this, res, type, this.backList)
        })
    },
    backList() {
      this.$router.push({
        name: "ShopList",
        params: {
          searchData: this.$route.params.searchData
        }
      });
    },
  }
};
</script>