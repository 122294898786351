<template>
  <div>
    <div id="home" v-if="hasEditPermission">
      <section class="content home">
        <div class="row meal-row" v-if="$user.hasPermission('E0')">
          <div class="col-lg-6">
            <div class="box box-warning">
              <div class="box-header with-border">
                <h3 class="box-title">本週訂餐資訊</h3>
                <p class="punch pull-right">
                  今日上班打卡時間：
                  <span
                    :class="punchStatus == '未打卡' ? 'text-red' : 'text-green'"
                    >{{ punchStatus }}</span
                  >
                </p>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  <table class="table table-bordered table-hover">
                    <tbody>
                      <tr class="bg-gray">
                        <th style="width: 15%">日期</th>
                        <th style="width: 15%">星期</th>
                        <th>店家名稱</th>
                        <th>餐點名稱</th>
                        <th style="width: 15%">備註</th>
                        <th
                          style="width: 15%; min-width: 140px"
                          class="text-center"
                        >
                          取消/保留餐點
                        </th>
                      </tr>
                      <tr
                        v-for="(day, index) in eatDays"
                        :key="index"
                        :class="{ 'bg-blue-1': day === today.getDay() }"
                      >
                        <td class="text-nowrap">
                          {{ getDate(day - 1) }}
                        </td>
                        <td class="text-nowrap">
                          {{ formatDateTime(getDate(day - 1), "dddd") }}
                        </td>
                        <td>{{ getOrderMealName(day, "storeName") }}</td>
                        <td>{{ getOrderMealName(day, "mealName") }}</td>
                        <td>
                          <template v-if="getOrderItem(day)">
                            {{
                              getOrderItem(day).manageUpdate == 3
                                ? "保留"
                                : getOrderItem(day).manageUpdate == 4
                                ? "取消"
                                : ""
                            }}
                          </template>
                        </td>
                        <td class="text-center">
                          <template v-if="checkMealBtnsShow(day)">
                            <button
                              @click="updateMealStatus(day, 'cancel')"
                              class="btn btn-danger"
                              style="margin-right: 10px"
                            >
                              取消
                            </button>
                            <button
                              @click="updateMealStatus(day, 'keep')"
                              class="btn btn-primary"
                            >
                              保留
                            </button>
                          </template>
                        </td>
                      </tr>
                      <tr class="bg-gray-light">
                        <td class="text-nowrap">{{ getToday() }}</td>
                        <td class="text-nowrap">今晚</td>
                        <td v-if="orderDinnerRecord.length > 0">
                          {{ orderDinnerRecord[0].storeName }}
                        </td>
                        <td v-else>-</td>
                        <td v-if="orderDinnerRecord.length > 0">
                          {{ orderDinnerRecord[0].mealName }}
                        </td>
                        <td v-else>-</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="text-red mt-10">
                  <i class="fa fa-warning"></i>
                  {{ tip }} 開放訂餐截止時間：{{ orderDeadline }}。
                </p>
                <table class="table border-0">
                  <tbody>
                    <tr>
                      <td>
                        <router-link
                          class="btn btn-block btn-warning"
                          :to="`/All/OrderNextWeekMeal/${expatId}`"
                        >
                          預訂{{ expatId === "J" ? "" : "下週" }}午餐
                        </router-link>
                      </td>
                      <td v-if="expatId !== 'J'">
                        <router-link
                          class="btn btn-block btn-default"
                          to="/OrderDinner"
                        >
                          預訂當天晚餐
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box box-warning">
              <div class="box-header with-border">
                <h3 class="box-title">常用連結</h3>
              </div>
              <div class="box-body">
                <div class="home-links">
                  <router-link
                    class="btn btn-purple"
                    to="/Seating"
                    v-if="expatId !== 'J'"
                  >
                    <i class="fa fa-street-view"></i>
                    <span>座位表</span>
                  </router-link>
                  <router-link
                    class="btn btn-orange"
                    to="/Extension"
                    v-if="expatId !== 'J'"
                  >
                    <i class="fa fa-fax"></i>
                    <span>分機表</span>
                  </router-link>
                  <a
                    class="btn btn-warning"
                    href="https://outlook.office.com/mail/"
                    target="_blank"
                    to
                  >
                    <i class="fa fa-envelope-o"></i>
                    <span>公司郵件</span>
                  </a>
                  <a
                    class="btn btn-success"
                    href="https://efnet.wanin.tw/EFNET/"
                    target="_blank"
                    to
                  >
                    <i class="fa fa-calendar-check-o"></i>
                    <span>電子簽核</span>
                  </a>
                  <a
                    class="btn btn-info"
                    href="https://www.wanin.tw/"
                    target="_blank"
                  >
                    <i class="fa fa-bank"></i>
                    <span>網銀官網</span>
                  </a>
                  <a
                    class="btn btn-maroon"
                    href="http://epaper.wanin.tw/"
                    target="_blank"
                  >
                    <i class="fa fa-newspaper-o"></i>
                    <span>網銀人季刊</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box box-warning" v-if="$user.hasPermission('AT')">
          <!-- <div class="box-header">
            <h3 class="box-title">活動行事曆</h3>
          </div>-->
          <div class="box-body">
            <div class="top-box with-border">
              <h4 class="title">活動行事曆</h4>
              <router-link
                :to="'/ActivityCalendar'"
                class="btn btn-primary pull-right"
                >瀏覽更多</router-link
              >
            </div>
            <div class="activity-calendar">
              <div class="btns pre-next mt-10 bg-gray-light">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="getWeekActivitys(-1)"
                >
                  <i class="fa fa-chevron-circle-left"></i>&nbsp;上一週
                </button>
                <h3 class="date"></h3>
                <button
                  class="btn btn-default"
                  type="button"
                  @click="getWeekActivitys(1)"
                >
                  下一週&nbsp;
                  <i class="fa fa-chevron-circle-right"></i>
                </button>
              </div>
              <div class="table-responsive">
                <table class="table order-table table-bordered">
                  <tbody>
                    <tr class="bg-gray">
                      <th>星期一</th>
                      <th>星期二</th>
                      <th>星期三</th>
                      <th>星期四</th>
                      <th>星期五</th>
                      <th>星期六</th>
                      <th>星期日</th>
                    </tr>
                    <tr>
                      <td
                        :class="item.class"
                        v-for="(item, index) in activitysWeek"
                        :key="index"
                      >
                        <div class="date">
                          {{ formatDateTime(item.date, "YYYY-MM-DD") }}&nbsp;
                          <span class="week">{{
                            formatDateTime(item.date, "dddd")
                          }}</span>
                        </div>
                        <div class="day">
                          {{ formatDateTime(item.date, "YYYY-MM-DD") }}
                        </div>
                        <!-- 有參加 class 加 is-join -->
                        <div
                          @click="openActivityBooking(data)"
                          :class="
                            data.joinStatus >= 0
                              ? 'event btn btn-default is-join'
                              : 'event btn btn-default'
                          "
                          v-for="(data, index) in item.datas"
                          :key="index"
                        >
                          <div class="name">
                            {{ data.typeName }}-{{ data.kindName }}-{{
                              data.activityName
                            }}
                          </div>
                          <div class="time">
                            {{ formatDateTime(data.activitySTime, "HH:mm") }}~{{
                              formatDateTime(data.activityETime, "HH:mm")
                            }}
                          </div>
                          <div class="note">{{ data.location }}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="text-red mt-10">
                <i class="fa fa-warning"></i>
                點擊活動區塊即可查看明細、進行報名參加，已過的時段無法參加。
              </p>
            </div>
          </div>
        </div>
        <div class="shop-area">
          <div
            class="box box-warning _small mr-20"
            v-if="$user.hasPermission('SP')"
          >
            <div class="box-header">
              <h3 class="box-title">熱門特約</h3>
            </div>
            <div class="box-body">
              <div class="shop-list">
                <div
                  class="shop-item"
                  v-for="(shop, index) in shopList.hot"
                  :key="index"
                  @click="goShop(shop)"
                >
                  <div class="photo">
                    <img :src="getImage(shop.files)" class="logo" alt="" />
                  </div>
                  <div class="name">{{ shop.storeName }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="box box-warning _small" v-if="$user.hasPermission('SP')">
            <div class="box-header">
              <h3 class="box-title">最新特約</h3>
            </div>
            <div class="box-body">
              <div class="shop-list">
                <div
                  class="shop-item"
                  v-for="(shop, index) in shopList.new"
                  :key="index"
                  @click="goShop(shop)"
                >
                  <div class="photo">
                    <img :src="getImage(shop.files)" class="logo" alt="" />
                  </div>
                  <div class="name">{{ shop.storeName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box box-warning" v-if="$user.hasPermission('AN')">
          <div class="box-header">
            <h3 class="box-title">公司公告</h3>
          </div>
          <div class="box-body">
            <div class="nav-tabs-custom">
              <ul class="nav nav-tabs">
                <li
                  :class="{ active: type.id == announcementTypeId }"
                  :key="index"
                  v-for="(type, index) in announcementTypes"
                >
                  <a
                    :aria-expanded="type.id == announcementTypeId"
                    :href="'#announcement_' + index"
                    data-toggle="tab"
                    v-on:click="getAnnouncements(type.id)"
                    >{{ type.typeName }}</a
                  >
                </li>
                <!-- <li class="pull-right"><a href="#" class="text-muted"><i class="fa fa-gear"></i></a></li> -->
              </ul>
              <div class="tab-content">
                <div class="tab-pane active">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered min-600">
                      <tbody>
                        <tr class="bg-gray">
                          <th style="min-width: 90px; width: 10%">發布日期</th>
                          <th style="width: 15%">公告文號</th>
                          <th class="text-nowrap" style="width: 10%">
                            公告單位
                          </th>
                          <th>公告聯絡人</th>
                          <th>公告主旨</th>
                          <th class="text-nowrap">詳閱檔案</th>
                        </tr>
                        <tr :key="index" v-for="(item, index) in announcements">
                          <td>
                            {{ formatDateTime(item.publishTime, "YYYY-MM-DD") }}
                          </td>
                          <td>{{ item.docNo }}</td>
                          <td>{{ item.issuedUnitName }}</td>
                          <td>{{ item.contactEmpName }}</td>
                          <td>
                            <router-link :to="'/Announcement/' + item.id">{{
                              item.title
                            }}</router-link>
                          </td>
                          <td class="text-center">
                            <a
                              :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                              :key="file.id"
                              :title="file.name"
                              class="file-icon"
                              v-for="file in item.files"
                              v-show="item.files.length > 0"
                            >
                              <i
                                :class="
                                  'icon icon-' + file.mine.substring(0, 3)
                                "
                              ></i>
                            </a>
                            <span v-show="item.files.length == 0">-</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.tab-content -->
            </div>
            <router-link
              :to="'/AnnouncementList/' + announcementTypeId"
              class="btn btn-primary"
              >瀏覽更多</router-link
            >
          </div>
        </div>
        <div class="box box-warning" v-if="$user.hasPermission('F0')">
          <div class="box-header">
            <h3 class="box-title">公司文件</h3>
          </div>
          <div class="box-body">
            <div class="nav-tabs-custom">
              <ul class="nav nav-tabs">
                <li
                  :class="{ active: type.tId == fileTypeId }"
                  :key="index"
                  v-for="(type, index) in fileTypes"
                >
                  <a
                    :aria-expanded="type.tId == fileTypeId"
                    :href="'#companyFiles_' + index"
                    data-toggle="tab"
                    v-on:click="getCompanyFiles(type.tId)"
                    >{{ type.typeName }}</a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered min-600">
                      <tbody>
                        <tr class="bg-gray">
                          <th style="min-width: 90px; width: 10%">上傳日期</th>
                          <th style="width: 15%">文件分類</th>
                          <th class="text-nowrap" style="width: 10%">
                            歸屬單位
                          </th>
                          <!-- <th class="text-nowrap" style="width: 5%">單位聯絡人</th>
                          <th class="text-nowrap" style="width: 5%">承辦人</th>-->
                          <th>文件名稱</th>
                          <th class="text-nowrap">附件</th>
                        </tr>
                        <tr :key="index" v-for="(file, index) in files">
                          <td>
                            {{ formatDateTime(file.createTime, "YYYY-MM-DD") }}
                          </td>
                          <td>{{ file.typeName }}</td>
                          <td>{{ file.issuedUnitName }}</td>
                          <!-- <td>{{file.contactEmpName}}</td>
                          <td>{{file.contractorName}}</td>-->
                          <td>{{ file.documentName }}</td>
                          <td class="text-center">
                            <a
                              :href="`${host}/file/GetCompanyFile?filesId=${file.filesId}`"
                              :key="file.id"
                              :title="file.name"
                              class="file-icon"
                              v-for="file in file.files"
                            >
                              <i
                                :class="
                                  'icon icon-' + file.mine.substring(0, 3)
                                "
                              ></i>
                            </a>
                            <span v-show="file.files.length == 0">-</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /.tab-content -->
            </div>
            <router-link class="btn btn-primary" to="/CompanyFiles"
              >瀏覽更多</router-link
            >
          </div>
        </div>
        <div class="box box-warning" v-if="$user.hasPermission('ET')">
          <div class="box-header">
            <h3 class="box-title">未完成之必修課程</h3>
          </div>
          <div class="box-body">
            <div class="tab-content">
              <div class="tab-pane active">
                <div class="table-responsive">
                  <table class="table table-hover table-bordered min-600">
                    <tbody>
                      <tr class="bg-gray">
                        <th class="text-center">類別</th>
                        <th>課程名稱</th>
                        <th class="text-center">功能</th>
                      </tr>
                      <tr v-if="courseList.length == 0">
                        <td class="text-center" colspan="3">
                          您已完成所有必修課程
                        </td>
                      </tr>
                      <tr
                        v-else
                        :key="index"
                        v-for="(course, index) in courseList"
                      >
                        <td class="text-center">{{ course.courseType }}</td>
                        <td>{{ course.courseName }}</td>
                        <td class="text-center">
                          <router-link
                            :to="{
                              name: 'ETStudentCourse',
                              params: {
                                type: 'Required',
                                id: course.courseId,
                                name: course.courseName,
                              },
                            }"
                            class="btn btn-warning"
                          >
                            詳細
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <activityBooking
        ref="activityBooking"
        v-on:refresh="getWeekActivitys(0)"
      />
    </div>
    <div v-else>
      <section class="content home">
        <h1 id="test" style="color: #3c8dbc">⇦請點選左側列表進行操作</h1>
      </section>
    </div>
    <midify ref="midify" :proplogin="false" />
    <!-- 無設定信箱彈窗 -->
    <div class="modal fade" id="attention-mail" style="z-index: 999">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title text-red">
              <i class="fa fa-warning"></i> 注意
            </h4>
          </div>
          <div class="modal-body">
            <p>尚未設定信箱，請盡快設定</p>
            <p>( 當忘記密碼時將會傳送新密碼至您所設定信箱 )</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success" type="button" @click="midify()">
              前往設定信箱
            </button>
            <button
              class="btn btn-default pull-right"
              id="attention-dismiss"
              data-dismiss="modal"
              type="button"
            >
              取消
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<style scoped lang="scss">
/*測試*/
#test {
  position: relative;
  animation: TestMove 1s infinite; /*IE*/
  -moz-animation: TestMove 1s infinite; /*FireFox*/
  -webkit-animation: TestMove 1s infinite; /*Chrome, Safari*/
}
@keyframes TestMove {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@-moz-keyframes TestMove {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes TestMove {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
#home {
  .box-header .punch {
    margin-bottom: 0;
    font-size: 15px;
    @media (max-width: 480px) {
      margin-top: 10px;
      float: none !important;
    }
  }
  @media (min-width: 992px) {
    .shop-area {
      display: flex;
      justify-content: space-between;
    }
    .box {
      &._small {
        display: inline-block;
        width: 49%;
      }
    }
  }
  .box {
    .shop-list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .shop-item {
        text-align: center;
        width: 25%;
        cursor: pointer;
        .photo {
          margin-bottom: 10px;
          height: 10vw;
          display: flex;
          align-items: center;

          @media (max-width: 992px) {
            height: 15vw;
          }

          @media (max-width: 767px) {
            height: 25vw;
          }

          img {
            max-width: 9vw;
            max-height: 9vw;
            margin: 0 auto;
            @media (max-width: 992px) {
              max-width: 15vw;
              max-height: 15vw;
            }
            @media (max-width: 767px) {
              max-width: 22vw;
              max-height: 22vw;
            }
          }
        }
        .name {
          font-size: 20px;
          font-weight: bold;
          height: 58px;
          @media (max-width: 768px) {
            font-size: 3.2vw;
            height: 9vw;
          }
        }
      }
    }
  }
  .meal-row {
    .col-lg-6 {
      @media (max-width: 1407px) {
        width: 100%;
      }
    }
  }
}
</style>

<script>
import api from "@/assets/js/app-api";
import moment from "moment";
import utils from "@/assets/js/app-utils";
import activityBooking from "@/views/Activity/ActivityBooking";
import config from "@/assets/js/app-config";
import midify from "@/views/Login/midify";

export default {
  components: {
    activityBooking,
    midify,
  },
  data() {
    return {
      host: process.env.VUE_APP_EIPAPI_PATH,
      employeeId: this.$store.state.loginUser.employeeId,
      companyId: this.$user.getUserCompanyId(),
      expatId: this.$user.getUserExpatId(),
      orderLunchRecord: [],
      orderDinnerRecord: [],
      today: new Date(),
      eatStartDay: new moment().startOf("isoWeek").format("YYYY/MM/DD"),
      eatEndDay: new moment()
        .add(1, "weeks")
        .endOf("isoWeek")
        .format("YYYY/MM/DD"),
      mealDaily: [],
      eatDays: 0,
      orderDeadline: "",
      tip: "",
      files: [],
      fileTypes: [],
      fileTypeId: "",
      announcements: [],
      announcementTypes: [],
      announcementTypeId: "",
      addWeeks: 0,
      activitysWeek: [],
      courseList: [],
      shopList: {
        new: [],
        hot: [],
      },
      punchStatus: "未打卡",
      openUserKeep: 0,
      checkDeadlineType: "",
    };
  },
  async created() {
    this.hasEditPermission = this.$user.hasPermission(config.Permission.Home);

    // 今日上班打卡時間
    this.getPunchStatus();

    // 本週訂餐資訊
    if (this.$user.hasPermission("E0")) {
      await this.getOrderLunchRecord();
      await this.getOrderDinnerRecord();
      await this.getOrderDaily();
      this.getDeadline();
      this.eatDays = 6;
    }

    // 公司公告
    if (this.$user.hasPermission("AN")) await this.getAnnouncementType();

    // 公司文件
    if (this.$user.hasPermission("F0")) await this.getCompanyFilesType();

    this.getDeadline();
    this.getCourseList();
    this.eatDays = 6;

    // 特約商店
    if (this.$user.hasPermission("SP")) {
      await this.getShopList(2);
      await this.getShopList(3);
    }

    // 社團活動
    if (this.$user.hasPermission("AT")) this.getWeekActivitys(0);
  },
  mounted() {
    if (this.$route.query.Com == "N") this.$router.push("/RollCallSystem");
    if (this.$store.state.loginUser.empEmail == "") {
      $("#attention-mail").modal("show");
    }

    $("body")
      .removeClass()
      .addClass("skin-black sidebar-mini wysihtml5-supported fixSidebar fixed");
  },
  methods: {
    getCourseList() {
      api.education.course
        .student({
          employeeId: this.$store.state.loginUser.employeeId, // 員編
          deptId: this.$store.state.loginUser.deptId, // 部門id
          companyId: this.$store.state.loginUser.companyId, // 公司id
          isRequire: 1, // 是否必修 0：選修、1：必修
          courseType: "", // 課程類別 // 課程類別 (可不傳)
          isFinished: "0", // 完成狀態 (可不傳)
        })
        .then((res) => {
          if (res.data.status == 1) {
            this.courseList = res.data.result;
          }
        });
    },
    getPunchStatus() {
      api.punch.queryToday().then((res) => {
        this.punchStatus = res.data.result;
        this.$forceUpdate();
      });
    },
    async getShopList(order) {
      this.loading = true;
      api.shop
        .queryAll({
          storeName: "",
          storeType: null,
          companyType: null,
          storeStatus: 1,
          isDel: 0,
          isFrontend: 1,
          order: order, // 排序 1:依照合約日期大到小排序、2:依評論數大到小排序 不帶會預設1
          pageNum: 1,
          pageSize: 3,
        })
        .then((res) => {
          let data = res.data.result.datas;
          if (order == 3) this.shopList.new = data;
          else this.shopList.hot = data;
        });
    },
    getImage(files) {
      const Index = files.findIndex((item) => this.global.isImage(item.mine));
      return Index > -1
        ? process.env.VUE_APP_EIPIMG_PATH + files[Index].filesUrl
        : "";
    },
    goShop(item) {
      this.$router.push({
        name: "ShopDetail",
        params: {
          id: item.storeId,
          Status: item.storeStatus,
          searchData: this.searchData,
        },
      });
    },
    getWeekActivitys(addWeek) {
      this.addWeeks = this.addWeeks + addWeek;
      let startTime = new moment(this.eatStartDay)
        .add(this.addWeeks, "weeks")
        .format("YYYY-MM-DD");
      let endTime = new moment(this.eatEndDay)
        .add(this.addWeeks, "weeks")
        .format("YYYY-MM-DD");

      api.activity
        .queryAll({
          startTime: startTime,
          endTime: endTime,
        })
        .then((res) => {
          let datas = [];
          if (res.data.status == "1") {
            datas = res.data.result.datas;
          }
          this.activitysWeek = [];
          for (let i = 0; i < 7; i++) {
            let obj = {
              date: "",
              datas: [],
              class: "",
            };
            obj.date = new moment(startTime)
              .add(i, "days")
              .format("YYYY-MM-DD");
            let item = [];
            if (res.data.result.total > 0)
              item = datas.filter(
                (activity) =>
                  this.formatDateTime(activity.activitySTime, "YYYY-MM-DD") ==
                    obj.date && activity.status != 0
              );

            obj.datas = item;

            if (item.length == 0) obj.class = "no-event";
            if (new moment().format("YYYY-MM-DD") == obj.date)
              obj.class = "today";

            this.activitysWeek.push(obj);
          }
        });
    },
    openActivityBooking(item) {
      this.$refs.activityBooking.showActivityBooking(item);
    },
    getOrderDaily() {
      //取得本周與下周訂餐時段
      api.mealDailySetting
        .queryAll({
          eatStartDay: this.eatStartDay,
          eatEndDay: this.eatEndDay,
          eatTime: 1,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.mealDaily = res.data.result.datas;
          }
        });
    },
    getOrderLunchRecord() {
      //取得本周與下周訂餐歷程
      api.empOrder
        .queryAll({
          eatStartDay: this.eatStartDay,
          eatEndDay: this.eatEndDay,
          companyId: this.expatId,
          employeeId: this.$store.state.loginUser.employeeId,
          eatTime: 1,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.orderLunchRecord = res.data.result.datas;
            this.$forceUpdate();
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getOrderDinnerRecord() {
      //取得晚餐
      api.empOrder
        .queryAll({
          eatStartDay: new moment().format("YYYY/MM/DD"),
          eatEndDay: new moment().format("YYYY/MM/DD"),
          companyId: this.expatId,
          employeeId: this.$store.state.loginUser.employeeId,
          eatTime: 2,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.orderDinnerRecord = res.data.result.datas;
          } else {
            this.$eventBus.$emit("showAlert", res.data.message);
            return;
          }
        });
    },
    getDeadline() {
      //未訂餐提醒
      this.mealDaily
        .filter((res) =>
          new moment(res.eatDay).isBetween(
            moment().add(1, "weeks").startOf("isoWeek"),
            moment().add(1, "weeks").endOf("isoWeek"),
            null,
            "[]"
          )
        )
        .forEach((daily) => {
          if (
            this.orderLunchRecord.filter(
              (order) => order.eatDay == daily.eatDay
            ).length == 0
          ) {
            this.tip = "提醒您下週餐點尚未預訂！";
            return;
          }
        });

      //訂餐截止時間
      api.mealTimeSet
        .queryLunch({
          companyId: this.expatId,
        })
        .then((res) => {
          if (res.data.status == "1") {
            this.orderDeadline = new moment()
              .startOf("isoWeek")
              .add(res.data.result.weekEnd - 1, "Days")
              .format("YYYY/MM/DD " + res.data.result.hourEnd + ":00:00");

            // 10點之前可調本日和明日之後，10點之後可調整明日之後
            this.openUserKeep = res.data.result.openUserKeep;
            let nowTime = new moment().format("HHmm");
            this.checkDeadlineType =
              Number(nowTime) < 1000 ? "today" : "others";
          }
        });
    },
    getDateOrderLunch(day) {
      //取得餐點
      let lunch = this.orderLunchRecord.filter(
        (order) => this.formatDateTime(order.eatDay, "YYYY/MM/DD") == day
      );
      return lunch;
    },

    getOrderItem(day) {
      return this.getDateOrderLunch(this.getDate(day - 1))[0];
    },
    getOrderTotal(day) {
      return this.getDateOrderLunch(this.getDate(day - 1)).total;
    },
    getOrderMealName(day, key) {
      const item = this.getOrderItem(day);
      if (this.getOrderTotal(day) != 0 && item) {
        if (item.eatStatus == "0") {
          return "不訂餐";
        } else {
          if (item[key]) return item[key];
          else return "未訂餐";
        }
      } else if (this.checkDailyMeal(this.getDate(day - 1))) {
        return "未訂餐";
      } else {
        return "休假";
      }
    },
    checkMealBtnsShow(eatDay) {
      // 休假/不訂餐/晚餐，隱藏按鈕 = 有餐點名稱或未訂餐，顯示按鈕
      const getMealName = this.getOrderMealName(eatDay, "mealName");
      const hasMealName = getMealName != "不訂餐" && getMealName != "休假";

      if (this.openUserKeep == 1 && hasMealName) {
        const day = this.getDate(eatDay - 1);
        const nowDate = new moment().format("YYYY-MM-DD");
        const checkDate = new moment(day).format("YYYY-MM-DD");
        const isToday = checkDate == nowDate;
        const isOverToday = new moment(day).isAfter(nowDate);

        // 判斷是否今天日期 + 可調本日 or 其他日期 + 可調下周
        if (
          ((isToday || isOverToday) && this.checkDeadlineType == "today") ||
          (isOverToday && this.checkDeadlineType == "others")
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    checkDailyMeal(day) {
      //檢查本周訂餐
      let daily = this.mealDaily.filter(
        (res) => this.formatDateTime(res.eatDay, "YYYY/MM/DD") == day
      );

      if (daily.length != 0) return true;
      return false;
    },
    async getAnnouncementType() {
      var resAnnouncementTypes = await api.announcementtype.queryall({
        status: "1",
      });
      this.announcementTypes = resAnnouncementTypes.data.result.datas;
      if (this.announcementTypes[0] && this.announcementTypes[0].id) {
        this.getAnnouncements(this.announcementTypes[0].id);
      }
    },
    async getAnnouncements(tId) {
      this.announcementTypeId = tId;
      var resAnnouncements = await api.announcement.queryall({
        typeId: tId,
        pageNum: 1,
        pageSize: 5,
        target: this.$store.state.loginUser.companyId,
      });
      this.announcements = resAnnouncements.data.result.datas;
    },
    async getCompanyFilesType() {
      var resFileTypes = await api.companyFilesType.queryall({
        status: "1",
        IsShowHome: 1,
      });
      this.fileTypes = resFileTypes.data.result.datas;
      if (this.fileTypes.length > 0) {
        this.getCompanyFiles(this.fileTypes[0].tId);
      }
    },
    async getCompanyFiles(tId) {
      this.fileTypeId = tId;
      var resFiles = await api.companyFiles.queryall({
        typeId: tId,
        pageNum: 1,
        pageSize: 5,
        target: this.$store.state.loginUser.companyId,
      });
      this.files = resFiles.data.result.datas;
    },
    formatDateTime(datetime, format) {
      return utils.formatTime(datetime, format);
    },
    getDate(day) {
      return this.formatDateTime(
        new moment().startOf("isoWeek").add(day, "Days"),
        "YYYY/MM/DD"
      );
    },
    getToday() {
      return this.formatDateTime(new moment(), "YYYY/MM/DD");
    },
    midify() {
      this.$refs.midify.showMidifyBox("info");
    },

    updateMealStatus(day, type) {
      console.log("[updateMealStatus]", day, type);
      let confirmMsg = "";
      let msg = "";
      let status = 0;
      if (type == "cancel") {
        msg = "取消";
        confirmMsg = "確定要取消餐點嗎？";
        status = 4;
      }
      if (type == "keep") {
        msg = "保留";
        status = 3;
      }

      if (confirmMsg) {
        this.$eventBus.$emit("showConfirm", confirmMsg, (isOK) => {
          if (isOK) {
            this.updateMealStatusApi(day, status, msg);
          }
        });
      } else {
        this.updateMealStatusApi(day, status, msg);
      }
    },
    updateMealStatusApi(day, status, msg) {
      let item = this.getOrderItem(day);
      let params = "";

      if (item) {
        let eatDay = item.eatDay.split("T")[0];
        let eatID = item.eatId;
        params = {
          EatDay: eatDay,
          EatId: eatID, //如果有的話，一定要傳
          ManageUpdate: status, //員工手動 3:保留 4:取消
        };
      } else {
        params = {
          EatDay: new moment(this.getDate(day - 1)).format("YYYY-MM-DD"),
          EmployeeId: this.employeeId,
          ManageUpdate: status, // 0：取消訂餐，1：恢復訂餐，2：保留訂餐
        };
      }
      console.log("updateMealStatusApi", item, params);
      api.empOrder.updateEatStatus(params).then((res) => {
        if (res.data.status == "1") {
          this.$eventBus.$emit("showAlert", msg + "成功！");
          this.getOrderLunchRecord();
        } else {
          this.$eventBus.$emit("showAlert", msg + "失敗！");
        }
      });
    },
  },
};
</script>