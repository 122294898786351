<template>
  <div id="CourseRecordDetailEdit">
    <section class="content-header">
      <h1>新增部門必修</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>課程管理</li>
        <li class="active">新增部門必修</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <div class="select-group">
              <select
                class="form-control"
                id="courseType"
                required="required"
                v-model="searchData.courseType"
                @change="getDataList()"
              >
                <option
                  v-for="(item, index) in classList.chapterType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="select-group">
              <select
                class="form-control"
                id="selecclassTypetType"
                required="required"
                v-model="searchData.courseClass"
                @change="getDataList()"
              >
                <option
                  v-for="(item, index) in classList.classType"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋關鍵字"
                type="text"
                v-model="searchData.courseName"
              />
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  @click="getDataList()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 10%">
                    類型
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    分類
                  </th>
                  <th class="text-nowrap" style="width: 15%; min-width: 180px">
                    課程名稱
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    必修
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="4">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="4">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(data, index) in list" :key="index">
                    <td class="text-center">{{ data.courseType }}</td>
                    <td class="text-center">{{ data.courseClass }}</td>
                    <td>{{ data.courseName }}</td>
                    <td class="text-center">
                      <div
                        class="ckbx-style-8 ckbx toggleInput"
                        @click="checkIsAllDeptCourse(data)"
                      >
                        <input
                          :id="`check_${index}`"
                          :false-value="0"
                          :true-value="1"
                          name="ckbx-style-8"
                          type="checkbox"
                          v-model="data.isRequire"
                          @change="changeStatus(data)"
                          :disabled="data.isAllDeptCourse"
                        />
                        <label :for="`check_${index}`"></label>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import api from "@/assets/js/app-api";

export default {
  components: {
    iCheckCheckBox,
  },
  data() {
    return {
      loading: false,
      searchData: {
        deptId: this.$route.params.depId, // 修課部門id
        courseName: "", // 課程名稱
        courseType: "", // 課程類別
        courseClass: "", // 課程分類
        courseStatus: 1, // 0：停用、1：啟用
        order: 1, // 排序 1.創立時間新到舊
        pageNum: 1, // 頁碼
        pageSize: 999, // 頁數
        isDel: 0, // 是否刪除 0：啟用、1：刪除
        getTotalTime: 1, // 取得課程總時數 0：停用、1：啟用 ps. 需要顯示總時數再傳入1即可
        // courseStatus: 1, // 課程狀態 0：停用、1：啟用
        // createEmpId: "", // 創立員編
      },
      classList: {},
      list: [],
    };
  },
  watch: {
    list: {
      handler: function (val) {},
      deep: true,
      immediate: true,
    },
  },
  async created() {
    if (this.$route.params.chaterSearchData != undefined) {
      this.searchData = this.$route.params.chaterSearchData;
    }
    this.getClassList();
    this.getDataList();
  },
  methods: {
    getClassList() {
      api.education.class().then(({ data }) => {
        this.classList = data.result;
      });
    },
    getDataList() {
      this.loading = false;
      api.education.course.queryAll(this.searchData).then((res) => {
        this.list = res.data.result.datas;
        this.loading = false;
      });
    },
    changeStatus(data) {
      if (data.isAllDeptCourse) {
        data.isRequire = 1;
        return;
      }
      if (data.isRequire) this.saveRelationDept(data);
      else this.deleteRelationDept(data);
    },
    deleteRelationDept(data) {
      api.education.record.dept.delete({
        courseId: data.courseId, // 課程id
        doCourseDept: this.$route.params.name, // 修課部門
        doCourseDeptId: this.$route.params.depId, // 修課部門id
      });
    },
    saveRelationDept(data) {
      api.education.record.dept.create({
        courseId: data.courseId, // 課程id
        doCourseDept: this.$route.params.name, // 修課部門
        doCourseDeptId: this.$route.params.depId, // 修課部門id
      });
    },
    back() {
      this.$router.push({
        name: "ETCourseRecordPersonal",
        params: {
          type: this.$route.params.type,
          searchData: this.$route.params.searchData,
        },
      });
    },

    checkIsAllDeptCourse(data) {
      if (data.isAllDeptCourse) {
        this.$eventBus.$emit(
          "showAlert",
          "該課程為全公司必修課，無法取消必修！"
        );
      }
    },
  },
};
</script>
