import { render, staticRenderFns } from "./CourseEdit.vue?vue&type=template&id=12196ea8"
import script from "./CourseEdit.vue?vue&type=script&lang=js"
export * from "./CourseEdit.vue?vue&type=script&lang=js"
import style0 from "./CourseEdit.vue?vue&type=style&index=0&id=12196ea8&lang=scss"
import style1 from "./CourseEdit.vue?vue&type=style&index=1&id=12196ea8&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\web\\eipweb\\webpack\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12196ea8')) {
      api.createRecord('12196ea8', component.options)
    } else {
      api.reload('12196ea8', component.options)
    }
    module.hot.accept("./CourseEdit.vue?vue&type=template&id=12196ea8", function () {
      api.rerender('12196ea8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/EducationTraining/CourseEdit.vue"
export default component.exports