<template>
  <div id="StudentCourseDetail">
    <section class="content-header">
      <h1>{{ courseName }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">{{ courseName }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body left">
          <template v-if="isQuiz">
            <quiz
              v-on:updateStatus="quizCallUpdate"
              :isFinished="testData.isFinished"
            ></quiz>
          </template>

          <div
            class="top-box"
            v-if="!isQuiz && datas && datas.learnMaterialType != 2"
          >
            <div class="info">
              <p class="times">
                上課進度：
                {{
                  secondToMinute(learningTime) >= datas.chapterTime
                    ? datas.chapterTime
                    : secondToTime(learningTime)
                }}
                / {{ datas.chapterTime }}
              </p>
              <p class="text-red">
                請開啟聲音，避免瀏覽器將禁音視為閒置狀態而影響上課計時
              </p>
            </div>
            <button
              class="btn btn-warning btn-add"
              type="button"
              @click="showInfo()"
              v-if="datas.chapterDesc"
            >
              章節介紹
            </button>
          </div>
          <div class="check-block" :class="{ 'has-check': finishMsg }">
            <video
              id="video"
              width="100%"
              v-if="
                !isQuiz &&
                datas.videoFiles &&
                datas.videoFiles[0] &&
                datas.learnMaterialType == 0
              "
              :src="`${hostName()}${datas.videoFiles[0].filesUrl}`"
              controls
              controlslist="nodownload noremoteplayback noplaybackrate"
              disabledPictureInPicture="true"
              disabledRemotePlayback="true"
            >
              Your browser does not support HTML video.
            </video>
            <template v-if="!isQuiz">
              <div class="ppt is-youtube" v-show="datas.learnMaterialType == 1">
                <div class="loading">載入中...</div>
                <div class="youtube-video" v-show="youtubeId">
                  <div
                    id="youtube-video"
                    style="
                      width: 100% !important;
                      height: 100% !important;
                      position: absolute;
                      left: 0;
                      top: 0;
                      z-index: 2;
                    "
                  ></div>
                </div>
                <iframe
                  id="iframe"
                  :src="pptUrl(datas.pptUrl)"
                  frameborder="0"
                  allowfullscreen="true"
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                  v-if="
                    !youtubeId &&
                    datas &&
                    datas.pptUrl &&
                    !getYoutubeVideoId(datas.pptUrl)
                  "
                >
                </iframe>
              </div>
              <div class="ppt is-pdf" v-if="datas.learnMaterialType == 2">
                <PDFDocument
                  :pdfUrl="pdfUrl"
                  v-on:isPageBottom="isPageBottom"
                  ref="pdfDocument"
                />
              </div>
              <div
                class="checkbox"
                v-if="datas.clickToFinish == 1 && finishMsg"
              >
                <label
                  :class="{
                    'is-disabled': !isScrollToBottom || alreadyFinished,
                  }"
                >
                  <iCheckCheckBox
                    v-bind:propChecked="isFinishedRead"
                    v-on:input="readClickStatus"
                    :propDisabled="
                      !isScrollToBottom || isFinishedRead || alreadyFinished
                    "
                  />
                  {{ finishMsg }}
                </label>
              </div>
            </template>
          </div>
        </div>
        <div class="box-body right">
          <div class="title">
            課程章節
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
          <div class="chapter">
            <div
              class="item"
              :class="{
                'text-primary': !isQuiz && item.chapterId == datas.chapterId,
              }"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="check">
                <i class="fa fa-check" v-if="item.isFinished == '1'"></i>
              </div>
              <div>
                <div
                  class="name"
                  :class="{
                    'in-class': !isQuiz && datas.chapterId == item.chapterId,
                  }"
                  @click="changeChapter(item)"
                >
                  {{ item.chapterName }}
                </div>
                <div>
                  <button
                    class="btn btn-default"
                    @click="download(item.files)"
                    v-if="item.files.length > 0"
                  >
                    <i class="fa fa-file"></i>
                    附件下載
                  </button>
                </div>
              </div>
            </div>
            <div
              class="item quiz"
              :class="{ 'text-primary': isQuiz }"
              v-if="testData.testId"
            >
              <div class="check">
                <i class="fa fa-check" v-if="testData.isFinished"></i>
              </div>
              <div>
                <div
                  class="name"
                  :class="{ 'in-class': isQuiz }"
                  @click="changeChapter(datas, 'test')"
                >
                  課程測驗
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--章節介紹-->
      <div class="modal fade" ref="info" v-if="datas.chapterDesc">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-center">章節介紹</h4>
            </div>
            <div class="modal-body">
              <div
                v-if="datas.chapterDesc"
                v-html="changeN(datas.chapterDesc)"
              ></div>
              <div v-else>無章節介紹</div>
            </div>
            <div class="modal-footer text-center">
              <button
                class="btn btn-default"
                data-dismiss="modal"
                type="button"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import quiz from "@/components/CourseQuiz";
import iCheckCheckBox from "@/components/iCheck_CheckBox";
import PDFDocument from "@/components/PDFDocument";
import api from "@/assets/js/app-api";

export default {
  components: {
    quiz,
    iCheckCheckBox,
    PDFDocument,
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      employeeId: "",
      datas: {
        chapterName: this.$route.params.chapterName || "", // 章節名稱
        chapterDesc: "", // 章節介紹
        courseId: this.$route.params.id || "", // 課程id
        chapterId: this.$route.params.chapterId || "",
        chapterStatus: 1, // 章節狀態
        chapterTime: "00:00:00", // 章節時數
        learnMaterialType: 0, // 教材類型 0：影片、1：簡報
        startTime: "", // 上課時間
        teacher: "", // 講師
        // chapterOrder: 3, // 章節排序
        // isDel: 0, // 是否刪除 0：啟用、1：刪除
        // createEmpId: "123123", // 創立人員編
        // createEmpName: "aaa", // 創立人姓名
      },
      courseName: this.$route.params.name,
      list: [],
      testData: [],
      video: "",
      videoTimeline: 0,
      learningTime: 0,
      countNumber: 0,
      counters: "",
      notFinishCount: 0,
      isQuiz: false,

      isPlaying: false,
      isAlreadySetChapterCount: {},

      player: null,
      youtubeId: "",
      alreadySetYTseekTo: false,
      pageVisibilityState: "",

      pdfUrl: "",
      finishMsg: "",
      isFinishedRead: false,
      alreadyFinished: false,
      isScrollToBottom: false,

      storageName: "",
    };
  },
  mounted() {
    this.employeeId = this.$user.getUserData().user.employeeId;
    this.isQuiz = this.$route.query && this.$route.query.quiz;
    this.getLearningRecord();
    this.checkCourseIsFinish();
    this.getData();

    // 監聽分頁被切換隱藏
    const that = this;
    document.addEventListener("visibilitychange", function () {
      that.pageVisibilityState = document.visibilityState;
    });
  },
  watch: {
    $route(newVal, oldVal) {
      this.clearChapterCount();

      if (newVal.query && newVal.query.quiz) {
        this.isQuiz = true;
        this.checkCourseIsFinish();
        if (this.player && typeof this.player.stopVideo === "function") {
          this.player.stopVideo();
        }
        return;
      }
      this.isQuiz = false;
      this.datas.chapterId = newVal.params.chapterId;
      this.datas.chapterName = newVal.params.chapterName;

      if (newVal.params.chapterId != oldVal.params.chapterId) {
        if (this.player && typeof this.player.stopVideo === "function") {
          this.player.stopVideo();
        }
      }

      this.getData();
    },
    youtubeId(newVal, oldVal) {
      if (oldVal) {
        this.changeYoutubeId(newVal);
      } else {
        if (newVal) {
          this.loadYouTubeAPI();
        }
      }
    },
    list: {
      handler: function (val) {},
      deep: true,
    },
    isAlreadySetChapterCount: {
      handler: function (val) {},
      deep: true,
    },
    datas: {
      handler: function (val) {},
      deep: true,
    },
  },
  methods: {
    hostName() {
      const url = location.href;
      if (url.indexOf("localhost") > -1 || url.indexOf("dev") > -1)
        return "https://dev-eip.wanin.tw/";
      else return "https://eip.wanin.tw/";
    },
    checkCourseIsFinish() {
      api.education.course
        .check({
          courseId: this.datas.courseId, // 課程id
          employeeId: this.employeeId, // 員編
        })
        .then(({ data }) => {
          if (data.status == 1) {
            this.$eventBus.$emit("showAlert", "已完成課程");
          }
        });
    },
    getLearningRecord() {
      // 查詢是否有建立過明細
      api.education.record.main
        .query({
          courseId: this.datas.courseId, // 課程id
          employeeId: this.employeeId, // 員編
          order: 1, // 1：創建時間新到舊
        })
        .then(({ data }) => {
          if (!data.result) {
            this.setLearningRecord();
          }
        });
    },
    setLearningRecord() {
      // 建立明細
      api.education.record.main.create({
        courseId: this.datas.courseId, // 課程id
        employeeId: this.employeeId, // 員編
      });
    },
    getData() {
      this.notFinishCount = 0;
      this.learningTime = 0;
      this.alreadySetYTseekTo = false;

      const datas = {
        courseId: this.$route.params.id, // 課程id
        employeeId: this.employeeId,
      };
      if (!this.isQuiz) {
        datas.chapterId = this.$route.params.chapterId; // 章節id
      }

      api.education.chapter.query(datas).then(({ data }) => {
        if (data.status == 1) {
          this.datas = data.result;
          this.courseName = this.datas.courseName;
          this.isFinishedRead = this.datas.isFinished == 1 ? true : false;
          this.finishMsg = this.datas.finishMsg;
          this.list = this.datas.chapterList;
          this.testData = this.datas.testData;
          this.list.forEach((item) => {
            if (item.isFinished == 0) this.notFinishCount += 1;
          });

          const courseId = this.datas.courseId;
          const chapterId = this.datas.chapterId;
          this.storageName = "learningTime_" + courseId + "_" + chapterId;

          if (
            (this.datas.videoFiles && this.datas.videoFiles[0]) ||
            this.datas.pptUrl
          ) {
            this.getTime();
          }
        } else {
          this.$eventBus.$emit("showAlert", "查無課程章節！");
        }
      });
    },
    getTime() {
      const storageLearningTime = localStorage.getItem(this.storageName);

      api.education.record.detail
        .query({
          employeeId: this.employeeId, // 員編
          courseId: this.datas.courseId, // 課程id
          chapterId: this.datas.chapterId, // 章節id
        })
        .then(({ data }) => {
          this.video = document.getElementById("video");
          if (data.status == "1") {
            this.learningTime = 0;
            if (data.result && data.result.learningTime) {
              this.videoTimeline =
                this.timeToSecond(data.result.videoTimeline) || 0;

              const hasStorage =
                storageLearningTime &&
                this.timeToSecond(storageLearningTime) >
                  this.timeToSecond(data.result.learningTime);

              this.learningTime = this.timeToSecond(data.result.learningTime);

              if (this.video) {
                this.video.currentTime = this.isFinishedRead
                  ? 0
                  : hasStorage
                  ? this.timeToSecond(storageLearningTime) || 0
                  : this.videoTimeline || 0;
              }
            }
          }

          if (this.datas.videoFiles && this.datas.videoFiles[0]) {
            this.setVideoAndPdfListener();
          } else if (this.datas.pptUrl) {
            this.setYtIframeListener();
          } else {
            this.hideLoading();
          }
        });
    },
    download(files) {
      var a = document.createElement("a");
      a.href = this.hostName() + files[0].filesUrl;
      a.download = this.courseName + files[0].name;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getCurrentTime() {
      if (this.datas.learnMaterialType == 0) {
        return this.video.currentTime;
      } else {
        if (this.youtubeId) {
          return this.player.getCurrentTime();
        } else {
          return this.videoTimeline;
        }
      }
    },

    setChapterCount(type) {
      if (this.isQuiz) return;
      this.clearChapterCount();

      if (type == "notVideo") this.saveTime();
      this.counters = setInterval(() => {
        if (this.isPlaying || type == "notVideo") this.saveTime();
      }, 1000);
    },
    clearChapterCount() {
      clearInterval(this.counters);
      this.counters = "";
      this.countNumber = 0;

      const isAlreadySetChapterCount = Object.keys(
        this.isAlreadySetChapterCount
      );
      if (isAlreadySetChapterCount.length > 0) {
        const that = this;
        isAlreadySetChapterCount.forEach((key) => {
          that.isAlreadySetChapterCount[key] = false;
        });
      }

      this.hideLoading();
    },
    saveTime() {
      if (this.learningTime >= this.timeToSecond(this.datas.chapterTime)) {
        this.clearChapterCount();
        return;
      }

      this.countNumber++; // 計算5秒為一個單位
      this.learningTime++; // 每秒計時呈現

      let videoTimeline = this.secondToTime(this.getCurrentTime());
      let learningTime = this.secondToTime(Number(this.learningTime));

      localStorage.setItem(this.storageName, videoTimeline);

      if (this.countNumber % 5 == 0) {
        this.checkLearningDetail(learningTime, videoTimeline);
      } else {
        // 影片不是5的倍數，影片跑完後，傳送api紀錄
        const chapterTime = this.timeToSecond(this.datas.chapterTime);
        if (this.learningTime == chapterTime) {
          this.checkLearningDetail(learningTime, videoTimeline);
        }
      }
    },
    checkLearningDetail(learningTime, videoTimeline, type) {
      api.education.record.detail
        .create({
          employeeId: this.employeeId,
          courseId: this.datas.courseId,
          chapterId: this.datas.chapterId,
          learningTime: learningTime || "00:00:00",
          videoTimeline: videoTimeline || "00:00:00",
        })
        .then(({ data }) => {
          if (data.status == 1) {
            if (
              (learningTime &&
                this.timeToSecond(learningTime) >=
                  this.timeToSecond(this.datas.chapterTime)) ||
              type == "readModel"
            ) {
              if (type == "readModel") {
                this.alreadyFinished = true;
              }
              this.checkAndGoNextCourse();
            }
          }
        });
    },
    checkAndGoNextCourse() {
      const Index = this.list.findIndex(
        (item) => item.chapterId == this.datas.chapterId
      );

      if (this.notFinishCount == 1 && this.list[Index].isFinished == 0) {
        this.list[Index].isFinished = 1;
        this.checkCourseIsFinish();

        // 有測驗才導
        if (this.testData.testId) this.changeChapter(this.datas, "test");
        return;
      }

      if (this.notFinishCount >= 1) {
        for (let i = 1; i <= this.list.length; i++) {
          let num = Index + i;

          if (num >= this.list.length) {
            // 不回流 + 刷新狀態
            this.getData();
            return;
          }

          // 已完成的章節不導頁
          if (this.datas.isFinished) return;

          let nextIndex =
            num >= this.list.length ? num - this.list.length : num;
          if (this.list[nextIndex] && this.list[nextIndex].isFinished == 0) {
            if (this.player) this.player.stopVideo();
            this.$router.push({
              name: "ETStudentCourseDetail",
              params: {
                type: this.$route.params.type,
                id: this.$route.params.id,
                name: this.$route.params.name,
                chapterId: this.list[nextIndex].chapterId,
                chapterName: this.list[nextIndex].chapterName,
                searchData: this.$route.params.searchData,
              },
            });
            break;
          }
        }
      }
    },
    timeToSecond(time) {
      let h = time.split(":")[0];
      let m = time.split(":")[1];
      let s = time.split(":")[2];

      let second = 0;
      second += parseInt(h * 3600);
      second += parseInt(m * 60);
      second += parseInt(s);
      return second;
    },
    secondToMinute(time) {
      let minute = 0;
      minute += Math.floor(parseInt(time / 60));
      return minute;
    },
    secondToTime(time) {
      let hr = String(Math.floor(time / 3600)).padStart(2, 0);
      let min = String(Math.floor((time - hr * 3600) / 60)).padStart(2, 0);
      let sec = String(parseInt(time - hr * 3600 - min * 60)).padStart(2, 0);
      return hr + ":" + min + ":" + sec;
    },
    setVideoAndPdfListener() {
      if (this.storageName) localStorage.removeItem(this.storageName);

      this.pdfUrl = "";
      if (this.datas.videoFiles[0].mine == "pdf") {
        this.pdfUrl = this.hostName() + this.datas.videoFiles[0].filesUrl;
        this.hideLoading();
        return;
      }

      if (!this.video) return;

      const that = this;
      this.video.addEventListener("pause", (event) => {
        const notFinished =
          that.secondToMinute(that.learningTime) >= that.datas.chapterTime;
        if (notFinished || that.video.currentTime < that.video.duration) {
          that.isPlaying = false;
        }
      });
      this.video.addEventListener("play", (event) => {
        that.isPlaying = true;

        if (this.isAlreadySetChapterCount[this.datas.chapterId]) return;
        this.isAlreadySetChapterCount[this.datas.chapterId] = true;
        that.setChapterCount();
      });

      this.video.onratechange = (event) => {
        if (event.target.defaultPlaybackRate != 1) {
          this.$eventBus.$emit(
            "showAlert",
            "加速不會縮短課程剩餘時數，請使用1倍速觀課，避免影響您的上課紀錄！"
          );
        }
      };

      this.hideLoading();
    },
    setYtIframeListener() {
      if (this.datas && this.datas.pptUrl) {
        let url = this.pptUrl(this.datas.pptUrl);
        this.youtubeId = this.getYoutubeVideoId(url);
        if (!this.youtubeId) this.setChapterCount("notVideo");
      }
    },
    getYoutubeVideoId(url) {
      const regex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
    loadYouTubeAPI() {
      const storageLearningTime = localStorage.getItem(this.storageName);
      const hasStorage =
        storageLearningTime &&
        this.timeToSecond(storageLearningTime) > this.videoTimeline;
      const that = this;

      // 加載 YouTube IFrame API
      if (typeof YT === "undefined") {
        console.log("加載 YouTube IFrame API");
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        tag.onload = () => {
          loadPlayer();
        };
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        if (!that.player) loadPlayer("reload");
      }

      // 等待 API 加載完成後初始化播放器
      function loadPlayer(type) {
        if (type == "reload") {
          setYoutbePlayer();
          return;
        }
        window.onYouTubeIframeAPIReady = () => {
          setYoutbePlayer();
        };
      }

      function setYoutbePlayer() {
        that.player = new window.YT.Player("youtube-video", {
          videoId: that.youtubeId,
          playerVars: {
            rel: 0,
          },
          events: {
            onStateChange: onPlayerStateChange,
            onPlaybackRateChange: onPlaybackRateChange,
          },
        });
      }

      let setKeepPlay = false;
      function onPlayerStateChange(event) {
        if (that.pageVisibilityState == "hidden" && that.isPlaying) {
          // 原本播放中，分頁卻被切換，繼續播放
          setKeepPlay = true;
        }

        if (event.data === window.YT.PlayerState.PLAYING) {
          setTimeout(() => {
            if (that.videoTimeline && !that.alreadySetYTseekTo) {
              that.alreadySetYTseekTo = true;
              that.player.seekTo(
                this.isFinishedRead
                  ? 0
                  : hasStorage
                  ? that.secondToTime(storageLearningTime)
                  : that.videoTimeline
              );

              if (this.storageName) localStorage.removeItem(this.storageName);
            }
          }, 100);
          that.isPlaying = true;

          if (that.isAlreadySetChapterCount[that.datas.chapterId]) return;
          that.isAlreadySetChapterCount[that.datas.chapterId] = true;
          that.setChapterCount();
        } else if (event.data === window.YT.PlayerState.PAUSED) {
          const notFinished =
            that.secondToMinute(that.learningTime) >= that.datas.chapterTime;
          if (
            notFinished ||
            that.player.getCurrentTime() < that.player.getDuration()
          ) {
            that.isPlaying = false;

            if (setKeepPlay) {
              setTimeout(() => {
                that.player.playVideo();
                setKeepPlay = false;
              }, 100);
            }
          }
        }
      }

      function onPlaybackRateChange(event) {
        const newRate = event.data;
        if (newRate != 1) {
          that.$eventBus.$emit(
            "showAlert",
            "加速不會縮短課程剩餘時數，請使用1倍速觀課，避免影響您的上課紀錄！"
          );
        }
      }

      this.hideLoading();
    },
    changeYoutubeId(id) {
      if (this.player && typeof this.player.loadVideoById === "function") {
        this.player.loadVideoById(id);
        this.player.stopVideo();
      }
    },

    showInfo() {
      $(this.$refs.info).modal("show");
    },
    goDetail() {
      this.$router.push({
        name: "ETStudentCourseDetail",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    pptUrl(data) {
      let url = data.split('src="')[1].split('"')[0];
      return url;
    },
    changeChapter(item, type) {
      this.isFinishedRead = false;
      this.alreadyFinished = false;
      this.isScrollToBottom = false;
      this.pdfUrl = "";

      this.$router.push({
        name: "ETStudentCourseDetail",
        params: {
          type: this.$route.params.type,
          id: this.$route.params.id,
          name: this.$route.params.name,
          chapterId: type == "test" ? this.testData.testId : item.chapterId,
          chapterName: item.chapterName,
          chaterSearchData: this.$route.params.chaterSearchData,
          searchData: this.$route.params.searchData,
        },
        query: type == "test" ? { quiz: true } : null,
      });
    },
    back() {
      this.$router.push({
        name: "ETStudentCourse",
        params: {
          id: this.$route.params.id,
          type: this.$route.params.type,
          name: this.$route.params.name,
          searchData: this.$route.params.searchData,
        },
      });
    },
    changeN(data) {
      return data.replace(/(\n)/g, "<br>");
    },

    quizCallUpdate(val) {
      this.testData.isFinished = val == true ? 1 : 0;
      this.checkCourseIsFinish();
    },

    isPageBottom(val) {
      this.isScrollToBottom = val;
      if (val) console.log("滾動到底部!!");
    },

    readClickStatus(status) {
      this.isFinishedRead = status == 1 ? true : false;
      if (this.isFinishedRead) {
        this.checkLearningDetail("", "", "readModel");
      }
    },
  },
  beforeDestroy() {
    this.clearChapterCount();
  },
};
</script>

<style lang="scss" scoped>
@mixin max-md {
  @media (max-width: 991px) {
    @content;
  }
}
.youtube-video {
  height: 0;
  padding-bottom: 56%;
  position: relative;
}
#StudentCourseDetail {
  .box {
    display: flex;
    @include max-md {
      flex-wrap: wrap;
    }
    .box-body {
      &.left {
        width: calc(100% - 350px);
        padding: 10px;
        @include max-md {
          width: 100%;
          order: 2;
        }

        .check-block {
          .checkbox {
            margin: 15px 0 0 0;
            padding: 0 5%;
            font-size: 16px;
            .is-disabled {
              color: #888;
              cursor: not-allowed;
            }
          }
          &.has-check {
            padding-bottom: 5%;
            .ppt {
              padding-bottom: 0;
            }
          }
        }

        #video,
        .ppt {
          padding: 5%;
        }

        .ppt {
          position: relative;
          .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
          }

          iframe {
            position: relative;
            width: 100% !important;
            height: 38vw !important;
            z-index: 2;
          }
        }
      }
      &.right {
        width: 350px;
        border-left: 1px solid #d2d6de;
        padding: 10px 0 0 0;
        @include max-md {
          width: 100%;
          order: 1;
          border-left: 0;
          border-bottom: 20px solid #ecf0f5;
        }
        .title {
          font-size: 22px;
          font-weight: bold;
          border-bottom: 1px solid #d2d6de;
          padding: 15px 20px;
        }
        .chapter {
          padding: 20px;
          .item {
            font-size: 16px;
            margin-bottom: 20px;
            display: flex;
            align-items: flex-start;
            .check {
              width: 20px;
              height: 20px;
              background-color: #eeeeee;
              margin-right: 10px;
              border: 1px solid #c5c5c5;
              position: relative;

              i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #000;
              }
            }
            .name {
              cursor: pointer;

              &.in-class {
                font-weight: bold;
              }
            }
            .btn-default {
              background-color: white;
              margin-top: 10px;
            }

            &.quiz {
              margin-bottom: 0;
              padding-top: 20px;
              border-top: 1px solid #d2d6de;
            }
          }
        }
      }

      .top-box {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 600px) {
          flex-wrap: wrap;
          .info {
            order: 2;
          }
          .btn {
            order: 1;
          }
        }
        .info {
          width: 100%;
        }
        p {
          margin: 0;
        }
        .times {
          font-size: 18px;
          margin: 7px auto;
        }
        .btn {
          font-size: 20px;
        }
      }
    }
  }
  .btn-cog {
    width: auto;
    float: right;
  }
}
</style>