<template>
  <div id="Score">
    <section class="content-header">
      <h1>年度考核作業(處主管)</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">年度考核作業(處主管)</li>
      </ol>
    </section>
    <section class="content seating">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <div class="col-5">
              <div class="select-group">
                <select
                  class="form-control w90"
                  v-model.number="searchData.year"
                  @change="getDataList()"
                  style="margin-left: 0"
                >
                  <option
                    v-for="(year, index) in years"
                    :key="index"
                    :value="year"
                  >
                    {{ year }}
                  </option>
                </select>
                <select
                  class="form-control w90"
                  v-model.number="searchData.month"
                  @change="getDataList()"
                >
                  <option value="0">上半年</option>
                  <option value="1">下半年</option>
                </select>
                <select
                  class="form-control"
                  v-model="divisionId"
                  @change="getList()"
                  v-if="divisionList.length > 0"
                >
                  <option
                    v-for="(item, index) in divisionList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
                <select
                  class="form-control"
                  v-model="searchData.deptId"
                  @change="getDataList(true)"
                >
                  <option value="" v-if="divisionList.length > 0">全部</option>
                  <option
                    v-for="(item, index) in deptList"
                    :key="index"
                    :value="item.deptId"
                  >
                    {{ item.deptName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="tc mw100">部門</th>
                  <th class="tc mw100">員工編號</th>
                  <th class="tc mw100">姓名</th>
                  <th class="tc mw90">加分合計</th>
                  <th class="tc mw90">最後總分</th>
                  <th class="tc mw200" style="width: 200px">說明</th>
                  <th class="tc mw150">管理</th>
                </tr>
                <tr v-if="loading">
                  <td class="tc" colspan="8">查詢中</td>
                </tr>
                <tr v-else-if="list.length === 0">
                  <td class="tc" colspan="8">
                    {{ message || "查詢無任何資料" }}
                  </td>
                </tr>
                <tr v-else v-for="(data, index) in list" :key="index">
                  <td class="tc">{{ data.deptName }}</td>
                  <td class="tc">{{ data.employeeId }}</td>
                  <td class="tc">{{ data.employeeName }}</td>
                  <td class="tc">
                    <span v-if="data.totalScore == null"> - </span>
                    <span
                      v-else
                      style="cursor: pointer; text-decoration: underline"
                      class="text-primary"
                      @click="showContent(data)"
                    >
                      {{ data.totalScore ? data.totalScore : "0" }}
                    </span>
                  </td>
                  <td class="tc">
                    <div v-if="!data.isEdit">
                      {{ data.oneBalanceScore }}
                    </div>
                    <div class="tc" v-else style="width: 80px; margin: auto">
                      <input
                        class="form-control text-center"
                        type="number"
                        v-model="data.oneBalanceScore"
                      />
                    </div>
                  </td>
                  <td class="tc">
                    <div v-if="!data.isEdit">
                      {{ data.oneBalanceNote }}
                    </div>
                    <div v-else>
                      <input
                        class="form-control text-center"
                        type="text"
                        v-model="data.oneBalanceNote"
                      />
                    </div>
                  </td>
                  <td class="tc">
                    <button
                      class="btn btn-primary"
                      v-if="!data.isEdit && !isClose"
                      @click="edit(data)"
                    >
                      修改
                    </button>
                    <button
                      class="btn btn-success"
                      v-if="data.isEdit"
                      @click="updateDate(data)"
                    >
                      儲存
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-if="!isClose && list.length > 0"
        >
          <button class="btn btn-primary" @click="save()">完成送出</button>
        </div>
      </div>
    </section>
    <ScorePersonalHistory ref="ScorePersonalHistory" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import ScorePersonalHistory from "./ScorePersonalHistory";
import Pagination from "@/components/Pagination";

export default {
  components: {
    ScorePersonalHistory,
    Pagination,
  },
  data() {
    return {
      loading: true,
      total: 0,
      searchData: {
        year: this.global.getNowHalfYear(),
        month: this.global.getHalfYear(),
        deptId: "",
        deptLevel: 1, //1:為處主管平衡加分 2:為部主管平衡加分(人資部、人才培訓部、法務部)
      },
      divisionId: "",
      list: [],
      person: this.global.getPerson(this.$route.params.type),
      years: this.global.getYearList(),
      // addData: {},
      userData: {},
      permission: {},
      deptList: [],
      divisionList: [],
      users: [],
      datas: [],
      isClose: false,

      message: "",
    };
  },
  created() {
    this.userData = this.$user.getUserData();
    this.divisionList = this.userData.deptEmp.filter(
      (item) => Number(item.deptLevel) <= 50
    );
    this.userData.deptEmp = this.userData.deptEmp.filter(
      (item) => item.companyId == "A"
    );
    if (this.divisionList.length == 0) {
      this.deptList = this.userData.deptEmp;
      this.searchData.deptId = this.userData.deptEmp[0].deptId;
      this.getDataList();
    }
    if (this.divisionList.length > 0) {
      this.searchData.deptId = this.divisionList[0].deptId;
      this.divisionId = this.divisionList[0].deptId;
      this.getList();
    }
  },
  methods: {
    getList() {
      api.okr.v2.big.deptQueryAll({ deptId: this.divisionId }).then((res) => {
        this.deptList = res.data.result;
        this.searchData.deptId = res.data.result[0].deptId;
        this.getDataList();
      });
    },
    getDataList(checkAll) {
      this.loading = true;
      if (checkAll == true) this.searchData.divisionId = this.divisionId;
      else this.searchData.divisionId = "";
      api.okr.v2.big.queryAll(this.searchData).then(({ data }) => {
        this.list = data.result ? data.result.datas : [];
        this.isClose = data.result && data.result.isClose == 0 ? false : true;
        this.message = data.message;
        this.loading = false;
      });
    },
    resetDataList() {
      this.searchData.pageNum = 1;
      this.getDataList();
    },
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.getDataList();
    },
    edit(item) {
      item.isEdit = true;
      this.$forceUpdate();
    },
    updateDate(item) {
      item.isEdit = false;
      api.okr.v2.big.update(item).then((res) => {
        this.global.showResponse(this, res, "update");
        this.getDataList();
      });
      this.$forceUpdate();
    },
    save() {
      this.$eventBus.$emit(
        "showConfirm",
        "請確認<span class='text-red'>所有部門的所有人員</span>均已調整完成，是否執行送出？",
        (isOK) => {
          if (isOK) {
            api.okr.v2.big.create(this.searchData).then((res) => {
              this.global.showResponse(this, res, "send", this.getDataList());
            });
          }
        }
      );
    },
    showContent(data) {
      this.$refs.ScorePersonalHistory.showContent(data, this.searchData);
    },
    updateListSearch() {
      if (
        this.person.level !== 9 &&
        this.loading &&
        !this.$route.params.searchData
      ) {
        this.searchData.deptId = this.global.getDeptId(
          this.deptList,
          this.userData,
          this.permission
        );
        if (!this.searchData.deptId && this.deptList.length) {
          this.searchData.deptId = this.deptList[0].deptId || "";
        }
      }
    },
  },
};
</script>