<template>
  <div id="CourseList">
    <section class="content-header">
      <h1>課程紀錄</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">課程紀錄</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning max850">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left">{{ $route.params.name }}</h4>
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              class="btn btn-block btn-success btn-add mr-10"
              type="button"
              style="min-width: auto"
              @click="exportList()"
            >
              匯出
            </button>
          </div>
          <div class="table-responsive">
            <p>時數：{{ course.courseTotalTime || 0 }}</p>
            <p v-if="course.hasTest">
              測驗及格分數：{{ course.passingScore || 0 }}分
            </p>
            <p v-if="course.teachers">講師：{{ course.teachers }}</p>

            <div class="select-flex">
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectCompany"
                  required="required"
                  v-model="searchData.isRequire"
                  @change="getDataList()"
                >
                  <option value="1">必修課程</option>
                  <option value="0">選修課程</option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectCompany"
                  required="required"
                  v-model="searchData.empCompanyId"
                  @change="getDataList()"
                >
                  <option
                    :value="company.companyId"
                    v-for="(company, index) in companyList"
                    :key="index"
                  >
                    {{ company.companyName }}
                  </option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectDept"
                  required="required"
                  v-model="searchData.deptId"
                  @change="getDataList()"
                >
                  <option
                    :value="dept.deptId"
                    v-for="(dept, index) in deptList"
                    :key="index"
                  >
                    {{ dept.deptIdName || dept.deptName }}
                  </option>
                </select>
              </div>
              <div class="select-group">
                <select
                  class="form-control"
                  id="selectFinished"
                  required="required"
                  v-model="searchData.isFinished"
                  @change="getDataList()"
                >
                  <option value="">全部狀態</option>
                  <option value="0">未完成</option>
                  <option value="1">已完成</option>
                </select>
              </div>
            </div>

            <table
              class="table table-hover text-center table-bordered min-800 mt-10"
            >
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap">部門</th>
                  <th class="text-nowrap">姓名</th>
                  <th class="text-nowrap">職位</th>
                  <th class="text-nowrap" style="min-width: 100px">簽到時間</th>
                  <th class="text-nowrap">簽退時間</th>
                  <th class="text-nowrap" v-if="course.hasTest">測驗分數</th>
                  <th class="text-nowrap">完成狀態</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td colspan="6">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="course.learnRecordList.length == 0">
                  <tr>
                    <td colspan="6">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-for="(data, index) in course.learnRecordList"
                    :key="index"
                  >
                    <td>{{ data.deptName }}</td>
                    <td>{{ data.employeeName }}</td>
                    <td>{{ data.empTitleName }}</td>
                    <td>
                      <span v-if="data.startTime">{{
                        data.startTime | dateFormat("YYYY-MM-DD HH:mm")
                      }}</span>
                      <span v-else> - </span>
                    </td>
                    <td>
                      <span v-if="data.endTime">{{
                        data.endTime | dateFormat("YYYY-MM-DD HH:mm")
                      }}</span>
                      <span v-else> - </span>
                    </td>
                    <td v-if="course.hasTest">
                      <span v-if="data.score">{{ data.score }}分</span>
                      <span v-else>-</span>
                    </td>
                    <td>{{ data.isFinished ? "已完成" : "未完成" }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
#CourseList {
  .select-flex {
    display: flex;
    width: 100%;
    .select-group {
      width: 100%;
      margin-left: 10px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
</style>
<script>
import api from "@/assets/js/app-api";
import utils from "@/assets/js/app-utils";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      loading: false,
      searchData: {
        empCompanyId: this.$store.state.loginUser.companyId, // 公司id
        deptId: this.$store.state.loginUser.deptId, // 部門id(可不傳)
        courseId: this.$route.params.id || "", // 課程id
        isFinished: "",
        isRequire: 1,
      },
      course: {
        createTime: "",
        courseTotalTime: "",
        teachers: "",
        learnRecordList: [],
      },

      userData: {},
      companyList: [],
      deptList: [],
    };
  },
  async created() {
    this.userData = this.$user.getUserData();

    const storageName = "ETCourseDetail_" + this.searchData.courseId;
    const isRequire = localStorage.getItem(storageName);
    if (isRequire) {
      this.searchData.isRequire = isRequire;
      localStorage.removeItem(storageName);
    }

    this.getCompanyList();
    this.getDeptList();
    this.getDataList();
  },
  methods: {
    getCompanyList() {
      api.education.company().then(({ data }) => {
        this.companyList = data.result;
      });
    },
    getDeptList() {
      api.education
        .deptList({
          deptType: "", // 部門層級
          companyId: this.searchData.companyId, // 公司代號
        })
        .then(({ data }) => {
          this.deptList = data.result || [];
        });
    },
    getDataList() {
      this.loading = true;
      api.education.course.detail(this.searchData).then(({ data }) => {
        if (data.status == "1") this.course = data.result;
        this.loading = false;
      });
    },
    async exportList() {
      if (!this.course.learningEmp) {
        this.$eventBus.$emit("showAlert", "無資料!!");
        return;
      }

      const optionalCourseEmp = this.course.learningEmp.optionalCourseEmp;
      let optionalData = this.setExportDatas(optionalCourseEmp);

      const requireCourseEmp = this.course.learningEmp.requireCourseEmp;
      let requireData = this.setExportDatas(requireCourseEmp);

      const obj = [
        {
          exportData: optionalData,
          sheetName: "選修",
        },
        {
          exportData: requireData,
          sheetName: "必修",
        },
      ];
      utils.exportMultipleSheetsData(
        obj,
        this.$route.params.name + "_" + "課程紀錄" + ".xlsx"
      );
    },
    setExportDatas(datas) {
      let newDatas = [];
      if (datas.length > 0) {
        datas.forEach((res) => {
          let startTime = res.startTime
            ? new moment(res.startTime).format("YYYY-MM-DD HH:ss")
            : "-";
          let endTime = res.endTime
            ? new moment(res.endTime).format("YYYY-MM-DD HH:ss")
            : "-";
          const pushData = {
            部門: res.deptName,
            姓名: res.employeeName,
            職位: res.empTitleName,
            簽到時間: startTime,
            簽退時間: endTime,
          };
          if (this.course.hasTest) {
            pushData["測驗分數"] = res.score ? res.score : "-";
          }
          pushData["完成狀態"] = res.isFinished == 1 ? "已完成" : "未完成";
          newDatas.push(pushData);
        });
      }
      return newDatas;
    },

    back() {
      this.$router.push({
        name: "ETCourseList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
