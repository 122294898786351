var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "StudentCourseDetail" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v(_vm._s(_vm.courseName))]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v(_vm._s(_vm.courseName))]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c(
          "div",
          { staticClass: "box-body left" },
          [
            _vm.isQuiz
              ? [
                  _c("quiz", {
                    attrs: { isFinished: _vm.testData.isFinished },
                    on: { updateStatus: _vm.quizCallUpdate },
                  }),
                ]
              : _vm._e(),
            !_vm.isQuiz && _vm.datas && _vm.datas.learnMaterialType != 2
              ? _c("div", { staticClass: "top-box" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("p", { staticClass: "times" }, [
                      _vm._v(
                        "\n              上課進度：\n              " +
                          _vm._s(
                            _vm.secondToMinute(_vm.learningTime) >=
                              _vm.datas.chapterTime
                              ? _vm.datas.chapterTime
                              : _vm.secondToTime(_vm.learningTime)
                          ) +
                          "\n              / " +
                          _vm._s(_vm.datas.chapterTime) +
                          "\n            "
                      ),
                    ]),
                    _c("p", { staticClass: "text-red" }, [
                      _vm._v(
                        "\n              請開啟聲音，避免瀏覽器將禁音視為閒置狀態而影響上課計時\n            "
                      ),
                    ]),
                  ]),
                  _vm.datas.chapterDesc
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-warning btn-add",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.showInfo()
                            },
                          },
                        },
                        [_vm._v("\n            章節介紹\n          ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "check-block",
                class: { "has-check": _vm.finishMsg },
              },
              [
                !_vm.isQuiz &&
                _vm.datas.videoFiles &&
                _vm.datas.videoFiles[0] &&
                _vm.datas.learnMaterialType == 0
                  ? _c(
                      "video",
                      {
                        attrs: {
                          id: "video",
                          width: "100%",
                          src: `${_vm.hostName()}${
                            _vm.datas.videoFiles[0].filesUrl
                          }`,
                          controls: "",
                          controlslist:
                            "nodownload noremoteplayback noplaybackrate",
                          disabledPictureInPicture: "true",
                          disabledRemotePlayback: "true",
                        },
                      },
                      [
                        _vm._v(
                          "\n            Your browser does not support HTML video.\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isQuiz
                  ? [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.datas.learnMaterialType == 1,
                              expression: "datas.learnMaterialType == 1",
                            },
                          ],
                          staticClass: "ppt is-youtube",
                        },
                        [
                          _c("div", { staticClass: "loading" }, [
                            _vm._v("載入中..."),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.youtubeId,
                                  expression: "youtubeId",
                                },
                              ],
                              staticClass: "youtube-video",
                            },
                            [
                              _c("div", {
                                staticStyle: {
                                  width: "100% !important",
                                  height: "100% !important",
                                  position: "absolute",
                                  left: "0",
                                  top: "0",
                                  "z-index": "2",
                                },
                                attrs: { id: "youtube-video" },
                              }),
                            ]
                          ),
                          !_vm.youtubeId &&
                          _vm.datas &&
                          _vm.datas.pptUrl &&
                          !_vm.getYoutubeVideoId(_vm.datas.pptUrl)
                            ? _c("iframe", {
                                attrs: {
                                  id: "iframe",
                                  src: _vm.pptUrl(_vm.datas.pptUrl),
                                  frameborder: "0",
                                  allowfullscreen: "true",
                                  mozallowfullscreen: "true",
                                  webkitallowfullscreen: "true",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm.datas.learnMaterialType == 2
                        ? _c(
                            "div",
                            { staticClass: "ppt is-pdf" },
                            [
                              _c("PDFDocument", {
                                ref: "pdfDocument",
                                attrs: { pdfUrl: _vm.pdfUrl },
                                on: { isPageBottom: _vm.isPageBottom },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.datas.clickToFinish == 1 && _vm.finishMsg
                        ? _c("div", { staticClass: "checkbox" }, [
                            _c(
                              "label",
                              {
                                class: {
                                  "is-disabled":
                                    !_vm.isScrollToBottom ||
                                    _vm.alreadyFinished,
                                },
                              },
                              [
                                _c("iCheckCheckBox", {
                                  attrs: {
                                    propChecked: _vm.isFinishedRead,
                                    propDisabled:
                                      !_vm.isScrollToBottom ||
                                      _vm.isFinishedRead ||
                                      _vm.alreadyFinished,
                                  },
                                  on: { input: _vm.readClickStatus },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.finishMsg) +
                                    "\n              "
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          2
        ),
        _c("div", { staticClass: "box-body right" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n          課程章節\n          "),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-default btn-cog",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-reply" }),
                _vm._v(" 返回列表\n          "),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "chapter" },
            [
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item",
                    class: {
                      "text-primary":
                        !_vm.isQuiz && item.chapterId == _vm.datas.chapterId,
                    },
                  },
                  [
                    _c("div", { staticClass: "check" }, [
                      item.isFinished == "1"
                        ? _c("i", { staticClass: "fa fa-check" })
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "name",
                          class: {
                            "in-class":
                              !_vm.isQuiz &&
                              _vm.datas.chapterId == item.chapterId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeChapter(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.chapterName) +
                              "\n              "
                          ),
                        ]
                      ),
                      _c("div", [
                        item.files.length > 0
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                on: {
                                  click: function ($event) {
                                    return _vm.download(item.files)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-file" }),
                                _vm._v(
                                  "\n                  附件下載\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                )
              }),
              _vm.testData.testId
                ? _c(
                    "div",
                    {
                      staticClass: "item quiz",
                      class: { "text-primary": _vm.isQuiz },
                    },
                    [
                      _c("div", { staticClass: "check" }, [
                        _vm.testData.isFinished
                          ? _c("i", { staticClass: "fa fa-check" })
                          : _vm._e(),
                      ]),
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "name",
                            class: { "in-class": _vm.isQuiz },
                            on: {
                              click: function ($event) {
                                return _vm.changeChapter(_vm.datas, "test")
                              },
                            },
                          },
                          [_vm._v("\n                課程測驗\n              ")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm.datas.chapterDesc
        ? _c("div", { ref: "info", staticClass: "modal fade" }, [
            _c("div", { staticClass: "modal-dialog modal-md" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(0),
                _c("div", { staticClass: "modal-body" }, [
                  _vm.datas.chapterDesc
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.changeN(_vm.datas.chapterDesc)),
                        },
                      })
                    : _c("div", [_vm._v("無章節介紹")]),
                ]),
                _vm._m(1),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title text-center" }, [
        _vm._v("章節介紹"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-default",
          attrs: { "data-dismiss": "modal", type: "button" },
        },
        [_vm._v("\n              關閉\n            ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }