var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "StoreMenu" } },
    [
      _c("section", { staticClass: "content-header" }, [
        _c("h1", [_vm._v("店家管理")]),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
            1
          ),
          _c("li", [_vm._v("訂餐服務管理")]),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/Stores" } }, [
                _vm._v("店家管理"),
              ]),
            ],
            1
          ),
          _c("li", { staticClass: "active" }, [_vm._v("菜單管理")]),
        ]),
      ]),
      _c("section", { staticClass: "content" }, [
        _c("div", { staticClass: "box box-warning store-menu max850" }, [
          _c("div", { staticClass: "box-body" }, [
            _c("div", { staticClass: "top-box two-btn" }, [
              _c("h4", { staticClass: "pull-left" }, [
                _vm._v(_vm._s(_vm.storeName) + " - 菜單列表"),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-default btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.backList()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-reply" }),
                  _vm._v(" 返回列表\n          "),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-warning btn-cog",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openMealInfo("0")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-plus" }),
                  _vm._v(" 新增餐點\n          "),
                ]
              ),
              _c("div", { staticClass: "text" }, [
                _c("p", { staticClass: "data-num" }, [
                  _vm._v("資料總筆數：" + _vm._s(_vm.total)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "table-responsive mt-10" }, [
              _c(
                "table",
                {
                  staticClass: "table table-hover table-bordered",
                  staticStyle: { "min-width": "650px" },
                },
                [
                  _c(
                    "tbody",
                    [
                      _vm._m(0),
                      _vm._l(_vm.menuList, function (meal) {
                        return _c("tr", { key: meal.mealId }, [
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "pic jqimgFill" },
                              _vm._l(meal.files, function (file) {
                                return _c("img", {
                                  key: file.filesId,
                                  attrs: {
                                    src: _vm.imgPath + file.filesUrl,
                                    draggable: "false",
                                  },
                                })
                              }),
                              0
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(meal.mealName))]),
                          _c("td", [_vm._v(_vm._s(meal.price))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(meal.amount == null ? "不限" : meal.amount)
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(meal.mealType == "1" ? "葷" : "素")),
                          ]),
                          _c("td", [_vm._v(_vm._s(meal.averageScore))]),
                          _c("td", { staticClass: "text-center" }, [
                            _c("div", { staticClass: "ckbx-style-8 ckbx" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: meal.mealStatus,
                                    expression: "meal.mealStatus",
                                  },
                                ],
                                attrs: {
                                  "false-value": 0,
                                  id: "meal" + meal.mealId,
                                  "true-value": 1,
                                  name: "ckbx-style-8",
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(meal.mealStatus)
                                    ? _vm._i(meal.mealStatus, null) > -1
                                    : _vm._q(meal.mealStatus, 1),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = meal.mealStatus,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? 1 : 0
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              meal,
                                              "mealStatus",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              meal,
                                              "mealStatus",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(meal, "mealStatus", $$c)
                                      }
                                    },
                                    function ($event) {
                                      return _vm.changeStatus(meal)
                                    },
                                  ],
                                },
                              }),
                              _c("label", {
                                attrs: { for: "meal" + meal.mealId },
                              }),
                            ]),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning",
                                attrs: { title: "餐點評比" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openOrderScore(meal.mealId)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-star-half-o" })]
                            ),
                            _vm._v("\n                   \n                  "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { title: "編輯" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openMealInfo(meal.mealId)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-pencil" })]
                            ),
                            _vm._v("\n                   \n                  "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                attrs: { title: "刪除" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDine(meal)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fa fa-trash" })]
                            ),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("storeEditMenu", {
        ref: "storeEditMenu",
        on: { getStoreMenu: _vm.getStoreMenu },
      }),
      _c("orderScore", {
        ref: "orderScore",
        on: { refreshList: _vm.getStoreMenu },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "text-nowrap", staticStyle: { width: "10%" } }, [
        _vm._v("圖片"),
      ]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("名稱")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("售價")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("可訂數量")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("種類")]),
      _c("th", { staticClass: "text-nowrap" }, [_vm._v("平均評分")]),
      _c("th", { staticClass: "text-center text-nowrap" }, [
        _vm._v("上架狀態"),
      ]),
      _c(
        "th",
        { staticClass: "text-center", staticStyle: { "min-width": "160px" } },
        [_vm._v("功能")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }