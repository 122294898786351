<template>
  <div id="MarqueeList">
    <section class="content-header">
      <h1>跑馬燈管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>跑馬燈管理</li>
        <li class="active">{{ announcementtypes.typeName }}</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              @click="addNew()"
              class="btn btn-block btn-warning btn-add"
              type="button"
            >
              <i class="fa fa-plus"></i> 新增跑馬燈訊息
            </button>
            <!-- <div class="select-group">
              <select
                class="form-control"
                required="required"
                v-model="searchData.companyId"
                @change="clickSearch()"
              >
                <option
                  :key="index"
                  :value="item.targetId"
                  v-for="(item, index) in targetList"
                >
                  {{ item.targetName }}
                </option>
              </select>
            </div> -->
            <div class="select-group">
              <select
                class="form-control"
                id="selectUnit"
                required="required"
                v-model="searchData.isStop"
                @change="clickSearch()"
              >
                <option value="-1">全部</option>
                <option value="0">啟用</option>
                <option value="1">停用</option>
              </select>
            </div>
            <div class="input-group">
              <input
                class="form-control pull-right"
                name="table_search"
                placeholder="搜尋跑馬燈訊息"
                type="text"
                v-model="searchData.keyword"
              />
              <div class="input-group-btn">
                <button
                  v-on:click="clickSearch()"
                  class="btn btn-default"
                  type="button"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="text">
              <p class="data-num">
                <span v-if="(searchShow = 1)">資料總筆數：</span>
                <span v-if="searchShow != 1">搜尋結果筆數：</span>
                <span id="total">{{ marquees.total }}</span>
              </p>
            </div>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <tbody>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="min-width: 300px">
                    跑馬燈訊息
                  </th>
                  <th class="text-nowrap text-center">開始時間</th>
                  <th class="text-nowrap text-center">結束時間</th>
                  <th class="text-nowrap text-center">排序</th>
                  <th class="text-center" style="min-width: 60px; width: 5%">
                    狀態
                  </th>
                  <th class="text-nowrap text-center">發布日期</th>
                  <th
                    class="text-nowrap text-center"
                    style="min-width: 150px; width: 10%"
                  >
                    功能
                  </th>
                </tr>
                <template v-if="marquees.total > 0">
                  <tr :key="item.id" v-for="item in marquees.datas">
                    <td v-html="item.note"></td>
                    <td class="text-nowrap text-center">
                      {{ formatTime(item.startTime) }}
                    </td>
                    <td class="text-nowrap text-center">
                      {{ formatTime(item.endTime) }}
                    </td>
                    <td class="text-nowrap text-center">{{ item.orderNum }}</td>
                    <td class="text-nowrap text-center">
                      {{ item.isStop == 0 ? "啟用" : "停用" }}
                    </td>
                    <td class="text-nowrap text-center">
                      {{ formatTime(item.createTime) }}
                    </td>
                    <td class="text-center">
                      <button @click="editorItem(item)" class="btn btn-primary">
                        修改
                      </button>
                      &nbsp;
                      <button @click="deleteItem(item)" class="btn btn-danger">
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="10">沒有資料</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="box-footer text-center no-border"
          v-show="marquees.total > 0"
        >
          <Pagination
            ref="pagination"
            :total="total"
            :pageLimitCount="searchData.pageSize"
            v-on:pageMethod="onPageChange"
          />
        </div>
      </div>
    </section>
    <MarqueeEdit ref="marqueeEdit" v-on:getMarqueeList="clickSearch" />
  </div>
</template>

<script>
import api from "@/assets/js/app-api";
import Pagination from "@/components/Pagination";
import utils from "@/assets/js/app-utils";
import config from "@/assets/js/app-config";
import MarqueeEdit from "@/views/Marquee/MarqueeEdit";

export default {
  components: {
    Pagination,
    MarqueeEdit,
  },
  delimiters: ["${", "}"],
  name: "app",
  props: {
    id: "",
  },
  watch: {
    $route(to, from) {
      this.searchData.keyword = "";
      this.searchData.companyId = this.userData.companyId;
      this.searchData.pageNum = 1;
      this.queryAll();
    },
  },
  data() {
    return {
      searchShow: 1,
      announcementtypes: [],
      marquees: [],
      marqueeTemp: [],
      userPermission: "",
      userData: "",
      hasEditPermission: false,
      total: 0, //總筆數

      targetList: [],

      issuedUnitList: [],

      searchData: {
        keyword: "",
        companyId: "",
        isStop: -1,
        pageNum: 1,
        pageSize: 10, //每頁顯示筆數
      },
    };
  },
  async created() {
    await this.getTarget();
    this.hasEditPermission = this.$user.hasPermission(
      config.Permission.AnnouncementEdit
    );
    if (this.$route.params.searchData != undefined) {
      this.searchData = this.$route.params.searchData;
    }
    this.queryAll();
  },
  mounted() {},
  methods: {
    async onPageChange(pageIdx, pageSize) {
      this.searchData.pageNum = pageIdx;
      this.searchData.pageSize = pageSize;
      this.queryAll();
    },
    async queryAll() {
      //*---- 顯示列表
      var task_all = api.marquee.queryAll(this.searchData);
      var response_all = await task_all;
      this.marqueeTemp = JSON.parse(JSON.stringify(response_all.data.result));

      this.marquees = response_all.data.result;
      this.total = this.marquees.total;
      this.$refs.pagination.setPage(this.searchData.pageNum, this.total);
    },

    clickSearch() {
      this.searchData.pageNum = 1;
      this.queryAll();
    },

    async getTarget() {
      this.userData = this.$store.state.loginUser;
      //*----對象
      var task_target = api.announcement.targetList();
      var response_target = await task_target;
      this.targetList = response_target.data.result;

      this.searchData.companyId = this.userData.companyId;
    },

    //*---- 新增內容
    addNew() {
      this.openMarqueeInfo();
      return;
    },

    //*---- 修改內容
    async editorItem(item) {
      this.openMarqueeInfo(item.marqueeId);
      return;
    },
    //*---- 刪除
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          api.marquee.delete(item).then((res) => {
            if (res.data.status == "1") {
              this.$eventBus.$emit("showAlert", "刪除成功!");
            } else {
              this.$eventBus.$emit("showAlert", "刪除失敗!");
            }
            this.queryAll();
          });
        }
      });
    },
    openMarqueeInfo(marqueeId) {
      this.$refs.marqueeEdit.showMarqueeEditBox(marqueeId);
    },
    formatTime: function (t) {
      return utils.formatTime(t, "YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
