<template>
  <div id="CourseList">
    <section class="content-header">
      <h1>{{ $route.params.name }}-章節管理</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li>課程管理</li>
        <li class="active">章節管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning">
        <div class="box-body">
          <div class="top-box">
            <button
              class="btn btn-block btn-default btn-add"
              type="button"
              @click="back()"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
            <button
              class="btn btn-block btn-warning btn-add mr-10"
              type="button"
              style="min-width: auto"
              @click="add()"
            >
              <i class="fa fa-plus"></i> 新增章節
            </button>
          </div>
          <div class="table-responsive mt-10">
            <table class="table table-hover table-bordered min-800">
              <thead>
                <tr class="bg-gray">
                  <th class="text-nowrap text-center" style="width: 10%">
                    排序
                  </th>
                  <th class="text-nowrap text-center" style="width: 15%">
                    章節名稱
                  </th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    建立者
                  </th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    時數
                  </th>
                  <th class="text-nowrap text-center" style="width: 10%">
                    建立時間
                  </th>
                  <th class="text-nowrap text-center" style="width: 8%">
                    狀態
                  </th>
                  <th class="text-nowrap text-center" style="width: 15%">
                    功能
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loading">
                  <tr>
                    <td class="text-center" colspan="7">查詢中</td>
                  </tr>
                </template>
                <template v-else-if="list.length == 0">
                  <tr>
                    <td class="text-center" colspan="7">查無資料</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in list" :key="index">
                    <td class="td-sort text-center">
                      <input
                        type="number"
                        v-model="item.chapterOrder"
                        :disabled="item.chapterStatus != '1'"
                      />
                      <button
                        class="btn btn-warning btn-upDate"
                        v-if="item.chapterStatus == '1'"
                        @click="changeOrder(item)"
                      >
                        更新
                      </button>
                    </td>
                    <td>{{ item.chapterName }}</td>
                    <td class="text-center">{{ item.createEmpName }}</td>
                    <td class="text-center" style="white-space: nowrap;">{{ item.chapterTime }}</td>
                    <td class="text-center">
                      {{ item.createTime | dateFormat }}
                    </td>
                    <td class="text-center">
                      {{ item.chapterStatus == "1" ? "啟用" : "停用" }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-primary" @click="edit(item)">
                        編輯
                      </button>
                      &nbsp;
                      <button class="btn btn-danger" @click="deleteItem(item)">
                        刪除
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/assets/js/app-api";

export default {
  components: {},
  data() {
    return {
      loading: false,
      searchData: {
        courseId: this.$route.params.id, // 課程id
        order: 1, // 排序 1：ChapterOrder 小到大
        pageNum: 1, // 頁碼
        pageSize: 20, // 頁數
      },
      list: [],
      total: 0,
    };
  },
  async created() {
    if (this.$route.params.chaterSearchData != undefined) {
      this.searchData = this.$route.params.chaterSearchData;
    }
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      api.education.chapter.queryAll(this.searchData).then((res) => {
        this.list = res.data.result.datas;
        this.loading = false;
      });
    },
    add() {
      this.$router.push({
        name: "ETChapterEdit",
        params: {
          id: this.searchData.courseId,
          name: this.$route.params.name,
          chaterSearchData: this.searchData,
          searchData: this.$route.params.searchData,
        },
      });
    },
    edit(item) {
      this.$router.push({
        name: "ETChapterEdit",
        params: {
          id: this.searchData.courseId,
          name: this.$route.params.name,
          chapterId: item.chapterId,
          chapterName: item.chapterName,
          chaterSearchData: this.searchData,
          searchData: this.$route.params.searchData,
        },
      });
    },
    deleteItem(item) {
      this.$eventBus.$emit("showConfirm", "確定要刪除嗎?", (isOK) => {
        if (isOK) {
          item.isDel = 1;
          this.updateItem(item, "delete");
        }
      });
    },
    changeOrder(item) {
      this.updateItem(item, "update");
    },
    updateItem(item, type) {
      api.education.chapter.update(item).then((res) => {
        this.global.showResponse(this, res, type, this.getDataList);
      });
    },
    back() {
      this.$router.push({
        name: "ETCourseList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
  },
};
</script>
