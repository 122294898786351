var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pdf-document" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "loading",
      },
      [_vm._v("Loading ...")]
    ),
    _vm.pdfUrl
      ? _c("div", { staticClass: "drag-box", attrs: { id: "dragBox" } }, [
          _c(
            "div",
            {
              staticClass: "scroll",
              on: {
                scroll: function ($event) {
                  return _vm.scrollfun($event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "wrapper", attrs: { id: "pdf-container" } },
                _vm._l(_vm.totals, function (item) {
                  return _c(
                    "div",
                    {
                      key: item,
                      staticClass: "pdf-box",
                      attrs: { id: `page-${item}` },
                    },
                    [
                      _c("canvas", {
                        staticClass: "canvas-pdf",
                        attrs: { id: "canvas-pdf-" + item },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }