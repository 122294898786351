var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "Products" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("公司產品")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", { staticClass: "active" }, [_vm._v("公司產品")]),
      ]),
    ]),
    _c("section", { staticClass: "content products" }, [
      _c("div", { staticClass: "box box-warning max850" }, [
        _c(
          "div",
          { staticClass: "box-body" },
          [
            _vm.hasEditPermission
              ? _c("div", { staticClass: "top-box" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-block btn-default btn-cog",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.manager()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-cog" }),
                      _vm._v(" 公司產品管理\n          "),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._l(_vm.products, function (product, index) {
              return _c("div", { key: index, staticClass: "block" }, [
                _c("h4", [
                  product.productUrl != ""
                    ? _c(
                        "a",
                        {
                          attrs: { href: product.productUrl, target: "_blank" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(product.productName) +
                              " \n              "
                          ),
                          _c("i", { staticClass: "fa fa-link" }),
                        ]
                      )
                    : _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(product.productName) +
                            " \n              "
                        ),
                        _c("i", { staticClass: "fa fa-link" }),
                      ]),
                ]),
                _c("p", [_vm._v(_vm._s(product.productInfo))]),
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-bordered table-striped text-center",
                    },
                    [
                      _c("tbody", [
                        _vm._m(0, true),
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _vm._l(
                                product.androidFiles,
                                function (file, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pic" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          alt: file.name,
                                          draggable: "false",
                                          src: _vm.imgPath + file.filesUrl,
                                          width: "200",
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              product.androidUrl != ""
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-default",
                                      attrs: {
                                        href: product.androidUrl,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      連結\n                      "
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-external-link",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "td",
                            [
                              _vm._l(product.iosFiles, function (file, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "pic" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        alt: file.name,
                                        draggable: "false",
                                        src: _vm.imgPath + file.filesUrl,
                                        width: "200",
                                      },
                                    }),
                                  ]
                                )
                              }),
                              product.iosUrl != ""
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-default",
                                      attrs: {
                                        href: product.iosUrl,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      連結\n                      "
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-external-link",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [_c("th", [_vm._v("Android")]), _c("th", [_vm._v("IOS")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }