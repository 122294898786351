var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "CourseList" } }, [
    _c("section", { staticClass: "content-header" }, [
      _c("h1", [_vm._v("課程管理")]),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          [_c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")])],
          1
        ),
        _c("li", [_vm._v("教育訓練")]),
        _c("li", { staticClass: "active" }, [_vm._v("課程管理")]),
      ]),
    ]),
    _c("section", { staticClass: "content" }, [
      _c("div", { staticClass: "box box-warning" }, [
        _c("div", { staticClass: "box-body" }, [
          _c("div", { staticClass: "top-box" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-warning btn-add",
                staticStyle: { "min-width": "auto" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.add()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-plus" }),
                _vm._v(" 新增\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-add mr-10",
                class: _vm.$user.hasPermission("sendNotice")
                  ? "btn-danger"
                  : "",
                staticStyle: { "min-width": "auto" },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.$user.hasPermission("sendNotice")
                      ? _vm.showMail()
                      : _vm.noMailPermission()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-bell" }),
                _vm._v(" 發送通知信\n          "),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-block btn-success btn-add mr-10",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.goRecordAll()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-search" }),
                _vm._v(" 批量課程紀錄\n          "),
              ]
            ),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.courseType,
                      expression: "searchData.courseType",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "courseType",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                _vm._l(_vm.classList.chapterType, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.value } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.name) +
                          "\n              "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "select-group" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchData.courseClass,
                      expression: "searchData.courseClass",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "selectUnit2", required: "required" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.searchData,
                          "courseClass",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.resetDataList()
                      },
                    ],
                  },
                },
                _vm._l(_vm.classList.classType, function (item, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: item.value } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(item.name) +
                          "\n              "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchData.courseName,
                    expression: "searchData.courseName",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  name: "table_search",
                  placeholder: "搜尋關鍵字",
                  type: "text",
                },
                domProps: { value: _vm.searchData.courseName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.searchData, "courseName", $event.target.value)
                  },
                },
              }),
              _c("div", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.resetDataList()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-search" })]
                ),
              ]),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm.$user.hasPermission("watchAll")
                ? _c("div", { staticClass: "checkbox" }, [
                    _c(
                      "label",
                      [
                        _c("iCheckCheckBox", {
                          attrs: { propValue: true, propChecked: _vm.self },
                          on: { "update:checked": _vm.showSelf },
                        }),
                        _vm._v(
                          "\n                我上傳的課程\n              "
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "table-responsive mt-10" }, [
            _c(
              "table",
              { staticClass: "table table-hover table-bordered min-800" },
              [
                _c("thead", [
                  _c("tr", { staticClass: "bg-gray" }, [
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "5%" },
                      },
                      [
                        _c("div", { staticClass: "checkbox" }, [
                          _c(
                            "label",
                            [
                              _c("iCheckCheckBox", {
                                attrs: {
                                  propValue: true,
                                  propChecked: _vm.isCheckAll,
                                },
                                on: { "update:checked": _vm.checkAll },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "8%", "min-width": "100px" },
                      },
                      [_vm._v("\n                  類型\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "8%", "min-width": "100px" },
                      },
                      [_vm._v("\n                  分類\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap",
                        staticStyle: { width: "15%", "min-width": "180px" },
                      },
                      [_vm._v("\n                  課程名稱\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "8%", "min-width": "80px" },
                      },
                      [_vm._v("\n                  建立者\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "5%" },
                      },
                      [_vm._v("\n                  時數\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "10%", "min-width": "120px" },
                      },
                      [_vm._v("\n                  建立時間\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "10%", "min-width": "120px" },
                      },
                      [
                        _vm._v(
                          "\n                  上次寄送通知信時間\n                "
                        ),
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "5%" },
                      },
                      [_vm._v("\n                  課程狀態\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "5%" },
                      },
                      [_vm._v("\n                  測驗狀態\n                ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "text-nowrap text-center",
                        staticStyle: { width: "15%", "min-width": "340px" },
                      },
                      [_vm._v("\n                  功能\n                ")]
                    ),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _vm.loading
                      ? [_vm._m(0)]
                      : _vm.list.length == 0
                      ? [_vm._m(1)]
                      : _vm._l(_vm.list, function (data, index) {
                          return _c("tr", { key: index }, [
                            _c("td", { staticClass: "text-center" }, [
                              _c("div", { staticClass: "checkbox" }, [
                                _c(
                                  "label",
                                  [
                                    _c("iCheckCheckBox", {
                                      attrs: {
                                        propValue: data,
                                        propChecked: data.checked,
                                      },
                                      on: { "update:checked": _vm.checkChange },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.courseType)),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(data.courseClass)),
                            ]),
                            _c("td", [_vm._v(_vm._s(data.courseName))]),
                            _c("td", [_vm._v(_vm._s(data.createEmpName))]),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                staticStyle: { "white-space": "nowrap" },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(data.courseTotalTime) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("dateFormat")(data.createTime)
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _c("td", { staticClass: "text-center" }, [
                              data.sendMailTime
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormat")(data.sendMailTime)
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v(" - ")]),
                            ]),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                class: { "text-red": data.courseStatus != 1 },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      data.courseStatus == 1 ? "啟用" : "停用"
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-center",
                                class: { "text-red": data.testStatus != 1 },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      data.testStatus == 1 ? "啟用" : "停用"
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _c("td", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goChapter(data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      章節\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goDetail(data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      詳細\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(data)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      修改\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(
                                "\n                     \n                    "
                              ),
                              _c("div", { staticClass: "btn-group" }, [
                                _vm._m(2, true),
                                _c("ul", { staticClass: "dropdown-menu" }, [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.goQuiz(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            測驗\n                          "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyCourse(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            複製\n                          "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-red",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(data)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            刪除\n                          "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            staticClass: "box-footer text-center no-border",
          },
          [
            _c("Pagination", {
              ref: "pagination",
              attrs: {
                total: _vm.total,
                pageLimitCount: _vm.searchData.pageSize,
              },
              on: { pageMethod: _vm.onPageChange },
            }),
          ],
          1
        ),
        _c("div", { ref: "mail", staticClass: "modal fade" }, [
          _c(
            "div",
            {
              staticClass: "modal-dialog",
              staticStyle: { overflow: "visible" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(3),
                _c("div", { staticClass: "modal-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "form-horizontal",
                      attrs: { name: "announcementForm" },
                    },
                    [
                      _c("div", { staticClass: "box-body" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(4),
                          _c(
                            "div",
                            { staticClass: "col-sm-10" },
                            _vm._l(_vm.checkDatas, function (course, index) {
                              return _c("div", { key: index }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(course.courseType) +
                                    " - " +
                                    _vm._s(course.courseName) +
                                    "\n                      "
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _vm._m(5),
                          _c("div", { staticClass: "col-sm-10" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mailContent,
                                  expression: "mailContent",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "inputContent",
                                required: "required",
                                type: "text",
                                rows: "5",
                              },
                              domProps: { value: _vm.mailContent },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.mailContent = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-sm-2 control-label text-nowrap",
                              staticStyle: { "padding-top": "0" },
                            },
                            [
                              _vm._v(
                                "\n                      通知主管：\n                    "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-10" },
                            [
                              _c(
                                "multiselect",
                                {
                                  attrs: {
                                    placeholder: "請選擇公司或部門",
                                    label: "optionName",
                                    "track-by": "optionName",
                                    required: "",
                                    options: _vm.managerList,
                                    "hide-selected": true,
                                    multiple: true,
                                    taggable: true,
                                    "close-on-select": false,
                                    "clear-on-select": false,
                                    "max-elements": "已達標籤上限",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "option",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "option__desc" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "option__title",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                              " +
                                                      _vm._s(
                                                        props.option.optionName
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.selected,
                                    callback: function ($$v) {
                                      _vm.selected = $$v
                                    },
                                    expression: "selected",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "notice",
                                      attrs: { slot: "noOptions" },
                                      slot: "noOptions",
                                    },
                                    [_vm._v("列表為空")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "notice",
                                      attrs: { slot: "noResult" },
                                      slot: "noResult",
                                    },
                                    [_vm._v("查無結果")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "notice maxElements",
                                      attrs: { slot: "maxElements" },
                                      slot: "maxElements",
                                    },
                                    [
                                      _vm._v(
                                        "\n                          已達標籤上限\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "modal-footer text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.sendMail()
                        },
                      },
                    },
                    [_vm._v("\n                確定\n              ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { "data-dismiss": "modal", type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.resetMail()
                        },
                      },
                    },
                    [_vm._v("\n                關閉\n              ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("查詢中"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { staticClass: "text-center", attrs: { colspan: "11" } }, [
        _vm._v("查無資料"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-brown dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [
        _vm._v("\n                        其他\n                        "),
        _c("span", { staticClass: "caret" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title text-center" }, [
        _vm._v("發送通知信"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label text-nowrap",
        staticStyle: { "padding-top": "0" },
      },
      [
        _c("i", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 課程：\n                    "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      {
        staticClass: "col-sm-2 control-label text-nowrap",
        staticStyle: { "padding-top": "0" },
      },
      [
        _c("i", { staticClass: "text-red" }, [_vm._v("*")]),
        _vm._v(" 信件內容：\n                    "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }