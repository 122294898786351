<template>
  <div id="CourseQuizEdit">
    <section class="content-header">
      <h1>{{ quizTitle }}</h1>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>教育訓練</li>
        <li class="active">課程管理</li>
      </ol>
    </section>
    <section class="content">
      <div class="box box-warning company-files-edit">
        <div class="box-body">
          <div class="top-box with-border">
            <h4 class="pull-left" id="title">
              {{ isEdit ? "編輯" : "新增" }}測驗
            </h4>
            <button
              @click="back()"
              class="btn btn-block btn-default btn-cog"
              type="button"
            >
              <i class="fa fa-reply"></i> 返回列表
            </button>
          </div>
        </div>
        <div class="box-body">
          <form class="form-horizontal">
            <div class="box-body">
              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  <span class="text-red">*</span> 及格分數：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    required
                    type="number"
                    v-model="passingScore"
                    min="0"
                  />
                  <span class="text-gray">*測驗達及格分數才算完成</span>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="selectType">
                  每題預設分數：
                </label>
                <div class="col-sm-8">
                  <input
                    class="form-control"
                    type="number"
                    v-model="newScore"
                    min="0"
                  />
                  <div class="mt-10">
                    <button
                      class="btn btn-warning mr-10"
                      type="button"
                      @click="updateScore('question')"
                    >
                      同步題目分數
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="datetimepicker">
                  <span class="text-red">*</span> 測驗狀態：
                </label>
                <div class="col-sm-8">
                  <label class="custom-radio">
                    <input
                      class="flat"
                      name="radioMode"
                      type="radio"
                      value="1"
                      v-model="testStatus"
                    />
                    <i class="icon"></i>
                    <span>啟用</span>
                  </label>
                  <label class="custom-radio">
                    <input
                      class="flat"
                      name="radioMode"
                      type="radio"
                      value="0"
                      v-model="testStatus"
                    />
                    <i class="icon"></i>
                    <span>停用</span>
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" for="datetimepicker">
                  <span class="text-red">*</span> 測驗內容：
                </label>
                <div class="col-sm-9">
                  <div class="table-responsive mt-10">
                    <div
                      class="option-border mb-10"
                      v-for="(question, index) in list"
                      :key="index"
                    >
                      <table class="table tc">
                        <tr>
                          <th style="min-width: 60px; width: 60px"></th>
                          <th style="min-width: 150px"></th>
                          <th
                            style="min-width: 160px"
                            :style="{
                              width: '110px',
                            }"
                          ></th>
                        </tr>
                        <tr>
                          <td class="question">Q{{ index + 1 }}</td>
                          <td>
                            <input
                              class="form-control mt-10"
                              type="text"
                              placeholder="請輸入題目"
                              v-model="question.questionText"
                              required
                            />
                          </td>
                          <td></td>
                        </tr>
                        <tr
                          v-for="(option, oIndex) in question.options"
                          :key="oIndex"
                        >
                          <td class="tc">
                            <label class="custom-radio">
                              <input
                                class="flat"
                                :name="`answer${index}`"
                                type="radio"
                                :value="oIndex + 1"
                                v-model="question.correctAnswer"
                              />
                              <i class="icon"></i>
                            </label>
                          </td>
                          <td>
                            <input
                              class="form-control mt-10"
                              type="text"
                              :placeholder="
                                '選項' + String.fromCharCode(64 + (oIndex + 1))
                              "
                              v-model="option.optionText"
                              required
                            />
                          </td>
                          <td class="text-left" style="min-width: 200px">
                            <button
                              class="btn btn-warning mt-10 mr-10 ml10"
                              type="button"
                              v-if="question.options.length > 1"
                              :disabled="oIndex == 0"
                              @click="sortOption(question, oIndex, true)"
                              title="向上移動"
                            >
                              <i class="fa fa-arrow-up"></i>
                            </button>
                            <button
                              class="btn btn-warning mt-10 mr-10"
                              type="button"
                              v-if="question.options.length > 1"
                              :disabled="oIndex == question.options.length - 1"
                              @click="sortOption(question, oIndex, false)"
                              title="向下移動"
                            >
                              <i class="fa fa-arrow-down"></i>
                            </button>
                            <button
                              class="btn btn-danger mt-10"
                              type="button"
                              v-if="question.options.length > 2"
                              @click="deleteOptions(question, oIndex)"
                              title="刪除"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </table>

                      <table class="table option-bottom">
                        <tr>
                          <th style="min-width: 60px; width: 60px"></th>
                          <th style="min-width: 150px"></th>
                        </tr>
                        <tr>
                          <td class="tc">分數</td>
                          <td>
                            <input
                              class="form-control mt-10"
                              required
                              type="number"
                              v-model="question.questionScore"
                              v-on:input="updateScore"
                              min="0"
                            />
                            <button
                              class="btn btn-warning mr-10 ml10"
                              type="button"
                              @click="addOption(question)"
                              title="新增選項"
                            >
                              <i class="fa fa-plus"></i>
                              新增選項
                            </button>
                            <button
                              class="btn btn-primary mr-10"
                              type="button"
                              v-if="list.length > 1"
                              :disabled="index == 0"
                              @click="sortQuestion(index, true)"
                              title="向上移動"
                            >
                              <i class="fa fa-arrow-up"></i>
                            </button>
                            <button
                              class="btn btn-primary mr-10"
                              type="button"
                              v-if="list.length > 1"
                              :disabled="index == list.length - 1"
                              @click="sortQuestion(index, false)"
                              title="向下移動"
                            >
                              <i class="fa fa-arrow-down"></i>
                            </button>
                            <button
                              class="btn btn-danger"
                              type="button"
                              v-if="list.length > 2"
                              @click="deleteQuestion(index)"
                              title="刪除"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <button
                      class="btn btn-primary mt-10"
                      type="button"
                      @click="addQuestion()"
                    >
                      <i class="fa fa-plus"></i>
                      新增題目
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <div class="total-score">
                <div class="tc">
                  總分
                  <p>{{ totalScore }}</p>
                </div>
              </div>
              <div class="btns">
                <button
                  class="btn btn-warning mr-10"
                  type="button"
                  @click="preview"
                >
                  預覽
                </button>
                <button class="btn btn-primary" type="button" @click="submit">
                  送出
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import iCheckRedioButton from "@/components/iCheckRedioButton";
import api from "@/assets/js/app-api";

export default {
  components: {
    // iCheckRedioButton,
  },
  data() {
    return {
      quizTitle: "課程管理",
      isEdit: false,
      list: [],
      defaultOptions: [
        { optionText: "", isCorrect: 0, optionOrder: 1 },
        { optionText: "", isCorrect: 0, optionOrder: 2 },
        { optionText: "", isCorrect: 0, optionOrder: 3 },
        { optionText: "", isCorrect: 0, optionOrder: 4 },
      ],
      totalScore: 0,
      newScore: 0,
      passingScore: 0, // 及格分數
      testStatus: 0, // 測驗狀態 0停用、1啟用
      finalDatas: {
        courseId: this.$route.params.id, // 課程id
        testName: this.$route.params.name, // 測驗名稱
        passingScore: 0, // 及格分數
        defaultScore: 0, // 每題預設分數
        testStatus: 0,
        isDel: 0, // 刪除狀態 0正常、1刪除
      },
      datas: [this.finalDatas],
    };
  },
  watch: {
    quizTitle(val) {
      this.finalDatas.testName = val;
    },
    passingScore(val) {
      this.finalDatas.passingScore = val;
    },
    newScore(val) {
      this.finalDatas.defaultScore = val;
    },
    testStatus(val) {
      this.finalDatas.testStatus = val;
    },
    list: {
      handler: function (newVal, oldVal) {
        this.finalDatas.questions = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      api.education.quiz
        .queryAll({
          CourseId: this.$route.params.id, // 課程id
        })
        .then((res) => {
          if (res.data.status == 1) {
            const defaultOptions = JSON.parse(
              JSON.stringify(this.defaultOptions)
            );

            let paramsDatas = this.$route.params.datas;
            const previewDatas = JSON.parse(
              localStorage.getItem("previewDatas")
            );
            if (
              previewDatas &&
              this.$route.params.id == previewDatas.courseId
            ) {
              paramsDatas = previewDatas;
              localStorage.removeItem("previewDatas");
            }

            this.datas = res.data.result;
            this.isEdit = Boolean(this.datas.length > 0);
            if (this.isEdit && !paramsDatas) {
              this.passingScore = this.datas[0].passingScore;
              this.newScore = this.datas[0].defaultScore;
              this.quizTitle = this.datas[0].testName;
              this.finalDatas.testId = this.datas[0].testId;
              this.testStatus = this.datas[0].testStatus;
              if (this.datas[0].questions) {
                let newList = this.datas[0].questions;
                newList.forEach((question) => {
                  if (question.options.length == 0) {
                    question.options = defaultOptions;
                    question.questionScore = this.newScore;
                    question.questionType = "選擇題";
                  }
                });
                this.list = newList;
                this.updateScore();
              }
            } else {
              if (paramsDatas) {
                this.datas = paramsDatas;
                this.passingScore = paramsDatas.passingScore;
                this.newScore = paramsDatas.defaultScore;
                this.quizTitle = paramsDatas.testName;
                this.list = paramsDatas.questions;
                this.updateScore();
              } else {
                if (this.$route.params.name) {
                  this.quizTitle = this.$route.params.name;
                }
                this.list = [
                  {
                    questionText: "", // 題目名稱
                    questionType: "選擇題", // 題目類型
                    correctAnswer: "", // 題目正確答案
                    questionOrder: 1, // 題目排序
                    questionScore: this.newScore,
                    options: defaultOptions,
                  },
                ];
              }
            }
          }
        });
    },

    // 更新每題預設分數
    resetScore() {
      this.list.forEach((item) => {
        item.questionScore = this.newScore;
      });
    },
    // 新增題目
    addQuestion() {
      const defaultOptions = JSON.parse(JSON.stringify(this.defaultOptions));
      this.list.push({
        questionText: "",
        questionScore: this.newScore,
        questionOrder: this.list.length + 1,
        questionType: "選擇題",
        options: defaultOptions,
      });
      this.updateScore();
    },
    // 新增選項
    addOption(question) {
      const defaultOptions = JSON.parse(JSON.stringify(this.defaultOptions));
      question.options.push(defaultOptions[0]);
    },
    // 刪除題目
    deleteQuestion(index) {
      this.$eventBus.$emit(
        "showConfirm",
        "刪除後無法復原，確定刪除？",
        (isOK) => {
          if (isOK) {
            this.list.splice(index, 1);
            this.updateScore();
          }
        }
      );
    },
    // 刪除選項
    deleteOptions(question, index) {
      this.$eventBus.$emit(
        "showConfirm",
        "刪除後無法復原，確定刪除？",
        (isOK) => {
          if (isOK) {
            question.options.splice(index, 1);
          }
        }
      );
    },
    // 題目上下排序
    sortQuestion(index, up) {
      if (up) {
        const temp = this.list[index];
        this.list[index] = this.list[index - 1];
        this.list[index - 1] = temp;
      } else {
        const temp = this.list[index];
        this.list[index] = this.list[index + 1];
        this.list[index + 1] = temp;
      }
    },
    // 選項上下排序
    sortOption(question, index, up) {
      if (up) {
        const temp = question.options[index];
        question.options[index] = question.options[index - 1];
        question.options[index - 1] = temp;
      } else {
        const temp = question.options[index];
        question.options[index] = question.options[index + 1];
        question.options[index + 1] = temp;
      }
    },
    preview() {
      this.rearrangeOptionOrder();
      localStorage.setItem("previewDatas", JSON.stringify(this.finalDatas));
      this.$router.push({
        name: "ETCourseQuizPreview",
        params: {
          id: this.$route.params.id,
          name: this.$route.params.name,
          searchData: this.$route.params.searchData,
        },
      });
    },
    back() {
      this.$router.push({
        name: "ETCourseList",
        params: {
          searchData: this.$route.params.searchData,
        },
      });
    },
    submit() {
      let hasError = false;
      this.list.forEach((question, index) => {
        if (!question.questionText) {
          this.$eventBus.$emit(
            "showAlert",
            `請確認所有欄位都有填上內容：Q${index + 1}的題目`
          );
          hasError = true;
          return false;
        }
        if (!question.correctAnswer) {
          this.$eventBus.$emit("showAlert", `請設置Q${index + 1}的正確答案`);
          hasError = true;
          return false;
        }
        if (!question.questionScore || question.questionScore == 0) {
          this.$eventBus.$emit("showAlert", `Q${index + 1}的分數必須大於0分`);
          hasError = true;
          return false;
        }
        question.options.forEach((option, oIndex) => {
          if (!option.optionText) {
            this.$eventBus.$emit(
              "showAlert",
              `請確認所有欄位都有填上內容：請檢查Q${index + 1}的選項`
            );
            hasError = true;
            return false;
          }
        });
      });

      if (this.totalScore < this.passingScore) {
        hasError = true;
        this.$eventBus.$emit("showAlert", `總分必需大於及格分數！`);
      }

      if (!this.passingScore || this.passingScore <= 0) {
        hasError = true;
        this.$eventBus.$emit("showAlert", `及格分數必須大於0分！`);
      }

      if (!hasError) {
        const action = this.isEdit ? "update" : "create";

        this.rearrangeOptionOrder();

        if (!this.finalDatas.testId) {
          this.finalDatas.testId = this.datas.testId;
        }
        api.education.quiz[action](this.finalDatas).then((res) => {
          if (res.data.status == 1) {
            this.$eventBus.$emit("showAlert", "新增成功！");
            this.back();
          } else {
            let stateCode = res.data.stateCode;
            let message = res.data.message;
            if (message) {
              this.$eventBus.$emit(
                "showAlert",
                message + (stateCode ? "(" + stateCode + ")" : "")
              );
            }
          }
        });
      }
    },

    // 重新調整正確的項目排序
    rearrangeOptionOrder() {
      this.finalDatas.questions.forEach((questions, index) => {
        questions.questionOrder = index + 1;
        questions.options.forEach((options, subIndex) => {
          options.optionOrder = subIndex + 1;
        });
      });
    },

    // 更新總分
    updateScore(type) {
      if (this.list.length > 0) {
        let totalScore = 0;
        this.list.forEach((item) => {
          if (type == "question") item.questionScore = this.newScore;
          totalScore += Number(item.questionScore);
        });
        this.totalScore = totalScore;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#CourseQuizEdit {
  .mb-10 {
    margin-bottom: 10px;
  }
  .text-gray {
    color: #999 !important;
  }
  .btn-question {
    background: transparent;
    i {
      color: #939393;
      font-size: 18px;
    }
  }
  .option-border {
    border: 1px solid #d9d9d9;
    .table {
      margin-bottom: 10px;
    }
    .option-bottom {
      border-top: 1px solid #d9d9d9;
      .form-control {
        display: inline-block;
        width: 100px;
      }
    }
  }
  .total-score {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dd4b39;
    color: white;
    font-size: 18px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    position: fixed;
    bottom: 15%;
    right: 5%;

    p {
      margin-bottom: 0;
    }
  }
}
</style>